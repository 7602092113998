import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSeamless } from '../../layout-components';
import ManageUsersTable from '../../components/Tables/users/manageUsersTable';
import AppModal from '../../components/modal';
import CreateUserForm from '../../components/users/forms';
export default function Users() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage Users"
        // titleDescription="Tables are the backbone of almost all web applications."
        createObj={true}
        handleOpen={handleOpen}
        toolTipTitle={'Create user'}
      />

      <ExampleWrapperSeamless sectionHeading="">
        <ManageUsersTable />
      </ExampleWrapperSeamless>
      {open && (<CreateUserForm open={open} handleClose={handleClose} />) }
    </Fragment>
  );
}
