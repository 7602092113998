import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../../modal/style';
import { AppModalFormActions } from '../../../modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesRequest } from '../../../../redux/actions/categoriesActions';
import {
  createCategoryApi,
  updateCategoryApi
} from '../../../../api/authServices/galleryCategory/category';
import FileUploadInput from '../../../FileUploadInput/FileUploadInput';
import {checkImageDetails, dispatchNotifyActions} from '../../../../utils/helper';
import ImageDimensions from '../../../image-dimensions';

const CreateGalleryCategoryForm = ({
  handleClose,
  editCategory,
  setEditCategory,
  categoryDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    name: editCategory ? categoryDetails.name : '',
    description: editCategory ? categoryDetails.description : '',
    description2: editCategory ? categoryDetails.description2 : '',
    image: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [invalidImage, setInvalidImage] = useState(true);
  const [imageDetails, setImageDetails] = useState({
    height: 0,
    width: 0
  });
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleModalClose = () => {
    if (editCategory) {
      setEditCategory(false);
    }
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      image: []
    });
  };

  const updateGalleryCategory = async () => {
    setProcessingRequest(true);
    const data = {
      name: values.name,
      description: values.description,
      description2: values.description2,
      image: values.image,
      id: categoryDetails.id,
      authToken: user.authToken
    };
    await updateCategoryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchCategoriesRequest({
            authToken: user.authToken
          })
        );
        dispatchNotifyActions(
          dispatch,
          'success',
          'Gallery category successfully updated'
        );
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            handleResponseValidationErrors(data);
            break;
          case 500:
            setProcessingRequest(false);
            dispatchNotifyActions(dispatch, 'error', 'Service update failed');
            break;
          default:
            dispatchNotifyActions(dispatch, 'error', 'Service update failed');
        }
      }
    });
  };

  const createCategory = async () => {
    setProcessingRequest(true);
    const data = {
      name: values.name,
      description: values.description,
      description2: values.description2,
      image: values.image,
      authToken: user.authToken
    };

    await createCategoryApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Service successfully created'
        );
        setProcessingRequest(false);
        dispatch(
          fetchCategoriesRequest({
            authToken: user.authToken
          })
        );
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        const { data, status } = err.response;
        switch (status) {
          case 400:
            handleResponseValidationErrors(data);
            break;
          case 500:
            dispatchNotifyActions(dispatch, 'error', 'Service creation failed');
            break;
          default:
            dispatchNotifyActions(dispatch, 'error', 'Service creation failed');
        }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('name')) {
      dispatchNotifyActions(dispatch, 'error', data.name[0]);
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('image')) {
      dispatchNotifyActions(dispatch, 'error', data.image[0]);
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('description')) {
      dispatchNotifyActions(dispatch, 'error', data.description[0]);
    } else {
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request. Try again'
      );
    }
  };

  const handleOnSubmit = async () => {
    if (!editCategory) {
      await createCategory();
    } else {
      await updateGalleryCategory();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      image: image
    });
    // checkImageDetails(image, 1365, 2048, setInvalidImage, setNotifyPopUp);
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editCategory ? 'Edit Service' : 'Add new service'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Service name is required'
                      }
                    })}
                    type="text"
                    name="name"
                    label="Category name"
                    placeholder="Enter service name"
                    error={errors.name && true}
                    inputProps={{
                      defaultValue: editCategory && categoryDetails.name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.name && errors.name.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('description', {
                      required: {
                        value: true,
                        message: 'Service description is required'
                      }
                    })}
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="Enter service description"
                    error={errors.description && true}
                    inputProps={{
                      defaultValue: editCategory && categoryDetails.description
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.description && errors.description.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('description2', {
                      required: {
                        value: false,
                        message: 'Service description is required'
                      }
                    })}
                    type="text"
                    name="description2"
                    label="Description 2 (Optional)"
                    placeholder="Enter service description 2"
                    error={errors.description2 && true}
                    inputProps={{
                      defaultValue: editCategory && categoryDetails.description2
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.description2 && errors.description2.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">
                    Upload cover image
                  </FormLabel>
                  <FileUploadInput
                    canPerformActions
                    setStateValues={setStateValues}
                    values={values}
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleFileUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    message={'Service cover image is required'}
                    errors={errors}
                    register={register}
                    validate={!editCategory}
                  />
                </FormControl>
              </Grid>
              <p id={'fileInfo'}></p>
            </Grid>
            {/*<p id="fileInfo"></p>*/}
          </DialogContent>
          <DialogActions>
            {/*{!invalidImage && (*/}
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editCategory ? 'Update service' : 'Create Service'}
            />
            {/*)}*/}
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateGalleryCategoryForm;
