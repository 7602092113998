import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import ManageTheTeamTable from '../../components/Tables/theTeam/manageTheTeamTable';
import CreateTheTeamForm from '../../components/theTeam/form/createTheTeamForm';
export default function TheTeam() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="The Team"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      <ManageTheTeamTable />

      {open && <CreateTheTeamForm open={open} handleClose={handleClose} />}
    </Fragment>
  );
}
