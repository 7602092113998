import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import ManageUserEnqueriesTable from '../../components/Tables/userEnqueries/manageUserEnqueriesTable';
export default function UserEnqueries() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="User enquiries"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={false}
        handleOpen={handleOpen}
      />
      <ManageUserEnqueriesTable />
    </Fragment>
  );
}
