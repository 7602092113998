import { SERVICE_PAGE } from '../redux/actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: false
};

export default function servicePageReducer(state = initialState, action) {
  switch (action.type) {
    case SERVICE_PAGE.FETCH_SERVICE_PAGE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      };
    case SERVICE_PAGE.FETCH_SERVICE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case SERVICE_PAGE.FETCH_SERVICE_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
