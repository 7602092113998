import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchAllUsersApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/manage-users/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createUserApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.post(`${baseUrl}/adminportal/manage-users/`, data, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
export const updateUserApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('first_name', data.first_name);
  formData.append('last_name', data.last_name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);

  if (data.email === data.prevData.email) {
    formData.delete('email');
  }
  if (data.phone === data.prevData.phone) {
    formData.delete('phone');
  }

  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-users/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteUserApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(`${baseUrl}/adminportal/manage-users/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
