import React, {Fragment, useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import Slideshow from '../../../assets/components/slideShow/slideImage';
import AppLoader from '../../../components/loader/loader';
import { fetchClientPhotosRequest } from '../../../redux/actions/clientPhotosActions';
import { PageTitle } from '../../../layout-components';
import { fetchLoveStoryGalleryRequest } from '../../../redux/actions/loveStoryGalleryActions';
import { fetchCategoryGalleryRequest } from '../../../redux/actions/galleryActions';

export default function ViewGalleries(props) {
  const { id, title, type } = props.history.location.state.data;
  const [switchAction, setSwitchAction] = useState('view');
  const galleryState = useSelector(state => {
    if (type === 'client-gallery') {
      return state.ClientPhotosReducer;
    } else if (type === 'love-story') {
      return state.LoveStoryGalleryReducer;
    } else if (type === 'service') {
      return state.GalleryReducer;
    }
  });
  const authState = useSelector(state => state.AuthReducer);
  const { loading, data } = galleryState;
  const [fetchGallery, setFetchGallery] = useState();
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    switch (type) {
      case 'client-gallery':
        setFetchGallery(fetchClientPhotosRequest);
        dispatch(
          fetchClientPhotosRequest({
            id,
            authToken: user.authToken
          })
        );
        break;
      case 'love-story':
        setFetchGallery(fetchLoveStoryGalleryRequest);
        dispatch(
          fetchLoveStoryGalleryRequest({
            id,
            authToken: user.authToken
          })
        );
        break;
      case 'service':
        setFetchGallery(fetchCategoryGalleryRequest);
        dispatch(
          fetchCategoryGalleryRequest({
            id,
            authToken: user.authToken
          })
        );
        break;
    }
  }, [dispatch, id, user.authToken, type]);

  return (
    <Fragment>
      {/*<PageTitle*/}
      {/*  titleHeading={`${title}`}*/}
      {/*  // titleDescription={`${props.history.location.state.data.description}`}*/}
      {/*  createObj={false}*/}
      {/*/>*/}
      {!loading ? (
        data && data.length > 0 ? (
          // type, fetchGallery, id, authToken
          <Slideshow
            images={data}
            switchAction={switchAction}
            setSwitchAction={setSwitchAction}
            type={type}
            authToken={user.authToken}
            id={id}
            fetchGallery={fetchGallery}
            dispatch={dispatch}
          />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} style={{ height: 400, marginTop: 90 }}>
              <h4 className={'align-middle text-center text-black-50'}>
                No gallery found
              </h4>
            </Grid>
          </Grid>
        )
      ) : (
        ''
      )}
      <AppLoader loading={loading} />
    </Fragment>
  );
}
