import React, { Fragment } from 'react';

import { ExampleWrapperSeamless } from '../../../layout-components';
import ManageGalleryTable from '../../Tables/photo-gallery/manageGalleryTable';
import { fetchCategoryGalleryRequest } from '../../../redux/actions/galleryActions';
export default function ManageCategory({ categoryId }) {
  return (
    <Fragment>
      <ExampleWrapperSeamless sectionHeading="">
        <ManageGalleryTable
          categoryId={categoryId}
          fetchGallery={fetchCategoryGalleryRequest}
        />
      </ExampleWrapperSeamless>
    </Fragment>
  );
}
