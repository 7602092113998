import { CLIENT_PHOTOS } from '../actionTypes';
import {fetchAllClientGalleryApi, fetchClientGalleryApi} from "../../api/authServices/clientGallery/clientGallery";
import {fetchClientPhotosApi} from "../../api/authServices/manageGalleries/galleries";

//Get all gallery links
export const fetchClientPhoto = () => ({
  type: CLIENT_PHOTOS.FETCH_CLIENT_PHOTO_DATA
});

export const fetchClientPhotoSuccess = data => ({
  type: CLIENT_PHOTOS.FETCH_CLIENT_PHOTO_SUCCESS,
  payload: {
    data
  }
});

export const fetchClientPhotosFail = err => ({
  type: CLIENT_PHOTOS.FETCH_CLIENT_PHOTO_FAIL,
  payload: {
    err
  }
});

export const fetchClientPhotosRequest = data => {
  return dispatch => {
    dispatch(fetchClientPhoto());
    fetchClientPhotosApi(data)
      .then(res => {
        dispatch(fetchClientPhotoSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchClientPhotosFail(err.message));
      });
  };
};
