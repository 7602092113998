import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchContactPageContentApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/pages/contact-us-content/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createContactPageContentApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('address', data.address);
  formData.append('description', data.description);
  formData.append('nav_image', data.navImage);
  return await AxiosService.post(
    `${baseUrl}/adminportal/pages/contact-us-content/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateContactPageContentApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('address', data.address);
  formData.append('description', data.description);
  // formData.append('nav_image', data.navImage)
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages/contact-content/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
