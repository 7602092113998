import { AUTH } from '../redux/actionTypes';

const initialState = {
  user: {
    loading: true,
    error: false,
    isLoggedIn: false,
    userDetail: {},
    authToken: null
  }
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    //auth User
    case AUTH.AUTH_USER:
      return {
        ...state,
        user: {
          loading: true,
          error: false,
          isLoggedIn: false,
          userDetail: {},
          authToken: null
        }
      };
    case AUTH.AUTH_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          userDetail: action.payload.data.userDetails,
          isLoggedIn: action.payload.data.status,
          authToken: action.payload.data.authToken
        }
      };
    case AUTH.IS_USER_LOGGED_IN:
      return {
        ...state,
        user: {
          ...state.user,
          isLoggedIn: action.payload.data.status
        }
      };
    case AUTH.FETCH_UPDATED_USER:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          userDetail: action.payload.data.userDetails
        }
      };
    case AUTH.AUTH_USER_FAIL:
      return {
        ...state,
        offers: {
          ...state.user,
          loading: false,
          error: true
        }
      };

    case AUTH.LOGOUT_USER:
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      return {
        ...state,
        user: {
          loading: true,
          error: false,
          isLoggedIn: false,
          userDetail: null,
          authToken: null
        }
      };
    case AUTH.RECOVER_AUTH:
      return {
        ...state,
        user: {
          loading: false,
          error: false,
          isLoggedIn: action.payload.data.isLoggedIn,
          userDetail: action.payload.data.userDetail,
          authToken: `${action.payload.data.authToken}`
        }
      };
    case AUTH.SET_USER_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          authToken: action.payload.data
        }
      };
    case AUTH.SET_MAX_TIME_OUT:
      return {
        ...state,
        user: {
          ...state.user,
          maxTimeOut: action.payload.data
        }
      };
    case AUTH.STORE_LOGIN_USER:
      return localStorage.setItem('user', JSON.stringify(state.user));

    //Defaul case
    default:
      return state;
  }
}
