import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { handleResponseError } from '../../utils/helper';
import PopupNotif2 from '../../components/notifications/popupnotif2';
import AppButton from '../../components/modal/AppButton/AppButton';
import { PageTitle } from '../../layout-components';
import { changePasswordApi } from '../../api/authServices/authUser/loginUser';

const ChangePasswordForm = () => {
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const { userDetail } = user;

  const [values, setStateValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const resetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    });
  };

  const changePassword = async () => {
    setProcessingRequest(true);
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      id: userDetail.id,
      authToken: user.authToken
    };
    await changePasswordApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Password successfully updated'
        });
      },
      onError: err => {
        if (err.response) {
          setProcessingRequest(false);
          if (err.response.data.non_field_errors) {
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: err.response.data.non_field_errors[0]
            });
          } else {
            handleResponseError(
              err.response,
              setProcessingRequest,
              setNotifyPopUp,
              ['old_password', 'password', 'password2']
            );
          }
        } else {
          setProcessingRequest(false);
          setNotifyPopUp({
            status: true,
            popupType: 'error',
            message: 'Password update failed'
          });
        }
      }
    });
  };

  const handleOnSubmit = async () => {
    await changePassword();
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Change password"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={false}
      />
      <Grid container>
        <Grid item xs={12} sm={4} md={4}></Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PopupNotif2
            activate={notifyPopUp.status}
            messageContent={notifyPopUp.message}
            popupType={notifyPopUp.popupType}
            resetError={() => resetError()}
          />
          <Card className="mb-4">
            <div className={'text-center mt-5'}>
              <h4>Update Password</h4>
            </div>
            <div className="divider border-2 border-light my-3 border-dark opacity-2 mx-auto rounded-circle w-50" />
            <CardContent className="p-3">
              <form onSubmit={handleSubmit(handleOnSubmit)} id="create-user">
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        {...register('oldPassword', {
                          required: {
                            value: true,
                            message: 'Old password is required'
                          }
                        })}
                        type="password"
                        name="oldPassword"
                        label="Old password"
                        placeholder="Enter old password"
                        error={errors.oldPassword && true}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.oldPassword && errors.oldPassword.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                    <FormControl fullWidth>
                      <TextField
                        {...register('newPassword', {
                          required: {
                            value: true,
                            message: 'New password is required'
                          }
                        })}
                        type="password"
                        name="newPassword"
                        label="New password"
                        placeholder="Enter new password"
                        error={errors.newPassword && true}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.newPassword && errors.newPassword.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                    <FormControl fullWidth>
                      <TextField
                        {...register('confirmPassword', {
                          required: {
                            value: true,
                            message: 'Confirm password is required'
                          }
                        })}
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                        placeholder="Confirm password"
                        error={errors.confirmPassword && true}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.confirmPassword &&
                            errors.confirmPassword.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <AppButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={processingRequest}
                    style={{ textTransform: 'capitalize', marginTop: '20px' }}
                    fullWidth
                    // startIcon={props.editExtension ? <Edit /> : <AddCircle style={{ paddingRight: 5 }} />}
                  >
                    Confirm
                    {processingRequest && (
                      <span style={{ marginLeft: 10 }}>
                        <CircularProgress size={20} />{' '}
                      </span>
                    )}
                  </AppButton>
                </DialogActions>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4}></Grid>
      </Grid>
    </Fragment>
  );
};

export default ChangePasswordForm;
