import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Typography, Tooltip, Card, Button, ButtonGroup } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  editModal,
  toggleDeleteCodeModal
} from '../../Tables/gallery-links/helper';

import AppDeleteModal from '../../../components/modal/AppDeleteModal/AppDeleteModal';

import PopupNotif2 from '../../../components/notifications/popupnotif2';
import {initRequest, publishGallery} from '../../../utils/helper';

import { fetchClientGalleryRequest } from '../../../redux/actions/clientGalleryActions';

import { deleteClientGalleryApi } from '../../../api/authServices/clientGallery/clientGallery';
import CreateClientGalleryForm from '../forms';
import AppLoader from '../../loader/loader';
import NoDataFound from '../../common/DataNotFound';
import Img from 'react-cool-img';
import imgPlaceholder from '../../../assets/images/placeholderV.jpeg';

import AppViewModal from '../../modal/appViewModal';
import ManageCategories from '../../photo-gallery/manage';
import CategoryDetails from "../../photo-gallery/details";
import ClientPhotoDetails from "../details";
import PublishButton from "../../publish/publishButton";
import AppConfirmModal from "../../modal/confirmActionModal";
import {publishClientApi, publishServiceApi} from "../../../api/authServices/publish/publish";
import {fetchCategoriesRequest} from "../../../redux/actions/categoriesActions";

export default function ClientGalleryList({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editStoryModal, setEditStoryModal] = useState(false);
  const [viewStoryDetails, setViewStoryDetails] = useState(false);
  const [editStory, setEditStory] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [storyDetails, setStoryDetails] = useState({
    title: '',
    link: '',
    user: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const clientGalleryState = useSelector(state => state.ClientGalleryReducer);
  const { loading, data } = clientGalleryState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchClientGalleryRequest);
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditModalClose = () => {
    setEditStoryModal(false);
  };
  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    setEditStory(true);
    editModal(setEditStoryModal, editStoryModal);
  };

  // Delete love story link
  const deleteCategory = async id => {
    setProcessingRequest(true);
    const data = {
      id: id,
      authToken: user.authToken
    };
    const res = await deleteClientGalleryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Client gallery successfully deleted'
        });
        dispatch(
          fetchClientGalleryRequest({
            authToken: user.authToken
          })
        );
        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Client gallery deletion failed'
        });
      }
    });
  };
  const handleStoryDetails = story => {
    setStoryDetails({
      ...storyDetails,
      title: story.title,
      link: story.gallery_link,
      user: story.user,
      user_text: story.user_text,
      id: story.id
    });
  };
  const handleViewStoryDetailOpen = story => {
    setViewStoryDetails(true);
    handleStoryDetails(story);
  };

  const handleViewStoryDetailClose = () => {
    setViewStoryDetails(false);
  };
  const routeChange = (storyId, storyTitle) => {
    history.push({
      pathname: 'ClientGalleries',
      state: {
        data: {
          id: storyId,
          title: storyTitle
        }
      }
    });
  };

  const viewGallery = (id, title) => {
    history.push({
      pathname: '/view-gallery',
      search: `?client-gallery=${title}`,
      state: {
        data: {
          id,
          title,
          type: 'client-gallery'
        }
      }
    });
  };
  const handleNoGalleryForSelectedCategory = data => {
    if (data.length === 0) {
      alert('data length is zero');
      setErrorPopUp({
        status: true,
        popupType: 'info',
        message: `No gallery found for ${storyDetails.title}`
      });
    } else {
    }
    // handleCloseViewDetails();
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const togglePublishProcess = story => {
    setStoryDetails({
      ...storyDetails,
      id: story.id,
      name: story.title,
      status: story.status
    });
    toggleConfirmModal();
  };
  const publishService = () => {
    publishGallery(
      setProcessingRequest,
      storyDetails,
      user.authToken,
      setErrorPopUp,
      publishClientApi,
      toggleConfirmModal,
      fetchClientGalleryRequest,
      dispatch,
      `Client gallery successfully ${
        storyDetails.status ? 'unpublished' : 'publish'
      }`,
      `Client gallery ${storyDetails.status ? 'unpublished' : 'publish'} failed`
    );
  };
  return (
    <Fragment>
      {!loading ? (
        <Grid container spacing={1}>
          {data &&
            data.map((story, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card className="card-transparent mb-4">
                  <div className="card-img-wrapper">
                    <Tooltip
                      title={''}
                      onClick={() => {
                        viewGallery(story.id, story.title);
                        // handleOpen();
                      }}>
                      <Img
                        style={{ cursor: 'grab' }}
                        placeholder={imgPlaceholder}
                        cache
                        src={story.cover_image}
                        className="card-img-top rounded"
                        alt="..."
                        // width="1237"
                        // height="370"
                      />
                    </Tooltip>
                  </div>
                  <div className="card-body text-left">
                    <h5 className="card-title font-weight-bold font-size-lg">
                      {story.title.toUpperCase()}
                    </h5>
                    <Typography className={'text-dark'} variant={'subtitle2'}>{story.user_text}</Typography>
                    {/*<a target={'_blank'} href={story.gallery_link}>*/}
                    {/*  {story.gallery_link}*/}
                    {/*</a>*/}
                    <br />
                    <br />
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group">
                      {/*<Button*/}
                      {/*  onClick={() => {*/}
                      {/*    handleViewStoryDetailOpen(story);*/}
                      {/*  }}>*/}
                      {/*  <Tooltip title={'View Details'}>*/}
                      {/*    <Visibility color={'primary'} />*/}
                      {/*  </Tooltip>*/}
                      {/*</Button>*/}
                      <Button
                        onClick={() => {
                          handleStoryDetails(story);
                          toggleEditModal(
                            story.title,
                            story.description,
                            story.user,
                            story.id
                          );
                        }}>
                        Edit
                        {/*<Tooltip title={'Edit'}>*/}
                        {/*  <Edit color={'secondary'} />*/}
                        {/*</Tooltip>*/}
                      </Button>
                      <Button
                        onClick={() => {
                          setStoryDetails({
                            ...storyDetails,
                            title: story.title,
                            description: story.description,
                            user: story.user,
                            id: story.id
                          });
                          toggleDeleteModal();
                        }}>
                        Delete
                        {/*<Tooltip title={'Delete'}>*/}
                        {/*  <Delete style={{ color: 'red' }} />*/}
                        {/*</Tooltip>*/}
                      </Button>
                      <Button
                        onClick={() => {
                          routeChange(story.id, story.title);
                        }}>
                        Manage
                        {/*<Tooltip title={'Manage'}>*/}
                        {/*  <TrackChanges color={'primary'} />*/}
                        {/*</Tooltip>*/}
                      </Button>
                      <PublishButton
                        status={story.status}
                        toggleModal={() => togglePublishProcess(story)}
                      />
                    </ButtonGroup>
                  </div>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}
      {!loading && <NoDataFound data={data} />}

      {/*VIEW CATEGORY GALLERY*/}
      {open && (
        <ClientPhotoDetails
          categoryDetails={storyDetails}
          handleNoGalleryForSelectedCategory={
            handleNoGalleryForSelectedCategory
          }
          open={open}
          handleClose={handleClose}
        />
      )}

      {editStory && (
        <CreateClientGalleryForm
          open={editStoryModal}
          handleClose={handleEditModalClose}
          clientGalleryDetails={storyDetails}
          editClientGallery={editStory}
          setEditClientGallery={setEditStory}
        />
      )}
      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete Client Gallery"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete gallery ${storyDetails.title}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteCategory(storyDetails.id);
        }} // action to delete item
      />

      <AppConfirmModal
        open={confirmModal}
        title={`Confirm ${storyDetails.status ? 'unpublished' : 'publish'}`}
        cancelText="No, Cancel"
        confirmText="Yes, Confirm"
        toggleModal={() => {
          toggleConfirmModal();
        }}
        heading={`You are about to publish service ${storyDetails.name}`}
        content={
          <p>
            {' '}
            Are you sure you want to{' '}
            {`${storyDetails.status ? 'unpublished' : 'publish'}`}?
          </p>
        }
        loading={processingRequest}
        confirmAction={() => {
          publishService();
        }} // action to delete item
      />

      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
}
