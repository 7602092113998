import { THE_TEAM } from '../actionTypes';
import { fetchTheTeamApi } from '../../api/authServices/theTeam/theTeam';

export const fetchTheTeam = () => ({
  type: THE_TEAM.FETCH_TEAM_DATA
});

export const fetchTheTeamSuccess = data => ({
  type: THE_TEAM.FETCH_TEAM_SUCCESS,
  payload: {
    data
  }
});

export const fetchTheTeamFail = err => ({
  type: THE_TEAM.FETCH_TEAM_FAIL,
  payload: {
    err
  }
});

export const fetchTheTeamRequest = data => {
  return dispatch => {
    dispatch(fetchTheTeam());
    fetchTheTeamApi(data)
      .then(res => {
        dispatch(fetchTheTeamSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchTheTeamFail(err.message));
      });
  };
};
