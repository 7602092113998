import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSeamless } from '../../layout-components';
import AppModal from '../../components/modal';
import ManageGalleryLinksTable from '../../components/Tables/gallery-links/manageGalleryLinksTable';
import AssignGalleryLinkForm from '../../components/gallery-links/forms';

export default function GalleryLinks() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Gallery Links"
        titleDescription="Tables are the backbone of almost all web applications."
        createObj={true}
        handleOpen={handleOpen}
        tooltipTitle="Assign"
      />

      <ExampleWrapperSeamless sectionHeading="">
        <ManageGalleryLinksTable />
      </ExampleWrapperSeamless>
      {open && <AssignGalleryLinkForm handleClose={handleClose} open={open} />}
    </Fragment>
  );
}
