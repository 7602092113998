import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Container, Card, CardContent } from '@material-ui/core';

import { ExampleWrapperSeamless, PageTitle } from '../../layout-components';
import ManageAboutPageForm from '../../components/about/forms/manageAboutForm';
import { useDispatch, useSelector } from 'react-redux';
import { initRequest } from '../../utils/helper';
import { fetchAboutPageContentRequest } from '../../redux/actions/aboutPageActions';
import AppLoader from '../../components/loader/loader';

import DisplayImages from '../../components/displayImages/displayImages';

import AboutPageTexts from '../../components/about/pageTexts';
import DisPlayTheTeam from '../../components/theTeam/theTeam';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fetchTheTeamRequest } from '../../redux/actions/theTeamActions';
import NoDataFound from "../../components/common/DataNotFound";

const About = () => {
  const [open, setOpen] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const aboutPageState = useSelector(state => state.AboutPageReducer);
  const { loading, data } = aboutPageState;
  const dispatch = useDispatch();
  const teamState = useSelector(state => state.TheTeamReducer);

  useEffect(() => {
    initRequest(dispatch, fetchTheTeamRequest);
  }, [dispatch]);

  useEffect(() => {
    initRequest(dispatch, fetchAboutPageContentRequest);
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      {!loading ? (
        <>
          <PageTitle
            titleHeading="About page"
            // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
            createObj={true}
            data={data}
            handleOpen={handleOpen}
          />
          {data && data.length > 0 ? (
            <div className="hero-wrapper bg-composed-wrapper bg-premium-dark min-vh-100">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image opacity-1"
                  style={{ backgroundImage: 'url(' + data[0].page_image + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-second opacity-3" />
                <div className="bg-composed-wrapper--bg bg-red-lights opacity-1" />
                <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                  <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                    <Grid item xs={12} sm={6} md={6} className={'text-center'}>
                      <h6 className="display-2 mb-2 font-weight-bold text-white">
                        Page content
                      </h6>
                      <div className="divider border-2 border-light my-5 border-light opacity-2 mx-auto rounded-circle w-50" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                  </Grid>
                  <Container maxWidth="lg" className="pb-5">
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={10}
                        className="px-0 mx-auto d-flex align-items-center">
                        <div className="text-center">
                          <div className="px-4 px-sm-0 text-white mt-4">
                            {/*<ExampleWrapperSeamless sectionHeading="Right side image">*/}
                            {/*  <DisplayImages*/}
                            {/*    infoImage={data[0].info_image}*/}
                            {/*    headerTitle={data[0].header_title}*/}
                            {/*    // navImage={data[0].page_image}*/}
                            {/*    infoTitle={data[0].info_title}*/}
                            {/*  />*/}
                            {/*</ExampleWrapperSeamless>*/}

                            {/*<ExampleWrapperSeamless sectionHeading="Header bold text">*/}
                              <Grid container spacing={4}>
                                {/*<Grid item xs={12} sm={6} md={3}></Grid>*/}
                                <Grid item xs={12} sm={12} md={4}>
                                  <Card className="card-box mb-4">
                                    <div className="card-header">
                                      <div className="card-header--title">
                                        {/*<small>Scrollable</small>*/}
                                        <b>Header bold text</b>
                                      </div>
                                    </div>
                                    <CardContent className="p-3">
                                      <div className="scroll-area-sm">
                                        <PerfectScrollbar>
                                          <p style={{ textAlign: 'left' }}>
                                            {data[0].header_text}
                                          </p>
                                        </PerfectScrollbar>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8}>
                                  {/*<ExampleWrapperSeamless sectionHeading="Info texts">*/}
                                    <AboutPageTexts
                                      infoText1={data[0].info_text1}
                                      infoText2={data[0].info_text2}
                                      heading1={'Header bold text'}
                                      heading2={'Information text'}
                                    />
                                  {/*</ExampleWrapperSeamless>*/}

                                </Grid>
                              </Grid>
                            {/*</ExampleWrapperSeamless>*/}
                            <ExampleWrapperSeamless sectionHeading="Right side image">
                              <DisplayImages
                                infoImage={data[0].info_image}
                                headerTitle={data[0].header_title}
                                // navImage={data[0].page_image}
                                infoTitle={data[0].info_title}
                              />
                            </ExampleWrapperSeamless>
                            {/*{teamState.data && teamState.data.length && (*/}
                            {/*  <ExampleWrapperSeamless sectionHeading="The Team">*/}
                            {/*    <Grid container spacing={2}>*/}
                            {/*      {teamState.data.map(team => (*/}
                            {/*        <DisPlayTheTeam*/}
                            {/*          name={team.name}*/}
                            {/*          image={team.image}*/}
                            {/*        />*/}
                            {/*      ))}*/}
                            {/*    </Grid>*/}
                            {/*  </ExampleWrapperSeamless>*/}
                            {/*)}*/}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}

          {open && (
            <ManageAboutPageForm open={open} handleClose={handleClose} />
          )}
        </>
      ) : (
        <AppLoader loading={loading} />
      )}
    </Fragment>
  );
};

export default About;
