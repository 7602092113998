import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import CategoriesList from '../../components/photo-gallery';
import CreateGalleryCategoryForm from '../../components/photo-gallery/gallery-category/forms';
import PageList from '../../components/home/pageList';
import CreatePageImagesForm from '../../components/home/pageImageForm';
export default function Home() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage Page images"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      <PageList />

      {open && <CreatePageImagesForm open={open} handleClose={handleClose} />}
    </Fragment>
  );
}
