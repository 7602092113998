import React, { Fragment } from 'react';
import {
  Button, Card,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle/CustomDialogTitle';

const AppViewModal = ({ open, handleClose, children, title, modalSize }) => {
  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        open={open}
        fullWidth
        maxWidth={modalSize}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <CustomDialogTitle toggleModal={handleClose} titleText={title} />
        <DialogContent className="p-0">
          <Card>
            <div style={{ marginLeft: 20, marginRight: 20 }}>{children}</div>

          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AppViewModal;
