import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchTheTeamApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/pages/the-team/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createTheTeamApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.profileImage);
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('title', data.title);
  return await AxiosService.post(
    `${baseUrl}/adminportal/pages/the-team/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateTheTeamApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.profileImage);
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('title', data.title);
  if (data.profileImage.length === 0) {
    formData.delete('cover_image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages/the-team/${data.id}/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteTheTeamApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/pages/the-team/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
