import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Container } from '@material-ui/core';

import { ExampleWrapperSeamless, PageTitle } from '../../layout-components';

import { useDispatch, useSelector } from 'react-redux';
import { initRequest } from '../../utils/helper';
import { fetchAboutPageContentRequest } from '../../redux/actions/aboutPageActions';
import AppLoader from '../../components/loader/loader';

import AboutPageTexts from '../../components/about/pageTexts';

import ManageContactUsPageForm from '../../components/contactUs/forms/manageContactUsForm';
import {fetchContactPageContentRequest} from "../../redux/actions/contactUsActions";

const ContactUs = () => {
  const [open, setOpen] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const contactPageState = useSelector(state => state.ContactPageReducer);
  const { loading, data } = contactPageState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchContactPageContentRequest);
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleInfoData = data => {
    return (
      <>
        <p>name: {data.email}</p>
        <p>name: {data.phone}</p>
        <p>name: {data.address}</p>
      </>
    );
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      {!loading ? (
        <>
          <PageTitle
            titleHeading="Contact-us page"
            // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
            createObj={true}
            handleOpen={handleOpen}
          />
          {data.length > 0 ? (
            <div className="hero-wrapper bg-composed-wrapper bg-premium-dark min-vh-100">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image opacity-5"
                  style={{ backgroundImage: 'url(' + data[0].nav_image + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-second opacity-3" />
                <div className="bg-composed-wrapper--bg bg-red-lights opacity-1" />
                <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                  <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                    <Grid item xs={12} sm={6} md={6} className={'text-center'}>
                      <h6 className="display-2 mb-2 font-weight-bold text-white">
                        Page content
                      </h6>
                      <div className="divider border-2 border-light my-5 border-light opacity-2 mx-auto rounded-circle w-50" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}></Grid>
                  </Grid>
                  <Container maxWidth="lg" className="pb-5">
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={10}
                        className="px-0 mx-auto d-flex align-items-center">
                        <div className="text-center">
                          <div className="px-4 px-sm-0 text-white mt-4">
                            <ExampleWrapperSeamless sectionHeading="Contact Informations">
                              <AboutPageTexts
                                infoText1={() => {
                                  handleInfoData(data[0]);
                                }}
                                infoText2={data[0].description}
                                heading1={'information'}
                                heading2={'description'}
                              />
                            </ExampleWrapperSeamless>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : (
            <h5 className={'text-center'}>No contact information found</h5>
          )}
          {open && (
            <ManageContactUsPageForm open={open} handleClose={handleClose} />
          )}
        </>
      ) : (
        <AppLoader />
      )}
    </Fragment>
  );
};

export default ContactUs;
