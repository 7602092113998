import React, { Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';

const DataTableLoader = props => {
  return (
    <Fragment>
      <CircularProgress size={12} thickness={10} />
      {"\u00A0"}
      {props.message}
    </Fragment>
  );
};
export default DataTableLoader;
