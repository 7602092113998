import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const publishLoveStoryApi = async ({ data, onSuccess, onError}) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-love-stories/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
export const publishServiceApi = async ({ data, onSuccess, onError}) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-services/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
export const publishClientApi = async ({ data, onSuccess, onError}) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-client-galleries/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
export const publishPageImagesApi = async ({ data, onSuccess, onError}) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages-images/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
