import { USER_ENQUERIES } from '../actionTypes';
import { fetchTheTeamApi } from '../../api/authServices/theTeam/theTeam';
import {fetchUserEnqueriesApi} from "../../api/authServices/userEnqueries/userEnqueries";

export const fetchUserEnqueries = () => ({
  type: USER_ENQUERIES.FETCH_ENQUERIES_DATA
});

export const fetchUserEnqueriesSuccess = data => ({
  type: USER_ENQUERIES.FETCH_ENQUERIES_SUCCESS,
  payload: {
    data
  }
});

export const fetchUserEnqueriesFail = err => ({
  type: USER_ENQUERIES.FETCH_ENQUERIES_FAIL,
  payload: {
    err
  }
});

export const fetchUserEnqueriesRequest = data => {
  return dispatch => {
    dispatch(fetchUserEnqueries());
    fetchUserEnqueriesApi(data)
      .then(res => {
        dispatch(fetchUserEnqueriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchUserEnqueriesFail(err.message));
      });
  };
};
