import { CONTACT_US_INFO } from '../actionTypes';
import { fetchContactInfoApi } from '../../api/authServices/contactUs/contactInfo';

export const fetchContactInfo = () => ({
  type: CONTACT_US_INFO.FETCH_CONTACT_INFO_DATA
});

export const fetchContactInfoSuccess = data => ({
  type: CONTACT_US_INFO.FETCH_CONTACT_INFO_SUCCESS,
  payload: {
    data
  }
});

export const fetchContactInfoFail = err => ({
  type: CONTACT_US_INFO.FETCH_CONTACT_INFO_FAIL,
  payload: {
    err
  }
});

export const fetchContactInfoRequest = data => {
  return dispatch => {
    dispatch(fetchContactInfo());
    fetchContactInfoApi(data)
      .then(res => {
        dispatch(fetchContactInfoSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchContactInfoFail(err.message));
      });
  };
};
