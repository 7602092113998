import React, {useEffect} from 'react';
import { Tooltip } from '@material-ui/core';
import { ListAltOutlined } from '@material-ui/icons';

const PublishButton = ({ status, toggleModal }) => {
  return (
    <Tooltip
      title={status ? 'Unpublish' : 'Publish'}
      onClick={() => {
        toggleModal();
      }}>
      <ListAltOutlined
        color={status ? 'primary' : 'default'}
        style={{ paddingTop: 5, fontSize: 30 }}
      />
    </Tooltip>
  );
};
export default PublishButton;
