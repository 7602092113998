import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Card, Button, Tooltip, ButtonGroup } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  editModal,
  toggleDeleteCodeModal
} from '../../Tables/gallery-links/helper';

import AppDeleteModal from '../../../components/modal/AppDeleteModal/AppDeleteModal';

import PopupNotif2 from '../../../components/notifications/popupnotif2';
import {initRequest, publishGallery} from '../../../utils/helper';
import CreateLoveStoriesForm from '../forms';
import { fetchLoveStoryRequest } from '../../../redux/actions/loveStoryActions';
import {deleteLoveStoryApi, updateLoveStoryApi} from '../../../api/authServices/loveStories/loveStories';

import SingleLoveStoryDetail from './singleLoveStoryDetail';
import NoDataFound from '../../common/DataNotFound';
import AppLoader from '../../loader/loader';
import Img from 'react-cool-img';
import imgPlaceholder from '../../../assets/images/aaa.png';
import AppConfirmModal from "../../modal/confirmActionModal";
import {publishLoveStoryApi, updateGalleryStatusApi} from "../../../api/authServices/publish/publish";
import PublishButton from "../../publish/publishButton";

export default function LoveStoryList({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editStoryModal, setEditStoryModal] = useState(false);
  const [viewStoryDetails, setViewStoryDetails] = useState(false);
  const [editStory, setEditStory] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [storyDetails, setStoryDetails] = useState({
    title: '',
    description: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const loveStoryState = useSelector(state => state.LoveStoryReducer);
  const { loading, data } = loveStoryState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchLoveStoryRequest);
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditModalClose = () => {
    setEditStoryModal(false);
  };
  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    setEditStory(true);
    editModal(setEditStoryModal, editStoryModal);
  };

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const publishLoveStory = () => {
    publishGallery(
      setProcessingRequest,
      storyDetails,
      user.authToken,
      setErrorPopUp,
      publishLoveStoryApi,
      toggleConfirmModal,
      fetchLoveStoryRequest,
      dispatch,
      `Love story successfully ${
        storyDetails.status ? 'unpublished' : 'publish'
      }`,
      `Love story ${storyDetails.status ? 'unpublished' : 'publish'} failed`
    );
  };

  // Delete love story link
  const deleteCategory = async id => {
    setProcessingRequest(true);
    const data = {
      id: id,
      authToken: user.authToken
    };
    const res = await deleteLoveStoryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Love story successfully deleted'
        });
        dispatch(
          fetchLoveStoryRequest({
            authToken: user.authToken
          })
        );
        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Gallery category deletion failed'
        });
      }
    });
  };
  const handleDescriptionData = data => {
    const desc = JSON.parse(data);
    return (
      <Fragment>
        <p className="card-text">{desc.description1}</p>
        <p className="card-text">{desc.description2}</p>
        <p className="card-text">{desc.description3}</p>
        <p className="card-text">{desc.description4}</p>
        <p className="card-text">{desc.description5}</p>
      </Fragment>
    );
  };
  const handleStoryDetails = story => {
    const desc = JSON.parse(story.description);
    setStoryDetails({
      ...storyDetails,
      title: story.title,
      coverImage: story.cover_image,
      description1: desc.description1,
      description2: desc.description2,
      description3: desc.description3,
      description4: desc.description4,
      description5: desc.description5,
      user: story.user,
      user_text: story.user_text,
      id: story.id
    });
  };
  const handleViewStoryDetailOpen = story => {
    setViewStoryDetails(true);
    handleStoryDetails(story);
  };
  const handleViewStoryDetailClose = () => {
    setViewStoryDetails(false);
  };
  const routeChange = (storyId, storyTitle) => {
    history.push({
      pathname: 'ManageLoveStory',
      state: {
        data: {
          id: storyId,
          title: storyTitle
        }
      }
    });
  };
  const togglePublishProcess = (story) => {
    setStoryDetails({
      ...storyDetails,
      id: story.id,
      status: story.status
    });
    toggleConfirmModal();
  };
  const viewGallery = (id, title) => {
    history.push({
      pathname: '/view-gallery',
      search: `?love-story=${title}`,
      state: {
        data: {
          id,
          title,
          type: 'love-story'
        }
      }
    });
  };
  return (
    <Fragment>
      {!loading && data.length > 0 ? (
        <Grid container spacing={1}>
          {data.map((story, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className="card-transparent mb-4">
                <div className="card-img-wrapper">
                  <Tooltip
                    title={'Click to view details'}
                    onClick={() => {
                      // handleViewStoryDetailOpen(story);
                      viewGallery(story.id, story.title)
                    }}>
                    <Img
                      style={{ cursor: 'grab'}}
                      placeholder={imgPlaceholder}
                      src={story.cover_image}
                      className="card-img-top rounded"
                      alt="..."
                      // width="1237"
                      height="280"
                      cache
                    />
                  </Tooltip>
                </div>
                <div className="card-body text-left">
                  <h5 className="card-title font-weight-bold font-size-lg">
                    {story.title}
                  </h5>

                  {/*{handleDescriptionData(story.description)}*/}

                  <ButtonGroup
                    key={index}
                    variant="contained"
                    aria-label="outlined primary button group">
                    {/*<Button*/}
                    {/*  onClick={() => {*/}
                    {/*    handleViewStoryDetailOpen(story);*/}
                    {/*  }}>*/}
                    {/*  <Tooltip title={'View Details'}>*/}
                    {/*    <Visibility color={'primary'} />*/}
                    {/*  </Tooltip>*/}
                    {/*</Button>*/}
                    <Button
                      onClick={() => {
                        handleStoryDetails(story);
                        toggleEditModal(
                          story.title,
                          story.description,
                          story.user,
                          story.id,
                        );
                      }}>
                      Edit
                      {/*<Tooltip title={'Edit'}>*/}
                      {/*  <Edit color={'secondary'} />*/}
                      {/*</Tooltip>*/}
                    </Button>
                    <Button
                      onClick={() => {
                        setStoryDetails({
                          ...storyDetails,
                          title: story.title,
                          description: story.description,
                          user: story.user,
                          id: story.id,
                        });
                        toggleDeleteModal();
                      }}>
                      Delete
                      {/*<Tooltip title={'Delete'}>*/}
                      {/*  <Delete style={{ color: 'red' }} />*/}
                      {/*</Tooltip>*/}
                    </Button>
                    <Button
                      onClick={() => {
                        routeChange(story.id, story.title);
                      }}>
                      Manage
                      {/*<Tooltip title={'Manage'}>*/}
                      {/*  <TrackChanges color={'primary'} />*/}
                      {/*</Tooltip>*/}
                    </Button>
                    <PublishButton
                      status={story.status}
                      toggleModal={() => togglePublishProcess(story)}
                    />
                  </ButtonGroup>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}
      {!loading && <NoDataFound data={data} />}
      {editStory && (
        <CreateLoveStoriesForm
          open={editStoryModal}
          handleClose={handleEditModalClose}
          storyDetails={storyDetails}
          editStory={editStory}
          setEditStory={setEditStory}
        />
      )}
      {viewStoryDetails && (
        <SingleLoveStoryDetail
          handleClose={handleViewStoryDetailClose}
          open={viewStoryDetails}
          storyDetail={storyDetails}
        />
      )}
      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete Love Story"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete love story ${storyDetails.title}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteCategory(storyDetails.id);
        }} // action to delete item
      />
      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />

      <AppConfirmModal
        open={confirmModal}
        title={`Confirm ${storyDetails.status ? 'unpublished' : 'publish'}`}
        cancelText="No, Cancel"
        confirmText="Yes, Confirm"
        toggleModal={() => {
          toggleConfirmModal();
        }}
        heading={`You are about to publish love story ${storyDetails.title}`}
        content={<p> Are you sure you want to {`${storyDetails.status ? 'unpublished' : 'publish'}`}?</p>}
        loading={processingRequest}
        confirmAction={() => {
          publishLoveStory();
        }} // action to delete item
      />
    </Fragment>
  );
}
