import React, { Fragment } from 'react';
import DescriptionField from './descriptionField';

const DescriptionList = ({
  register,
  errors,
  editForm,
  editDetails,
  handleChange,
  setStateValues,
  values,
  defaultData
}) => {
  return (
    <Fragment>
      <DescriptionField
        name="description1"
        values={values}
        errors={errors}
        editDetails={editDetails}
        editForm={editForm}
        handleChange={handleChange}
        register={register}
        setStateValues={setStateValues}
        title={'Description1'}
        required={true}
        label={'Description1'}
        defaultData={editForm && defaultData.description1}
        error={errors.description1}
        errorMessage={errors.description1 && errors.description1.message}
      />
      <DescriptionField
        name={'description2'}
        values={values}
        errors={errors}
        editDetails={editDetails}
        editForm={editForm}
        handleChange={handleChange}
        register={register}
        setStateValues={setStateValues}
        title={'Description2'}
        required={false}
        label={'Description2(Optional)'}
        defaultData={editForm && defaultData.description2}
      />
      <DescriptionField
        name={'description3'}
        values={values}
        errors={errors}
        editDetails={editDetails}
        editForm={editForm}
        handleChange={handleChange}
        register={register}
        setStateValues={setStateValues}
        title={'Description3'}
        required={false}
        label={'Description3(Optional)'}
        defaultData={editForm && defaultData.description3}
      />
      <DescriptionField
        name={'description4'}
        values={values}
        errors={errors}
        editDetails={editDetails}
        editForm={editForm}
        handleChange={handleChange}
        register={register}
        setStateValues={setStateValues}
        title={'Description4'}
        required={false}
        label={'Description4(Optional)'}
        defaultData={editForm && defaultData.description4}
      />
      <DescriptionField
        name={'description5'}
        values={values}
        errors={errors}
        editDetails={editDetails}
        editForm={editForm}
        handleChange={handleChange}
        register={register}
        setStateValues={setStateValues}
        title={'Description4'}
        required={false}
        label={'Description5(Optional)'}
        defaultData={editForm && defaultData.description5}
      />
    </Fragment>
  );
};
export default DescriptionList;
