import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Card, Button, Tooltip } from '@material-ui/core';
import CategoryDetails from '../../../components/photo-gallery/details';
import AppViewModal from '../../../components/modal/appViewModal';
import ManageCategories from '../../../components/photo-gallery/manage';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesRequest } from '../../../redux/actions/categoriesActions';
import CreateGalleryCategoryForm from '../../../components/photo-gallery/gallery-category/forms';
import {
  editModal,
  toggleDeleteCodeModal
} from '../../../components/Tables/gallery-links/helper';

import AppDeleteModal from '../../../components/modal/AppDeleteModal/AppDeleteModal';
import { deleteCategoryApi } from '../../../api/authServices/galleryCategory/category';
import PopupNotif2 from '../../../components/notifications/popupnotif2';
import { initRequest, publishGallery } from '../../../utils/helper';
import AppLoader from '../../../components/loader/loader';
import NoDataFound from '../../../components/common/DataNotFound';
import Img from 'react-cool-img';
import imgPlaceholder from '../../../assets/images/placeholderV.jpeg';

import PublishButton from '../../../components/publish/publishButton';
import AppConfirmModal from '../../../components/modal/confirmActionModal';
import { publishServiceApi } from '../../../api/authServices/publish/publish';
import AppButtonGroup from '../../../components/AppButtonGroup/AppButtonGroup';

export default function LivePreviewExample({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({
    name: '',
    description: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const galleryCategoryState = useSelector(state => state.CategoryReducer);
  const { loading, data } = galleryCategoryState;
  const [confirmModal, setConfirmModal] = useState(false);
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchCategoriesRequest);
  }, [dispatch]);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenViewDetails = () => {
    setViewDetails(true);
  };

  const handleCloseViewDetails = () => {
    setViewDetails(false);
  };
  const handleEditModalClose = () => {
    setEditCategoryModal(false);
  };

  const handleOpenManage = () => {
    setOpenManage(true);
  };

  const handleCloseManage = () => {
    setOpenManage(false);
  };

  const routeChange = (categoryId, categoryName, description) => {
    history.push({
      pathname: 'ManageCategory',
      state: {
        data: {
          id: categoryId,
          name: categoryName,
          description: description
        }
      }
    });
  };

  const viewGallery = (id, title) => {
    history.push({
      pathname: '/view-gallery',
      search: `?service=${title}`,
      state: {
        data: {
          id,
          title,
          type: 'service'
        }
      }
    });
  };
  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    setEditCategory(true);
    editModal(setEditCategoryModal, editCategoryModal);
  };

  // Delete gallery link
  const deleteCategory = async id => {
    setProcessingRequest(true);
    const data = {
      id: id,
      authToken: user.authToken
    };
    const res = await deleteCategoryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Gallery category successfully deleted'
        });
        dispatch(
          fetchCategoriesRequest({
            authToken: user.authToken
          })
        );
        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Gallery category deletion failed'
        });
      }
    });
  };
  const handleNoGalleryForSelectedCategory = data => {
    if (data.length === 0) {
      alert('data length is zero');
      setErrorPopUp({
        status: true,
        popupType: 'info',
        message: `No gallery found for ${categoryDetails.name}`
      });
    } else {
    }
    handleCloseViewDetails();
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const togglePublishProcess = category => {
    setCategoryDetails({
      ...categoryDetails,
      id: category.id,
      name: category.name,
      status: category.status
    });
    toggleConfirmModal();
  };
  const publishService = () => {
    publishGallery(
      setProcessingRequest,
      categoryDetails,
      user.authToken,
      setErrorPopUp,
      publishServiceApi,
      toggleConfirmModal,
      fetchCategoriesRequest,
      dispatch,
      `Service successfully ${
        categoryDetails.status ? 'unpublished' : 'publish'
      }`,
      `Service ${categoryDetails.status ? 'unpublished' : 'publish'} failed`
    );
  };
  return (
    <Fragment>
      {!loading ? (
        <Grid container spacing={1}>
          {data &&
            data.length > 0 &&
            data.map((category, index) => {
              return (
                <Grid item xs={12} sm={4} md={3} key={index}>
                  <Card className="card-transparent mb-2">
                    <div className="card-img-wrapper">
                      <Tooltip
                        title={''}
                        onClick={() => {
                          viewGallery(category.id, category.name);
                        }}>
                        {/*<ResizeImage*/}
                        {/*  src={category.cover_image}*/}
                        {/*  alt="Tsunami bt hokusai"*/}
                        {/*  options={{ width: 200 }}*/}
                        {/*/>*/}
                        <Img
                          style={{ cursor: 'grab' }}
                          src={category.cover_image}
                          className="card-img-top rounded"
                          alt="..."
                          placeholder={imgPlaceholder}
                          cache // Default is true, just for demo
                        />
                      </Tooltip>
                    </div>
                    <AppButtonGroup name={category.name}>
                      <Button
                        onClick={() => {
                          setCategoryDetails({
                            ...categoryDetails,
                            name: category.name,
                            description: category.description,
                            description2: category.description2,
                            id: category.id
                          });
                          toggleEditModal(
                            category.name,
                            category.description,
                            category.id
                          );
                        }}>
                        {/*<Tooltip title={'Edit'}>*/}
                        {/*  <Edit color={'secondary'} />*/}
                        {/*</Tooltip>*/}
                        Edit
                      </Button>
                      <Button
                        // className={'text-danger'}
                        onClick={() => {
                          setCategoryDetails({
                            ...categoryDetails,
                            name: category.name,
                            description: category.description,
                            id: category.id
                          });
                          toggleDeleteModal();
                        }}>
                        {/*<Tooltip title={'Delete'}>*/}
                        {/*  <Delete style={{ color: 'red' }} />*/}
                        {/*</Tooltip>*/}
                        Delete
                      </Button>
                      <Button
                        onClick={() => {
                          routeChange(
                            category.id,
                            category.name,
                            category.description
                          );
                        }}>
                        Manage
                      </Button>
                      <PublishButton
                        status={category.status}
                        toggleModal={() => togglePublishProcess(category)}
                      />
                    </AppButtonGroup>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}

      {!loading && <NoDataFound data={data} />}

      {/*VIEW CATEGORY GALLERY*/}
      {open && (
        <CategoryDetails
          categoryDetails={categoryDetails}
          handleNoGalleryForSelectedCategory={
            handleNoGalleryForSelectedCategory
          }
          open={open}
          handleClose={handleClose}
        />
      )}
      <AppViewModal
        modalSize={'md'}
        title={'Manage Category'}
        children={<ManageCategories />}
        open={openManage}
        handleClose={handleCloseManage}
      />
      {editCategoryModal && (
        <CreateGalleryCategoryForm
          open={editCategoryModal}
          handleClose={handleEditModalClose}
          categoryDetails={categoryDetails}
          editCategory={editCategory}
          setEditCategory={setEditCategory}
        />
      )}

      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete Service"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete service ${categoryDetails.name}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteCategory(categoryDetails.id);
        }} // action to delete item
      />
      <AppConfirmModal
        open={confirmModal}
        title={`Confirm ${categoryDetails.status ? 'unpublished' : 'publish'}`}
        cancelText="No, Cancel"
        confirmText="Yes, Confirm"
        toggleModal={() => {
          toggleConfirmModal();
        }}
        heading={`You are about to publish service ${categoryDetails.name}`}
        content={
          <p>
            {' '}
            Are you sure you want to{' '}
            {`${categoryDetails.status ? 'unpublished' : 'publish'}`}?
          </p>
        }
        loading={processingRequest}
        confirmAction={() => {
          publishService();
        }} // action to delete item
      />
      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
}
