import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchCategoryApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/manage-services/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createCategoryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.image);
  formData.append('site_cover_image', data.image);
  formData.append('orig_image', data.image);
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('description2', data.description2);


  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-services/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateCategoryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.image);
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('description2', data.description2);
  if (data.image.length === 0) {
    formData.delete('cover_image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-services/${data.id}/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteCategoryApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-services/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
