import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AppDeleteModal from '../../../modal/AppDeleteModal/AppDeleteModal';
import PopupNotif2 from '../../../notifications/popupnotif2';
import { dataFormatter, editModal, toggleDeleteCodeModal } from './helper';
import {
  setTableColumn,
  setTableOptions,
  setTableOptions2
} from './tableSettings';
import { useDispatch, useSelector } from 'react-redux';

import { initRequest } from '../../../../utils/helper';
import CreateAdminForm from '../../../admin/forms/index';
import { fetchAllAdminRequest } from '../../../../redux/actions/adminActions';
import { deleteAdminApi } from '../../../../api/admins/admins';

const ManageEmailTable = () => {
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [tableData, setTableData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [adminDetails, setAdminDetails] = useState([]);
  const [options, setOptions] = useState({});
  const [options2, setOptions2] = useState({});
  const [editAdmin, setEditAdmin] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const adminState = useSelector(state => state.AdminReducer);
  const { loading, data } = adminState;
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  useEffect(() => {
    initRequest(dispatch, fetchAllAdminRequest);
  }, [dispatch]);

  useEffect(() => {
    dataFormatter(data, setTableData);
  }, [data]);

  useEffect(() => {
    setTableColumn(
      setColumns,
      adminDetails,
      setEditAdminModal,
      editAdminModal,
      setDeleteCodeModal,
      deleteCodeModal,
      setAdminDetails,
      setEditAdmin
    );
  }, [deleteCodeModal, editAdminModal, adminDetails]);

  useEffect(() => {
    setTableOptions(setOptions);

    setTableOptions2(setOptions2);
  }, []);

  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    editModal(setEditAdminModal, editAdminModal);
  };

  const deleteUser = async () => {
    setProcessingRequest(true);
    const data = {
      id: adminDetails.id,
      authToken: user.authToken
    };
    const res = await deleteAdminApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        dispatch(
          fetchAllAdminRequest({
            authToken: user.authToken
          })
        );
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'User successfully deleted'
        });

        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'User deletion failed'
        });
      }
    });
  };

  return (
    <div>
      {loading ? (
        <MUIDataTable data={[]} columns={columns} options={options2} />
      ) : (
        <MUIDataTable data={tableData} columns={columns} options={options} />
      )}

      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete Admin"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete admin ${adminDetails.username}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteUser();
        }} // action to delete item
      />
      {editAdmin && (
        <CreateAdminForm
          handleClose={() => toggleEditModal()}
          editUser={editAdmin}
          open={editAdminModal}
          userDetails={adminDetails}
          setEditUser={setEditAdmin}
        />
      )}

      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </div>
  );
};

export default ManageEmailTable;
