import { THE_TEAM } from '../redux/actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: false
};

export default function TheTeamReducer(state = initialState, action) {
  switch (action.type) {
    case THE_TEAM.FETCH_TEAM_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      };
    case THE_TEAM.FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case THE_TEAM.FETCH_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
