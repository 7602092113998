import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchUserEnqueriesApi =  data => {
  return AxiosService.get(`${baseUrl}/adminportal/user-enqueries/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const deleteUserEnqueriesApi = ({ data, onSuccess, onError }) => {
  return AxiosService.delete(`${baseUrl}/adminportal/user-enqueries/${data.id}/`, {
      headers: { Authorization: `${data.authToken}` }
  })
    .then(onSuccess)
    .catch(onError);
};
