import React from 'react';
import { Tooltip } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/Delete';
import DataTableLoader from '../DataTableLoader';
import {deleteModal, deleteModalFromTable, deleteService, viewGallery} from './helper';
import { Visibility } from '@material-ui/icons';

export const setTableColumn = (
  setColumns,
  setGalleryDetails,
  codeDetails,
  setViewGalleryModal,
  viewGalleryModal,
  setCodeModal,
  codeDeleteModal
) =>
  setColumns([
    {
      name: 'counter',
      label: '#',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'land_scape_img',
      label: 'Image',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'time_created',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Action',
      label: <span style={{ marginLeft: 20 }}>Action</span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (e, d) => {
          const rData = {
            name: d.rowData[2],
            id: d.rowData[5],
            gal: d.rowData[6]
          };
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip
                title="View"
                onClick={() => {
                  viewGallery(
                    setGalleryDetails,
                    setViewGalleryModal,
                    viewGalleryModal,
                    rData
                  );
                }}
                style={{ cursor: 'pointer', marginLeft: 40}}>
                <Visibility color="primary" />
              </Tooltip>
              {/*<Tooltip*/}
              {/*  title="Delete"*/}
              {/*  style={{ cursor: 'pointer', marginLeft: 20 }}*/}
              {/*  onClick={() => {*/}
              {/*    deleteModal(*/}
              {/*      setGalleryDetails,*/}
              {/*      setCodeModal,*/}
              {/*      codeDeleteModal,*/}
              {/*      rData*/}
              {/*    );*/}
              {/*  }}>*/}
              {/*  <DeleteForever style={{ color: 'red', marginLeft: 10 }} />*/}
              {/*</Tooltip>*/}
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'gal',
      label: 'test',
      options: {
        display: 'excluded'
      }
    }
  ]);

export const setTableOptions = (
  setOptions,
  loading,
  editExtension,
  setRefreshTable,
  rowsSelected,
  setRowsSelected,
  data,
  setGalleryDetails,
  setCodeModal,
  codeDeleteModal
) =>
  setOptions({
    filterType: 'textField',
    selectableRowsHeader: true,
    // selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    selectableRows: 'multiple',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    rowsSelected: rowsSelected,
    onRowsDelete: (rowsDeleted, newData) => {
      const selectedItems = rowsDeleted.data.map(item => {
        return data[item.index].id;
      });
      const counts = selectedItems.length;
      deleteModalFromTable(setGalleryDetails, setCodeModal, codeDeleteModal, {
        name: `(counts: ${counts})`,
        id: selectedItems
      });
      return false;
    },
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'No data found'
      }
    }
  });

export const setTableOptions2 = (setOptions2) =>
  setOptions2({
    filterType: 'textField',
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: <DataTableLoader message="Fetching data..." />
      }
    }
  });
