import React, {Fragment} from 'react';
import { Card, Grid } from '@material-ui/core';
import { mediaBaseUrl } from '../../../api/media_baseurl';
import Img from 'react-cool-img';

export default function SingleCategoryDetail({ image, carousel }) {
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={12}>
          <Card className="card-transparent mb-4">
            <div className="card-img-wrapper">
              {image.slice(0, 4) !== 'http' && (
                <Img
                  src={carousel ? image : `${mediaBaseUrl}${image}`}
                  className="card-img-top rounded"
                  alt="..."
                  cache
                />
              )}

              {image.slice(0, 4) === 'http' && (
                <Img
                  src={image}
                  className="card-img-top rounded"
                  alt="..."
                  cache
                />
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
