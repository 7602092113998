import React, { Fragment } from 'react';

import { Grid, Card, CardContent, Button, Tooltip } from '@material-ui/core';
import SingleImageView from '../singleImageView/SingleImageView';
import Img from "react-cool-img";
import imgPlaceholder from '../../assets/images/aaa.png';

export default function DisplayImages({ navImage, infoImage }) {
  const [photo, setPhoto] = React.useState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  const handleSetImage = img => {
    setPhoto(img);
  };
  return (
    <Fragment>
      <Grid container spacing={2}>
        {/*<Grid item xs={12} sm={12} md={6}>*/}
        {/*  <Card className="mb-4">*/}
        {/*    <Tooltip title={''}*/}
        {/*      onClick={() => {*/}
        {/*        handleSetImage(navImage);*/}
        {/*        handleOpen();*/}
        {/*      }}>*/}
        {/*      <Img*/}
        {/*        placeholder={imgPlaceholder}*/}
        {/*        cache*/}
        {/*        alt="image"*/}
        {/*        className="card-img-top"*/}
        {/*        src={navImage}*/}
        {/*        style={{ cursor: 'grab'}}*/}
        {/*      />*/}
        {/*    </Tooltip>*/}
        {/*    <CardContent className="p-3">*/}
        {/*      <h6 className="card-title font-weight-bold font-size-lg">*/}
        {/*        {'Top page image'}*/}
        {/*      </h6>*/}
        {/*    </CardContent>*/}
        {/*  </Card>*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={12} md={3}>
          <Card className="mb-4">
            <Tooltip
              title={''}
              onClick={() => {
                handleSetImage(infoImage);
                handleOpen();
              }}>
              <Img cache placeholder={imgPlaceholder} alt="image" className="card-img-top" src={infoImage} style={{ cursor: 'grab'}} />
            </Tooltip>
            <CardContent className="p-3">
              <h6 className="card-title font-weight-bold font-size-lg">
                {'Info image'}
              </h6>
              {/*<Button*/}
              {/*  color="primary"*/}
              {/*  variant="contained"*/}
              {/*  onClick={() => {*/}
              {/*    handleSetImage(infoImage);*/}
              {/*  }}>*/}
              {/*  view*/}
              {/*</Button>*/}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && (
        <SingleImageView image={photo} open={open} handleClose={handleClose} />
      )}
    </Fragment>
  );
}
