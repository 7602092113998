import React, {Fragment, useEffect, useState} from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Button,
  Tooltip
} from '@material-ui/core';

import { connect, useSelector } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/images/react.svg';

import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderUserbox from '../../layout-components/HeaderUserbox';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useHistory } from 'react-router';
import {useDispatch} from "react-redux";
import {recoverAuth} from "../../redux/actions/authActions";
import {recoverUserAuth} from "../../utils/helper";
import PopupNotif2 from "../../components/notifications/popupnotif2";
import {setNotifyParams} from "../../redux/actions/notifications";
const Header = props => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;
  const authState = useSelector(state => state.AuthReducer);
  const notifyState = useSelector(state => state.NotificationsReducer);
  const { status, message } = notifyState;
  const { user } = authState;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('user'));
    if (!authUser) {
      history.replace('/login');
    } else {
      recoverUserAuth(dispatch);
    }
  }, []);

  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () => {
    dispatch(
      setNotifyParams({
        status: false,
        message: '',
        type: notifyState.type
      })
    );
    return setNotifyPopUp({
      status: status,
      message: message,
      popupType: notifyState.type
    });
  };

  useEffect(() => {
    setNotifyPopUp({
      status: status,
      popupType: notifyState.type,
      message: message
    });
  }, [message, notifyState.type, status]);

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {})}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title="Ziri Studios">
              <Link to="/DashboardDefault" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  className="app-logo-btn">
                  <img
                    className="app-logo-img"
                    alt="Ziri Studios"
                    src={projectLogo}
                  />
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">Ziri Studios</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center"></Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
      <PopupNotif2
        activate={notifyPopUp.status}
        messageContent={notifyPopUp.message}
        popupType={notifyPopUp.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
