import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchServicePageContentApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/pages/service-content/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const updateServicePageContentApi = async data => {
  const formData = new FormData();
  formData.append('image', data.image);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages/service-content/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createServicePageContentApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('nav_image', data.image);
  return await AxiosService.post(
    `${baseUrl}/adminportal/pages/service-content/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
