import { PAGE_IMAGES } from '../actionTypes';
import { fetchContactInfoApi } from '../../api/authServices/contactUs/contactInfo';
import {fetchPageImagesApi} from "../../api/authServices/pageImages/pageImages";

export const fetchPageImageInfo = () => ({
  type: PAGE_IMAGES.FETCH_PAGE_IMAGES_DATA
});

export const fetchPageImageSuccess = data => ({
  type: PAGE_IMAGES.FETCH_PAGE_IMAGES_SUCCESS,
  payload: {
    data
  }
});

export const fetchPageImageFail = err => ({
  type: PAGE_IMAGES.FETCH_PAGE_IMAGES_FAIL,
  payload: {
    err
  }
});

export const fetchPageImageRequest = data => {
  return dispatch => {
    dispatch(fetchPageImageInfo());
    fetchPageImagesApi(data)
      .then(res => {
        dispatch(fetchPageImageSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchPageImageFail(err.message));
      });
  };
};
