import {
  createAboutPageContentApi,
  updateAboutPageContentApi
} from '../../../api/authServices/aboutPage/aboutPage';
import { fetchAboutPageContentRequest } from '../../../redux/actions/aboutPageActions';
import {
  dispatchNotifyActions,
  handleNoResponseError, handleResponseError,
  handleResponseValidationErrors, resetForm
} from '../../../utils/helper';

export const createAboutPageContent = async (
  setProcessingRequest,
  values,
  user,
  dispatch,
  reset,
  resetData,
  handleModalClose
) => {
  setProcessingRequest(true);
  const {
    aboutInfoText1,
    aboutInfoText2,
    infoImage,
    pageImage,
    aboutHeaderText
  } = values;
  const data = {
    aboutInfoText1,
    aboutInfoText2,
    infoImage,
    pageImage,
    aboutHeaderText,
    authToken: user.authToken
  };

  await createAboutPageContentApi({
    data,
    onSuccess: res => {
      dispatchNotifyActions(
        dispatch,
        'success',
        'About page successfully created'
      );
      setProcessingRequest(false);
      dispatch(
        fetchAboutPageContentRequest({
          authToken: user.authToken
        })
      );
      resetForm(reset, resetData);
      handleModalClose();
    },
    onError: err => {
      setProcessingRequest(false);
      if (err.response) {
        handleResponseError(err.response, setProcessingRequest, dispatch, [
          'info_text1',
          'info_text2',
          'header_text',
          'info_image',
          'page_image'
        ]);
      } else {
        handleNoResponseError(
          'Page content update failed.',
          setProcessingRequest,
          dispatch
        );
      }
    }
  });
};

export const updateAboutPageContent = async (
  setProcessingRequest,
  values,
  user,
  dispatch,
  handleModalClose
) => {
  setProcessingRequest(true);
  const {
    aboutInfoText1,
    aboutInfoText2,
    infoImage,
    pageImage,
    aboutHeaderText
  } = values;
  const data = {
    aboutInfoText1,
    aboutInfoText2,
    infoImage,
    pageImage,
    aboutHeaderText,
    authToken: user.authToken,
    // eslint-disable-next-line no-use-before-define
    id: values.id
  };

  await updateAboutPageContentApi({
    data,
    onSuccess: res => {
      setProcessingRequest(false);
      dispatch(
        fetchAboutPageContentRequest({
          authToken: user.authToken
        })
      );
      dispatchNotifyActions(
        dispatch,
        'success',
        'About page content successfully updated'
      );
      handleModalClose();
    },
    onError: err => {
      if (err.response) {
        handleResponseError(
          err.response,
          setProcessingRequest,
          dispatch,
          ['info_text1', 'info_text2', 'header_text', 'info_mage', 'page_image']
        );
      } else {
        handleNoResponseError(
          'Page content update failed.',
          setProcessingRequest,
          dispatch
        );
      }
    }
  });
};

export const submitForm = async (
  data,
  setProcessingRequest,
  values,
  user,
  dispatch,
  reset,
  resetData,
  handleModalClose,
  loading
) => {
  if ((!loading && data === undefined) || (!loading && data.length === 0)) {
    await createAboutPageContent(
      setProcessingRequest,
      values,
      user,
      dispatch,
      reset,
      resetData,
      handleModalClose
    );
  } else {
    await updateAboutPageContent(
      setProcessingRequest,
      values,
      user,
      dispatch,
      handleModalClose
    );
  }
};
