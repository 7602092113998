import React, { Component } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Card, CardContent } from '@material-ui/core';

// see installation section above for versions of NPM older than 3.0.0

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center'
};

const src = 'https://picsum.photos/1200/900';

export default class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src,
      cropResult: null,
      files: []
    };
    this.cropImage = this.cropImage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.useDefaultImage = this.useDefaultImage.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
      this.setState({
        files: e.target.files[0]
      });
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.setState({ cropResult: this.cropper.getCroppedCanvas().toDataURL() });
    const canvas = this.cropper.getCroppedCanvas();

    this.getBlobFromCanvas(
      canvas,
      this.state.files.name,
      this.state.files.type
    );
  }

  getBlobFromCanvas = (canvas, name, type) => {
    new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (blob) {
          blob.name = name;
          const file = new File([blob], name);
          this.props.getCroppedImage(file);
          resolve(file);
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, type); //"image/jpeg");
      // dispatch(setUploadedImage(file));
      // console.log(file);
    });
  };

  useDefaultImage() {
    this.setState({ src });
  }

  render() {
    return (
      <div>
        <div style={{ width: '100%' }}>
          <input required type="file" onChange={this.onChange} />
          {/*<Button*/}
          {/*  variant={'text'}*/}
          {/*  color={'secondary'}*/}
          {/*  onClick={this.useDefaultImage}>*/}
          {/*  Use default img*/}
          {/*</Button>*/}
          <span style={{ marginLeft: 5 }}></span>
          <Button
            color={'secondary'}
            variant={'outlined'}
            style={{ float: 'right' }}
            onClick={this.cropImage}>
            Crop
          </Button>
          <br />
          <br />
          <Card variant={'outlined'}>
            <CardContent style={{ marginBottom: 10 }}>
              <Cropper
                style={{ height: 250, width: 450 }}
                aspectRatio={16 / 9}
                preview=".img-preview"
                guides={false}
                src={this.state.src}
                ref={cropper => {
                  this.cropper = cropper;
                }}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={false}
              />
              <br />
              <div
                className="box"
                style={{ width: '100%', float: 'left', marginBottom: 10 }}>
                <span>Cropped Image</span>
                <img
                  style={{ width: '100%' }}
                  src={this.state.cropResult}
                  alt=""
                />
              </div>
            </CardContent>
          </Card>
        </div>
        <div>
          {/*<div className="box" style={{ width: '50%', float: 'right' }}>*/}
          {/*  <h1>Preview</h1>*/}
          {/*  <div*/}
          {/*    className="img-preview"*/}
          {/*    style={{ width: '100%', float: 'left', height: 300 }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <br style={{ clear: 'both' }} />
      </div>
    );
  }
}
