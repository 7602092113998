import React, { Fragment } from 'react';
import { ExampleWrapperSeamless } from '../../layout-components';
import Cards5Examples29 from '../../example-components/Cards5/Cards5Examples29';

export default function CategoriesList() {
  return (
    <Fragment>
      <ExampleWrapperSeamless sectionHeading="">
        <Cards5Examples29 />
      </ExampleWrapperSeamless>
    </Fragment>
  );
}
