import React, { Fragment, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { handleResponseError } from '../../utils/helper';

import PopupNotif2 from '../../components/notifications/popupnotif2';

import AppButton from '../../components/modal/AppButton/AppButton';
import { PageTitle } from '../../layout-components';
import { updateAccountProfileApi } from '../../api/authServices/authUser/loginUser';
import { fetchUpdatedUser } from '../../redux/actions/authActions';

const UserProfileForm = () => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const { userDetail } = user;

  const [values, setStateValues] = useState({
    firstName: userDetail ? userDetail.first_name : '',
    lastName: userDetail ? userDetail.last_name : '',
    email: userDetail ? userDetail.email : '',
    phone: userDetail ? userDetail.phone : ''
  });
  const [processingRequest, setProcessingRequest] = useState(false);

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const resetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    });
  };

  const updateUser = async () => {
    setProcessingRequest(true);
    const data = {
      prevData: userDetail,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      id: userDetail.id,
      authToken: user.authToken
    };
    await updateAccountProfileApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(fetchUpdatedUser(res.data));
        const get_auth_user = JSON.parse(localStorage.getItem('user'));
        const updatedUser = {
          ...get_auth_user,
          userDetail: res.data.userDetails
        };
        localStorage.setItem('user', JSON.stringify(updatedUser));

        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Account profile successfully updated'
        });
      },
      onError: err => {
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            setNotifyPopUp,
            ['first_name', 'last_name', 'email', 'phone']
          );
        } else {
          setNotifyPopUp({
            status: true,
            popupType: 'success',
            message: 'Account profile update failed'
          });
        }
      }
    });
  };

  const handleOnSubmit = async () => {
    await updateUser();
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="My account"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={false}
      />
      <Grid container>
        <Grid item xs={12} sm={4} md={4}></Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PopupNotif2
            activate={notifyPopUp.status}
            messageContent={notifyPopUp.message}
            popupType={notifyPopUp.popupType}
            resetError={() => resetError()}
          />
          <Card className="mb-4">
            <div className={'text-center mt-5'}>
              <h4>Update account</h4>
            </div>
            <div className="divider border-2 border-light my-3 border-dark opacity-2 mx-auto rounded-circle w-50" />
            <CardContent className="p-3">
              {userDetail.email && (
                <form onSubmit={handleSubmit(handleOnSubmit)} id="create-user">
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          {...register('firstName', {
                            required: {
                              value: true,
                              message: 'First name is required'
                            }
                          })}
                          type="text"
                          name="firstName"
                          label="First name"
                          placeholder="Enter first name"
                          error={errors.firstName && true}
                          inputProps={{
                            defaultValue: userDetail.first_name
                          }}
                          onChange={e =>
                            handleChange(setStateValues, values, e)
                          }
                        />
                        <div style={{ color: 'red' }}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            align="center">
                            {errors.firstName && errors.firstName.message}
                          </Typography>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                      <FormControl fullWidth>
                        <TextField
                          {...register('lastName', {
                            required: {
                              value: true,
                              message: 'Last name is required'
                            }
                          })}
                          type="text"
                          name="lastName"
                          label="Last name"
                          placeholder="Enter last name"
                          error={errors.lastName && true}
                          inputProps={{
                            defaultValue: userDetail.last_name
                          }}
                          onChange={e =>
                            handleChange(setStateValues, values, e)
                          }
                        />
                        <div style={{ color: 'red' }}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            align="center">
                            {errors.lastName && errors.lastName.message}
                          </Typography>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                      <FormControl fullWidth>
                        <TextField
                          {...register('email', {
                            required: {
                              value: !userDetail.email,
                              message: 'Email is required'
                            }
                          })}
                          type="text"
                          name="email"
                          label="Email"
                          placeholder="Enter email address"
                          error={errors.email && true}
                          inputProps={{
                            defaultValue: userDetail.email && userDetail.email
                          }}
                          onChange={e =>
                            handleChange(setStateValues, values, e)
                          }
                        />
                        <div style={{ color: 'red' }}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            align="center">
                            {errors.email && errors.email.message}
                          </Typography>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                      <FormControl fullWidth>
                        <TextField
                          {...register('phone', {
                            required: {
                              value: !userDetail.phone,
                              message: 'Phone number is required'
                            }
                          })}
                          type="text"
                          name="phone"
                          label="Phone"
                          placeholder="Enter phone number"
                          error={errors.phone && true}
                          inputProps={{
                            defaultValue: userDetail.phone
                          }}
                          onChange={e =>
                            handleChange(setStateValues, values, e)
                          }
                        />
                        <div style={{ color: 'red' }}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            align="center">
                            {errors.phone && errors.phone.message}
                          </Typography>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <AppButton
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={processingRequest}
                      style={{ textTransform: 'capitalize', marginTop: '20px' }}
                      fullWidth
                      // startIcon={props.editExtension ? <Edit /> : <AddCircle style={{ paddingRight: 5 }} />}
                    >
                      Update profile
                      {processingRequest && (
                        <span style={{ marginLeft: 10 }}>
                          <CircularProgress size={20} />{' '}
                        </span>
                      )}
                    </AppButton>
                  </DialogActions>
                </form>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4}></Grid>
      </Grid>
    </Fragment>
  );
};

export default UserProfileForm;
