import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../modal/style';
import { AppModalFormActions } from '../modal';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import { dispatchNotifyActions, handleResponseError } from '../../utils/helper';
import {
  createCarouselApi,
  updateCarouselApi
} from '../../api/authServices/carousel/carousel';
import { fetchCarouselRequest } from '../../redux/actions/carouselActions';
import {createGalleryImageApi, updateGalleryImageApi} from "../../api/authServices/galleryImages/galleryImage";
import {fetchHomeImageGalleryRequest} from "../../redux/actions/homeGalleryActions";

const CreateGalleryImageForm = ({
  handleClose,
  editCarousel,
  setEditCarousel,
  carouselDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    image: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleModalClose = () => {
    if (editCarousel) {
      setEditCarousel(false);
    }
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      image: []
    });
  };

  const updateGalleryImage = async () => {
    setProcessingRequest(true);
    const data = {
      name: values.name,
      image: values.image,
      id: carouselDetails.id,
      authToken: user.authToken
    };

    await updateGalleryImageApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchHomeImageGalleryRequest({
            authToken: user.authToken
          })
        );
        dispatchNotifyActions(
          dispatch,
          'success',
          'Gallery image successfully updated'
        );
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'name',
            'image'
          ]);
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Gallery image update failed');
        }
      }
    });
  };

  const createGalleryImage = async () => {
    setProcessingRequest(true);
    const data = {
      name: values.name,
      image: values.image,
      authToken: user.authToken
    };

    await createGalleryImageApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Gallery image successfully uploaded'
        );
        setProcessingRequest(false);
        dispatch(
          fetchHomeImageGalleryRequest({
            authToken: user.authToken
          })
        );
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'name',
            'image'
          ]);
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Gallery image creation failed');
        }
      }
    });
  };

  const handleOnSubmit = async () => {
    if (!editCarousel) {
      await createGalleryImage();
    } else {
      await updateGalleryImage();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      image: image,
      name: image.name
    });
    // checkImageDetails(image, 1365, 2048, setInvalidImage, setNotifyPopUp);
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editCarousel ? 'Edit gallery image' : 'Add gallery image'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl style={{ marginTop: 20 }}>
                  <FormLabel component="legend">
                    Upload gallery image
                  </FormLabel>
                  <FileUploadInput
                    canPerformActions
                    setStateValues={setStateValues}
                    values={values}
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleFileUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    message={'Gallery image is required'}
                    errors={errors}
                    register={register}
                    validate={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/*<p id="fileInfo"></p>*/}
          </DialogContent>
          <DialogActions>
            {/*{!invalidImage && (*/}
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editCarousel ? 'Update image' : 'Upload image'}
            />
            {/*)}*/}
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateGalleryImageForm;
