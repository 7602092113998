import React, {Fragment, useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import PopupNotif2 from '../../notifications/popupnotif2';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import { submitServiceForm } from './helper';
import {initRequest} from "../../../utils/helper";
import {fetchServicePageContentRequest} from "../../../redux/actions/servicePageActions";

const CreateServicePageForm = ({ handleClose, open }) => {
  const [values, setStateValues] = useState({
    image: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const serviceState = useSelector(state => state.ServicePageReducer);
  const { loading, data } = serviceState;
  const { user } = authState;
  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  useEffect(() => {
    initRequest(dispatch, fetchServicePageContentRequest)
  }, []);

  const resetForm = () => {
    reset({
      image: null
    });
  };

  const handleOnSubmit = () => {
    submitServiceForm(
      setProcessingRequest,
      values,
      data,
      user,
      dispatch,
      setNotifyPopUp,
      handleModalClose
    );
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      image: image
    });
  };
  console.log(values);

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={
            data !== undefined && data.length > 0 && data[0].name
              ? 'Edit Service Page'
              : 'Create Service Page'
          }
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">Bulk upload</FormLabel>
                  <FileUploadInput
                    canPerformActions
                    setStateValues={setStateValues}
                    values={values}
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleFileUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    message={'Service page image is required'}
                    errors={errors}
                    register={register}
                    validate={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={
                data !== undefined && data.length > 0 && data[0].name
                  ? 'Edit Service page'
                  : 'Create service page'
              }
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateServicePageForm;
