import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar, PresentationLayout } from './layout-blueprints';

// Example Pages

import Buttons from './example-pages/Buttons';
import Dropdowns from './example-pages/Dropdowns';
import NavigationMenus from './example-pages/NavigationMenus';
import ProgressBars from './example-pages/ProgressBars';
import Pagination from './example-pages/Pagination';
import Scrollable from './example-pages/Scrollable';
import Badges from './example-pages/Badges';
import Icons from './example-pages/Icons';
import UtilitiesHelpers from './example-pages/UtilitiesHelpers';
import RegularTables1 from './example-pages/RegularTables1';
import RegularTables4 from './example-pages/RegularTables4';
import FormsControls from './example-pages/FormsControls';
import Home from './pages/home';
import About from './pages/about';
import ContactUs from './pages/Contact-us';
import LoveStories from './pages/love-stories';
import ClientGallery from './pages/client-gallery';
import Services from './pages/Services/categories/index';
import Blog from './pages/Blog';
import Categories from './pages/photo-gallery/categories';
import Users from './pages/users';
import GalleryLinks from './pages/gallery-links';
import ManageCategories from './pages/photo-gallery/manage';
import LoginPage from "./pages/auth/login";
import ManageLoveStory from "./pages/love-stories/manage";
import TheTeam from "./pages/theteam/theTeam";
import ServicePage from "./pages/Services";
import ContactUsInfo from "./pages/Contact-us/contactUs";
import UserEnqueries from "./pages/user-enquries/userEnqueries";
import Admin from "./pages/admin";
import UserProfileForm from "./pages/profile";
import ChangePasswordForm from "./pages/auth/changePassword";
import {CircularProgress, Dialog, DialogContent} from "@material-ui/core";
import ManageClientsGallery from "./pages/client-gallery/manage";
import ViewGalleries from "./pages/client-gallery/manage/viewGalleries";
import PageImageList from './pages/page-images/pageList';
import HomeCarouselImages from "./pages/home/homeCarouselImages";
import HomePageGalleryImageList from "./components/home/galleryPageList";
import HomeGalleryImages from "./pages/home/gallery";
import ManageEmails from './pages/messages/emails';
const DashboardDefault = lazy(() => import('./example-pages/DashboardDefault'));
const Cards3 = lazy(() => import('./example-pages/Cards3'));
const LandingPage = lazy(() => import('./example-pages/LandingPage'));
const Accordions = lazy(() => import('./example-pages/Accordions'));
const Modals = lazy(() => import('./example-pages/Modals'));
const Notifications = lazy(() => import('./example-pages/Notifications'));
const Popovers = lazy(() => import('./example-pages/Popovers'));
const Tabs = lazy(() => import('./example-pages/Tabs'));
const ApexCharts = lazy(() => import('./example-pages/ApexCharts'));
const Maps = lazy(() => import('./example-pages/Maps'));
const ListGroups = lazy(() => import('./example-pages/ListGroups'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Dialog open={true}>
                  <DialogContent>
                    <CircularProgress thickness={5} size={30} style={{ margin: 15 }} />
                    <p>Loading...</p>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          }>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path={['/login']}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={LoginPage} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>

            <Route
              path={[
                '/login',
                '/UserProfile',
                '/dashboard',
                '/Home',
                '/About',
                '/the-team',
                '/Contact-us-info',
                '/view-gallery',
                '/manage-page-images',
                // '/Contact-us',
                '/Love-stories',
                '/Client-gallery',
                '/Services',
                '/User-enquiries',
                '/Manage-admins',
                '/Manage-emails',
                '/Change-password',
                // '/Blog',
                '/portfolio',
                '/Manage-users',
                '/Gallery-links',
                '/ManageCategory',
                '/ManageLoveStory',
                '/ClientGalleries',
                '/manage-carousel-images',
                '/manage-home-gallery',
                '/Buttons',
                '/Dropdowns',
                '/NavigationMenus',
                '/ProgressBars',
                '/Pagination',
                '/Scrollable',
                '/Badges',
                '/Icons',
                '/UtilitiesHelpers',
                '/Cards3',
                '/Accordions',
                '/Modals',
                '/Notifications',
                '/Popovers',
                '/Tabs',
                '/RegularTables1',
                '/RegularTables4',
                '/FormsControls',
                '/ApexCharts',
                '/Maps',
                '/ListGroups'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path="/dashboard"
                      component={DashboardDefault}
                    />
                    <Route path="/Home" component={Home} />
                    <Route path="/About" component={About} />
                    <Route path="/Contact-us" component={ContactUs} />
                    <Route path="/Love-stories" component={LoveStories} />
                    <Route path="/Client-gallery" component={ClientGallery} />
                    <Route path="/Services" component={ServicePage} />
                    <Route path="/Blog" component={Blog} />
                    <Route path="/portfolio" component={Categories} />
                    <Route path="/the-team" component={TheTeam} />
                    <Route path="/Contact-us-info" component={ContactUsInfo} />
                    <Route path="/User-enquiries" component={UserEnqueries} />
                    <Route path="/Manage-admins" component={Admin} />
                    <Route path="/Manage-emails" component={ManageEmails} />
                    <Route path="/Manage-users" component={Users} />
                    <Route path="/Gallery-links" component={GalleryLinks} />
                    <Route path="/ManageCategory" component={ManageCategories} />
                    <Route path="/ManageLoveStory" component={ManageLoveStory} />
                    <Route path="/ClientGalleries" component={ManageClientsGallery} />
                    <Route path="/UserProfile" component={UserProfileForm} />
                    <Route path="/view-gallery" component={ViewGalleries} />
                    <Route path="/manage-page-images" component={PageImageList} />
                    <Route path="/manage-carousel-images" component={HomeCarouselImages} />
                    <Route path="/manage-home-gallery" component={HomeGalleryImages} />
                    <Route path="/Change-password" component={ChangePasswordForm} />
                    <Route path="/Buttons" component={Buttons} />
                    <Route path="/Dropdowns" component={Dropdowns} />
                    <Route
                      path="/NavigationMenus"
                      component={NavigationMenus}
                    />
                    <Route path="/ProgressBars" component={ProgressBars} />
                    <Route path="/Pagination" component={Pagination} />
                    <Route path="/Scrollable" component={Scrollable} />
                    <Route path="/Badges" component={Badges} />
                    <Route path="/Icons" component={Icons} />
                    <Route
                      path="/UtilitiesHelpers"
                      component={UtilitiesHelpers}
                    />
                    <Route path="/Cards3" component={Cards3} />
                    <Route path="/Accordions" component={Accordions} />
                    <Route path="/Modals" component={Modals} />
                    <Route path="/Notifications" component={Notifications} />
                    <Route path="/Popovers" component={Popovers} />
                    <Route path="/Tabs" component={Tabs} />
                    <Route path="/RegularTables1" component={RegularTables1} />
                    <Route path="/RegularTables4" component={RegularTables4} />
                    <Route path="/FormsControls" component={FormsControls} />
                    <Route path="/ApexCharts" component={ApexCharts} />
                    <Route path="/Maps" component={Maps} />
                    <Route path="/ListGroups" component={ListGroups} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
