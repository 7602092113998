import { ADMIN } from '../actionTypes';
import { fetchAllAdminsApi } from '../../api/admins/admins';

export const fetchAdmin = () => ({
  type: ADMIN.FETCH_ADMIN_DATA
});

export const fetchAdminSuccess = data => ({
  type: ADMIN.FETCH_ADMIN_DATA_SUCCESS,
  payload: {
    data
  }
});

export const fetchAdminFail = err => ({
  type: ADMIN.FETCH_ADMIN_DATA_FAIL,
  payload: {
    err
  }
});

export const fetchAllAdminRequest = data => {
  return dispatch => {
    dispatch(fetchAdmin());
    fetchAllAdminsApi(data)
      .then(res => {
        dispatch(fetchAdminSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAdminFail(err.message));
      });
  };
};
