import React from 'react';
import { Tooltip } from '@material-ui/core';

import DeleteForever from '@material-ui/icons/Delete';
import DataTableLoader from '../../DataTableLoader';
import { deleteModal, editUser } from './helper';
import { VisibilitySharp } from '@material-ui/icons';

export const setTableColumn = (
  setColumns,
  userDetails,
  setEditUserModal,
  editUserModal,
  setDeleteCodeModal,
  deleteCodeModal,
  setUserDetails,
  setEditUser
) =>
  setColumns([
    {
      name: 'counter',
      label: '#',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'subject',
      label: 'Email Subject',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email_content',
      label: 'Email Content',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'date_created',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Action',
      label: <span style={{ marginLeft: 20 }}>Action</span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (e, d) => {
          const rData = {
            first_name: d.rowData[1],
            last_name: d.rowData[2],
            email: d.rowData[3],
            username: d.rowData[4],
            phone: d.rowData[5],
            id: d.rowData[10]
          };
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip
                title="View"
                onClick={() => {
                  editUser(
                    setUserDetails,
                    setEditUserModal,
                    editUserModal,
                    setEditUser,
                    rData
                  );
                  console.log(rData);
                }}
                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10 }}>
                <VisibilitySharp color="primary" />
              </Tooltip>
              <Tooltip
                title="Delete"
                style={{ cursor: 'pointer', marginLeft: 20 }}
                onClick={() => {
                  deleteModal(
                    setUserDetails,
                    setDeleteCodeModal,
                    deleteCodeModal,
                    rData
                  );
                }}>
                <DeleteForever style={{ color: 'red', marginLeft: 10 }} />
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    }
  ]);

export const setTableOptions = setOptions =>
  setOptions({
    filterType: 'textField',
    // selectableRowsHideCheckboxes: 'hideCheckBox',
    // selectableRowsOnClick: true,
    // selectableRows: 'multi',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'No data found'
      }
    }
  });

export const setTableOptions2 = setOptions2 =>
  setOptions2({
    filterType: 'textField',
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: <DataTableLoader message="Fetching data..." />
      }
    }
  });
