import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import PopupNotif2 from '../../notifications/popupnotif2';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import { fetchAboutPageContentRequest } from '../../../redux/actions/aboutPageActions';
import { initRequest } from '../../../utils/helper';
import {
  createAboutPageContentApi,
  updateAboutPageContentApi
} from '../../../api/authServices/aboutPage/aboutPage';
import {fetchContactPageContentRequest} from "../../../redux/actions/contactUsActions";
import {
  createContactPageContentApi,
  updateContactPageContentApi
} from "../../../api/authServices/contactUs/contactPage";

const ManageContactUsPageForm = ({
  handleClose,
  editStory,
  setEditStory,
  storyDetails,
  open
}) => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const contactPageState = useSelector(state => state.ContactPageReducer);
  const { loading, data } = contactPageState;
  const [values, setStateValues] = useState({
    email: data.length > 0 ? data[0].name : '',
    phone: data.length > 0 ? data[0].phone : '',
    address: data.length > 0 ? data[0].address : '',
    description: data.length > 0 ? data[0].description : '',
    navImage: [],
    id: data.length > 0 ? data[0].id : null
  });

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const handleModalClose = () => {
    if (editStory) {
      setEditStory(false);
    }
    resetForm();
    handleClose();
  };
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const resetForm = () => {
    reset({
      email: '',
      phone: '',
      address: '',
      description: ''
    });
  };

  const updateContactPageContent = async () => {
    setProcessingRequest(true);
    const { email, address, phone, description, naveImage } = values;
    const data = {
      email,
      phone,
      address,
      description,
      authToken: user.authToken
    };
    await updateContactPageContentApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchAboutPageContentRequest({
            authToken: user.authToken
          })
        );
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Contact information successfully updated'
        });
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            handleResponseValidationErrors(data);
            break;
          case 500:
            setProcessingRequest(false);
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: data.message
            });
            break;
          default:
            setProcessingRequest(false);
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: 'Contact information update failed.'
            });
        }
      }
    });
  };

  const createContactPageContent = async () => {
    setProcessingRequest(true);
    const { email, address, phone, description } = values;
    const data = {
      email,
      phone,
      address,
      description,
      authToken: user.authToken
    };

    await createContactPageContentApi({
      data,
      onSuccess: res => {
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Contact information successfully created'
        });
        setProcessingRequest(false);
        dispatch(
          fetchContactPageContentRequest({
            authToken: user.authToken
          })
        );
        resetForm();
      },
      onError: err => {
        setProcessingRequest(false);
        console.log(err);
        // const { data, status } = err.response;
        // switch (status) {
        //   case 400:
        //     handleResponseValidationErrors(data);
        //     break;
        //   case 500:
        //     setNotifyPopUp({
        //       status: true,
        //       popupType: 'error',
        //       message: data.message
        //     });
        //     break;
        //   default:
        //     console.log(err.message);
        //     setNotifyPopUp({
        //       status: true,
        //       popupType: 'error',
        //       message: 'Love story creation failed.'
        //     });
        // }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('email')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.name[0]
      });
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('name')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.image[0]
      });
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('description')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.description[0]
      });
    } else {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: 'Failed to process request. Try again'
      });
    }
  };

  const handleOnSubmit = async () => {
    if (!data.length > 0) {
      await createContactPageContent();
    } else {
      await updateContactPageContent();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      navImage: image
    });
  };

  const handleInfoImagesUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      infoImage: image
    });
  };
  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={'Manage Contact-us Page'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register(`email`, {
                      required: {
                        value: true,
                        message: `Email is required`
                      }
                    })}
                    type="email"
                    name="email"
                    label={'Contact email'}
                    placeholder="Enter contact email"
                    error={errors.name && true}
                    inputProps={{
                      defaultValue: values.name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.email && errors.email.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register(`phone`, {
                      required: {
                        value: true,
                        message: `Phone number is required`
                      }
                    })}
                    type="text"
                    name="phone"
                    label={'Contact phone'}
                    placeholder="Enter contact phone"
                    error={errors.phone && true}
                    inputProps={{
                      defaultValue: values.phone
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.phone && errors.phone.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`address`, {
                      required: {
                        value: false,
                        message: `Address is required`
                      }
                    })}
                    type="text"
                    name="address"
                    label={'Contact address (Optional)'}
                    placeholder="Enter contact address"
                    error={errors.address && true}
                    inputProps={{
                      defaultValue: values.address
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.address && errors.address.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`description`, {
                      required: {
                        value: false,
                        message: `Description is required`
                      }
                    })}
                    type="text"
                    name="description"
                    label={'Contact description (Optional)'}
                    placeholder="Enter contact description"
                    error={errors.description && true}
                    inputProps={{
                      defaultValue: values.aboutInfoText2
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.description && errors.description.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            {/*<Grid container>*/}
            {/*  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>*/}
            {/*    <FormControl>*/}
            {/*      <FormLabel component="legend">Upload page image</FormLabel>*/}
            {/*      <FileUploadInput*/}
            {/*        register={register}*/}
            {/*        errors={errors}*/}
            {/*        message={'Contact us Page image is required'}*/}
            {/*        canPerformActions*/}
            {/*        resetFileUpload={resetForm}*/}
            {/*        handleFileChange={e => {*/}
            {/*          handleFileUpload(e);*/}
            {/*        }}*/}
            {/*        accept=".jpg, .png, .jpeg"*/}
            {/*        validate={!values.aboutHeaderText}*/}
            {/*      />*/}
            {/*    </FormControl>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={data.length > 0 ? 'Update' : 'Submit'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default ManageContactUsPageForm;
