import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchCategoryGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/service-galleries/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const fetchClientGalleryGalleriesApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/client-gallery-galleries/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const fetchClientPhotosApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/client-photos/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createGalleryApi = async (
  setProgress,
  { data, onSuccess, onError }
) => {
  const formData = new FormData();
  for (let i = 0; i < data.image.length; i++) {
    // formData.append('square_image', data.image[i]);
    if (data.type === 'clientgallery') {
      formData.append('orig_image', data.image[i]);
    }

    formData.append('land_scape_image', data.image[i]);
    // formData.append('orig_image', data.image[i]);
    formData.append(data.type, data.id);
  }
  // formData.append('gallery', data.image);
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-gallery/`,
    formData,
    {
      headers: {
        Authorization: `${data.authToken}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: data => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
      }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteCategoryGalleryApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-gallery/${[data.id]}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteMultipleCategoryGalleryApi = async ({
  data,
  onSuccess,
  onError
}) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/galleries/delete/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const fetchClientGalleryImageNamesApi = ({ data, onSuccess, onError }) => {
  return AxiosService.get(
    `${baseUrl}/adminportal/client-galleries-image-names/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(onSuccess)
    .catch(onError);
};

export const fetchServiceGalleryImageNamesApi = ({ data, onSuccess, onError }) => {
  return AxiosService.get(
    `${baseUrl}/adminportal/service-galleries-image-names/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(onSuccess)
    .catch(onError);
};

export const fetchLoveStoryGalleryImageNamesApi = ({ data, onSuccess, onError }) => {
  return AxiosService.get(
    `${baseUrl}/adminportal/lovestory-galleries-image-names/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(onSuccess)
    .catch(onError);
};
