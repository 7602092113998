import React, { Fragment} from 'react';
import { PageTitle } from '../../../layout-components';
import ManageCategory from '../../../components/photo-gallery/manage';
import UploadCategoryGalleryForm from '../../../components/photo-gallery/forms/uploadGalleryForm';
import { fetchCategoryGalleryRequest } from '../../../redux/actions/galleryActions';

export default function ManageCategories(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading={`Manage ${props.history.location.state.data.name} Gallery`}
        titleDescription={`${props.history.location.state.data.description}`}
        createObj={true}
        handleOpen={handleOpen}
      />
      <ManageCategory categoryId={props.history.location.state.data.id} />
      {open && (
        <UploadCategoryGalleryForm
          categoryId={props.history.location.state.data.id}
          open={open}
          handleClose={handleClose}
          type={'service'}
          fetchGallery={fetchCategoryGalleryRequest}
        />
      )}
    </Fragment>
  );
}
