import React from 'react';

const NoDataFound = ({ data }) => {
  return (
    <>
      {data === undefined || data.length === 0 ? (
        <div className={'text-center mt-lg-5'}>
          <h4 className={'text-muted'}>No data found</h4>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default NoDataFound;
