import React, { Fragment, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { fetchCategoryGalleryRequest } from '../../../redux/actions/galleryActions';
import { useDispatch, useSelector } from 'react-redux';
import AppViewModal from '../../modal/appViewModal';

import Slideshow from '../../../assets/components/slideShow/slideImage';
import AppLoader from '../../loader/loader';
import {fetchClientPhotosRequest} from "../../../redux/actions/clientPhotosActions";

export default function ClientPhotoDetails({
  categoryDetails,
  handleClose,
  open
}) {
  const galleryState = useSelector(state => state.ClientPhotosReducer);
  const authState = useSelector(state => state.AuthReducer);

  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = type =>
    setErrorPopUp({ status: false, message: '', popupType: type });
  const { loading, data } = galleryState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchClientPhotosRequest({
        id: categoryDetails.id,
        authToken: user.authToken
      })
    );
  }, [categoryDetails.id, dispatch, user.authToken]);

  return (
    <Fragment>
      {!loading ? (
        <AppViewModal
          style={{ height: !loading && data.length === 0 ? 500 : 0 }}
          modalSize={'lg'}
          title={`${categoryDetails.title} gallery`}
          children={
            data && data.length > 0 ? (
              <Slideshow images={data} />
            ) : (
              <Grid container spacing={4}>
                {/*{data && data.length > 0 ? (*/}
                {/*  data.map(gallery => (*/}
                {/*    <Grid item xs={12} sm={6} md={3}>*/}
                {/*      <Card className="card-transparent mb-4">*/}
                {/*        <div className="card-img-wrapper">*/}
                {/*          <img*/}
                {/*            src={`${mediaBaseUrl}${gallery.square_img}`}*/}
                {/*            className="card-img-top rounded"*/}
                {/*            alt="..."*/}
                {/*          />*/}
                {/*        </div>*/}
                {/*      </Card>*/}
                {/*    </Grid>*/}
                {/*  ))*/}
                {/*) : (*/}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ height: 400, marginTop: 90 }}>
                  <h4 className={'align-middle text-center text-black-50'}>
                    No gallery found
                  </h4>
                </Grid>
              </Grid>
            )
          }
          open={open}
          handleClose={handleClose}
        />
      ) : (
        ''
      )}
      <AppLoader loading={loading} />
    </Fragment>
  );
}
