import React, { Fragment, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import CustomDialogTitle from '../../../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../../../modal/style';
import { AppModalFormActions } from '../../../../modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  dispatchNotifyActions,
  handleResponseError
} from '../../../../../utils/helper';
import { createAdminApi } from '../../../../../api/admins/admins';
import { fetchAllAdminRequest } from '../../../../../redux/actions/adminActions';
import { fetchAllUsersApi } from '../../../../../api/authServices/users/users';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const SendEmailForm = ({ handleClose, editUser, open, userDetails }) => {
  const [values, setStateValues] = useState({
    subject: '',
    recipients: [],
    message: ''
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const userState = useSelector(state => state.UserReducer);
  const { loading, data } = userState;
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [emailRecipients, setEmailRecipients] = React.useState([]);

  const handleRecipientChange = e => {
    setEmailRecipients(e.target.value);
  };

  const handleChangeMultiple = e => {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setEmailRecipients(value);
  };
  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    reset({
      recipeints: [],
      subject: '',
      message: ''
    });
  };

  const createUser = async () => {
    setProcessingRequest(true);
    const data = {
      message: values.firstName,
      recipients: values.lastName,
      subject: values.email,
      authToken: user.authToken
    };

    await createAdminApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Admin successfully created'
        );
        setProcessingRequest(false);
        dispatch(fetchAllAdminRequest({ authToken: user.authToken }));
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'first_name',
            'last_name',
            'username',
            'email',
            'phone'
          ]);
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Admin creation failed');
        }
      }
    });
  };
  const handleOnSubmit = async () => {
    await createUser();
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  useEffect(() => {
    if (open) {
      fetchAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchAllUsers = async () => {
    const data = {
      authToken: user.authToken
    };
    const res = await fetchAllUsersApi(data);
    setUsers(res.data);
    setLoadingUsers(false);
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editUser ? 'Edit Admin' : 'Send Email'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="create-user">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('subject', {
                      required: {
                        value: true,
                        message: 'Email subject is required'
                      }
                    })}
                    type="text"
                    name="subject"
                    label="Email Subject"
                    placeholder="Enter Email subject"
                    error={errors.galleryLink && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.first_name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.subject && errors.subject.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    {...register('message', {
                      required: {
                        value: true,
                        message: 'Email body is required'
                      }
                    })}
                    type="text"
                    name="message"
                    label="Message body"
                    placeholder="Enter email body"
                    error={errors.lastName && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.last_name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                    rows={3}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.message && errors.message.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth error={Boolean(errors.user)}>
                  <InputLabel id="demo-simple-select-label">
                    Select reciepient
                  </InputLabel>

                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: 'Recipient is required'
                      }
                    }}
                    control={control}
                    name="user"
                    render={({ field: { name, onChange } }) => (
                      <Select
                        MenuProps={MenuProps}
                        // disabled={loadingUsers}
                        multiple
                        name={name}
                        value={emailRecipients}
                        displayEmpty
                        onChange={e => {
                          handleRecipientChange(e);
                            // onChange(e.target.value);
                        }}>
                        {users.map((user, index) => (
                          <MenuItem value={user.id} key={index}>
                            <Checkbox
                            //   checked={emailRecipients.indexOf(user.id) > -1}
                            />
                            {user.username}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <div style={{ color: 'red' }}>
                    <FormHelperText>
                      {errors.user && errors.user.message}
                    </FormHelperText>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle="Send Email"
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default SendEmailForm;
