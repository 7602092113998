import React, {Fragment, useEffect, useState} from 'react';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core';
import CustomDialogTitle from '../modal/CustomDialogTitle/CustomDialogTitle';
import AppButton from '../modal/AppButton/AppButton';

export default function SingleImageView({ image, open, handleClose }) {
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        scroll="body">
        <CustomDialogTitle toggleModal={handleClose}></CustomDialogTitle>

        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={12}>
              <Card className="card-transparent mb-4">
                <div className="card-img-wrapper">
                  <img
                    src={image}
                    className="card-img-top rounded"
                    alt="..."
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton onClick={handleClose}>Close</AppButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
