import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSeamless } from '../../layout-components';
import CreateAdminForm from '../../components/admin/forms';
import ManageAdminTable from '../../components/Tables/admin/manageAdminTable';
export default function Admin() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage Admin"
        // titleDescription="Tables are the backbone of almost all web applications."
        createObj={true}
        handleOpen={handleOpen}
        toolTipTitle={'Create admin'}
      />

      <ExampleWrapperSeamless sectionHeading="">
        <ManageAdminTable />
      </ExampleWrapperSeamless>
      {open && (<CreateAdminForm open={open} handleClose={handleClose} />) }
    </Fragment>
  );
}
