import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AppDeleteModal from '../../modal/AppDeleteModal/AppDeleteModal';
import PopupNotif2 from '../../notifications/popupnotif2';
import { dataFormatter, editModal, toggleDeleteCodeModal } from './helper';
import {
  setTableColumn,
  setTableOptions,
  setTableOptions2
} from './tableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsersRequest } from '../../../redux/actions/usersActions';
import CreateUserForm from '../../users/forms';
import { deleteUserApi } from '../../../api/authServices/users/users';
import {recoverAuth} from "../../../redux/actions/authActions";
import {initRequest} from "../../../utils/helper";

const ManageUsersTable = () => {
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [tableData, setTableData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [options, setOptions] = useState({});
  const [options2, setOptions2] = useState({});
  const [editUser, setEditUser] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const userState = useSelector(state => state.UserReducer);
  const { loading, data } = userState;
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  useEffect(() => {
    initRequest(dispatch, fetchAllUsersRequest);
  }, []);

  useEffect(() => {
    dataFormatter(data, setTableData);
  }, [data]);

  useEffect(() => {
    setTableColumn(
      setColumns,
      userDetails,
      setEditUserModal,
      editUserModal,
      setDeleteCodeModal,
      deleteCodeModal,
      setUserDetails,
      setEditUser
    );
  }, [deleteCodeModal, editUserModal, userDetails]);

  useEffect(() => {
    setTableOptions(setOptions);

    setTableOptions2(setOptions2);
  }, []);

  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    editModal(setEditUserModal, editUserModal);
  };

  // Delete gallery link
  const deleteUser = async () => {
    setProcessingRequest(true);
    const data = {
      id: userDetails.id,
      authToken: user.authToken
    };
    const res = await deleteUserApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        dispatch(
          fetchAllUsersRequest({
            authToken: user.authToken
          })
        );
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'User successfully deleted'
        });

        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(false);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'User deletion failed'
        });
      }
    });
  };

  return (
    <div>
      {loading ? (
        <MUIDataTable data={[]} columns={columns} options={options2} />
      ) : (
        <MUIDataTable data={tableData} columns={columns} options={options} />
      )}

      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete User"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete user ${userDetails.username}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteUser();
        }} // action to delete item
      />
      {editUser && (
        <CreateUserForm
          handleClose={() => toggleEditModal()}
          editUser={editUser}
          open={editUserModal}
          userDetails={userDetails}
          setEditUser={setEditUser}
        />
      )}

      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </div>
  );
};

export default ManageUsersTable;
