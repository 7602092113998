import { CATEGORY } from '../actionTypes';
import { fetchCategoryApi } from '../../api/authServices/galleryCategory/category';

export const fetchCategories = () => ({
  type: CATEGORY.FETCH_CATEGORY_DATA
});

export const fetchCategoriesSuccess = data => ({
  type: CATEGORY.FETCH_CATEGORY_SUCCESS,
  payload: {
    data
  }
});

export const fetchCategoriesFail = err => ({
  type: CATEGORY.FETCH_CATEGORY_FAIL,
  payload: {
    err
  }
});

export const fetchCategoriesRequest = data => {
  return dispatch => {
    dispatch(fetchCategories());
    fetchCategoryApi(data)
      .then(res => {
        dispatch(fetchCategoriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchCategoriesFail(err));
      });
  };
};
