import React, { Fragment } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AboutPageTexts = ({
  headerText,
  infoText1,
  infoText2,
  heading1,
  heading2
}) => {
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Card className="card-box mb-4">
            <div className="card-header">
              <div className="card-header--title">
                {/*<small>Scrollable</small>*/}
                <b>{heading1}</b>
              </div>
            </div>
            <CardContent className="p-3">
              <div className="scroll-area-sm">
                <PerfectScrollbar>
                  <p style={{ textAlign: 'left' }}>{infoText1}</p>
                </PerfectScrollbar>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card className="card-box mb-4">
            <div className="card-header">
              <div className="card-header--title">
                {/*<small>Scrollable</small>*/}
                <b>{heading2}</b>
              </div>
            </div>
            <CardContent className="p-3">
              <div className="scroll-area-sm">
                <PerfectScrollbar>
                  <p style={{ textAlign: 'left' }}>{infoText2 ? infoText2 : 'No information text available'}</p>
                </PerfectScrollbar>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default AboutPageTexts;
