import { USERS } from '../actionTypes';
import { fetchAllUsersApi } from '../../api/authServices/users/users';

export const fetchUsers = () => ({
  type: USERS.FETCH_DATA
});

export const fetchUserSuccess = data => ({
  type: USERS.FETCH_DATA_SUCCESS,
  payload: {
    data
  }
});

export const fetchUserFail = err => ({
  type: USERS.FETCH_DATA_FAIL,
  payload: {
    err
  }
});

export const fetchAllUsersRequest = data => {
  return dispatch => {
    dispatch(fetchUsers());
    fetchAllUsersApi(data)
      .then(res => {
        console.log(res.data);
        dispatch(fetchUserSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(fetchUserFail(err.message));
      });
  };
};
