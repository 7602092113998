import React from 'react';
import { Avatar } from '@material-ui/core';
import {mediaBaseUrl} from "../../../api/media_baseurl";

export const dataFormatter = (data, setTableData) => {
  let counter = 1;
  const gallery =
    data &&
    data.map(row => ({
      id: row.id,
      image: (
        <div className="d-flex align-items-center">
          <Avatar
            alt="..."
            src={`${mediaBaseUrl}` + row.land_scape_img}
            className="mr-2"
          />
          <div>
            <a
              href="#/"
              onClick={e => e.preventDefault()}
              className="font-weight-bold text-black"
              title="...">
              Photo Gallery
            </a>
            <span className="text-black-50 d-block">{row.title}</span>
          </div>
        </div>
      ),
      name: row.name,
      time_created: row.time_created,
      counter: counter++,
      gal: row.land_scape_img
    }));
  setTableData(gallery);
};
// toggles delete modal
export const toggleDeleteCodeModal = (setDeleteCodeModal, deleteCodeModal) => {
  setDeleteCodeModal(!deleteCodeModal);
};

// toggles edit code modal
export const toggleViewModal = (setViewGalleryModal, viewGalleryModal) => {
  setViewGalleryModal(!viewGalleryModal);
};

// executes  delete modal
export const deleteModal = (
  setGalleryDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

export const deleteModalFromTable = (
  setGalleryDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

// executes edit modal and set code data for editing
export const viewGallery = (
  setGalleryDetails,
  setViewGalleryModal,
  viewGalleryModal,
  data
) => {
  setGalleryDetails(data);
  toggleViewModal(setViewGalleryModal, viewGalleryModal);
};
