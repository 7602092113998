import { FILE_UPLOAD } from '../actionTypes';

export const setUploadedImage = data => ({
  type: FILE_UPLOAD.SET_UPLOADED_IMAGE,
  payload: {
    data
  }
});

export const setCropImageData = data => ({
  type: FILE_UPLOAD.SET_CROP_IMAGE_DATA,
  payload: {
    data
  }
});

export const reSetUploadedImage = data => ({
  type: FILE_UPLOAD.RESET_UPLOADED_IMAGE,
  payload: {
    data
  }
});

