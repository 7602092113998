import React, { Fragment } from 'react';

import { ExampleWrapperSeamless } from '../../../layout-components';
import ManageLoveStoryTable from '../../Tables/love-story/manageLoveStoryTable';

export default function ManageStory({ storyId, fetchGallery }) {
  return (
    <Fragment>
      <ExampleWrapperSeamless sectionHeading="">
        <ManageLoveStoryTable storyId={storyId} fetchGallery={fetchGallery} />
      </ExampleWrapperSeamless>
    </Fragment>
  );
}
