import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
import PopupNotif2 from '../../notifications/popupnotif2';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  createGalleryLink,
  updateGalleryLinks
} from '../../../api/authServices/galleryLinks/galleryLinks';
import { fetchAllUsersApi } from '../../../api/authServices/users/users';
import { fetchGalleryLinksRequest } from '../../../redux/actions/galleryLinks';

const AssignGalleryLinkForm = ({
  handleClose,
  editLink,
  setEditLink,
  galleryLinkDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    galleryLink: editLink ? galleryLinkDetails.link : '',
    description: editLink ? galleryLinkDetails.description : '',
    user: editLink ? galleryLinkDetails.user : null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const userState = useSelector(state => state.UserReducer);
  const { loading, data } = userState;
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    if (open) {
      fetchAllUsers();
    }
  }, []);

  const handleModalClose = () => {
    if (editLink) {
      setEditLink(false);
    }
    resetForm();
    handleClose();
  };

  const fetchAllUsers = async () => {
    const data = {
      authToken: user.authToken
    };
    const res = await fetchAllUsersApi(data);
    setUsers(res.data);
    setLoadingUsers(false);
  };
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const resetForm = () => {
    reset({
      galleryLink: '',
      user: null,
      description: ''
    });
  };

  const updateGalleryLink = async () => {
    setProcessingRequest(true);
    const data = {
      link: values.galleryLink,
      description: values.description,
      user: values.user,
      id: galleryLinkDetails.id,
      authToken: user.authToken
    };
    await updateGalleryLinks({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchGalleryLinksRequest({
          authToken: user.authToken
        }));
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Gallery Link successfully updated'
        });
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            handleResponseValidationErrors(data);
            break;
          case 500:
            setProcessingRequest(false);
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: data.message
            });
            break;
          default:
            setProcessingRequest(false);
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: 'Gallery link update failed.'
            });
        }
      }
    });
  };

  const createLink = async () => {
    setProcessingRequest(true);
    const data = {
      link: values.galleryLink,
      description: values.description,
      user: values.user,
      authToken: user.authToken
    };

    await createGalleryLink({
      data,
      onSuccess: res => {
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Link successfully assigned to user'
        });
        setProcessingRequest(false);
        dispatch(
          fetchGalleryLinksRequest({
            authToken: user.authToken
          })
        );
        resetForm();
      },
      onError: err => {
        setProcessingRequest(false);
        const { data, status } = err.response;
        switch (status) {
          case 400:
            handleResponseValidationErrors(data);
            break;
          case 500:
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: data.message
            });
            break;
          default:
            console.log(err.message);
            setNotifyPopUp({
              status: true,
              popupType: 'error',
              message: 'Gallery link creation failed.'
            });
        }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('link')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.link[0]
      });
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('user')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.user[0]
      });
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('description')) {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: data.description[0]
      });
    } else {
      setNotifyPopUp({
        status: true,
        popupType: 'error',
        message: 'Failed to process request. Try again'
      });
    }
  };

  const handleOnSubmit = async () => {
    if (!editLink) {
      await createLink();
    } else {
      await updateGalleryLink();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={editLink ? 'Edit Gallery Link' : 'Add new Link'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('galleryLink', {
                      required: {
                        value: true,
                        message: 'Gallery link is required'
                      }
                    })}
                    type="text"
                    name="galleryLink"
                    label="Gallery Link"
                    placeholder="Enter gallery link"
                    error={errors.galleryLink && true}
                    inputProps={{
                      defaultValue: editLink && galleryLinkDetails.link
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.galleryLink && errors.galleryLink.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('description', {
                      required: {
                        value: true,
                        message: 'Gallery description is required'
                      }
                    })}
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                    error={errors.description && true}
                    inputProps={{
                      defaultValue: editLink && galleryLinkDetails.description
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.description && errors.description.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth error={Boolean(errors.user)}>
                  <InputLabel id="demo-simple-select-label">
                    Select a user
                  </InputLabel>

                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: 'User is required'
                      }
                    }}
                    control={control}
                    defaultValue={editLink ? 'value' : ''}
                    name="user"
                    render={({ field: { value, name, onChange } }) => (
                      <Select
                        // disabled={loadingUsers}
                        name={name}
                        value={value}
                        displayEmpty
                        renderValue={
                          editLink ? () => galleryLinkDetails.user_text : ''
                        }
                        onChange={e => {
                          handleChange(setStateValues, values, e);
                          onChange(e.target.value);
                        }}>
                        {users.map((user, index) => (
                          <MenuItem value={user.id} key={index}>
                            {user.username}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <div style={{ color: 'red' }}>
                    <FormHelperText>
                      {errors.user && errors.user.message}
                    </FormHelperText>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={'Assign Link'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default AssignGalleryLinkForm;
