import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import CreateClientGalleryForm from '../../components/client-gallery/forms';
import ClientGalleryList from '../../components/client-gallery/client-gallery-list/ClientGalleryList';
export default function ClientGallery() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Client Gallery"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      <ClientGalleryList />

      {open && (
        <CreateClientGalleryForm open={open} handleClose={handleClose} />
      )}
    </Fragment>
  );
}
