import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchAllClientGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/manage-client-galleries/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const fetchClientGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/manage-client-galleries/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createClientGalleryApi = async ({ data, onSuccess, onError }) => {
  console.log(data);
  const formData = new FormData();
  formData.append('cover_image', data.coverImage);
  formData.append('gallery_link', data.link);
  formData.append('title', data.title);
  formData.append('user', data.user);
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-client-galleries/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateClientGalleryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.coverImage);
  formData.append('gallery_link', data.link);
  formData.append('title', data.title);
  formData.append('user', data.user);
  if (data.coverImage.length === 0) {
    formData.delete('cover_image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-client-galleries/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteClientGalleryApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-client-galleries/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
