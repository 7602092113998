import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText, FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';

import { useDispatch, useSelector } from 'react-redux';

import { fetchAllUsersApi } from '../../../api/authServices/users/users';
import {
  createLoveStoryApi,
  updateLoveStoryApi
} from '../../../api/authServices/loveStories/loveStories';
import { fetchLoveStoryRequest } from '../../../redux/actions/loveStoryActions';
import DescriptionList from './descriptionLists';
import {checkImageDetails, dispatchNotifyActions, handleResponseError} from '../../../utils/helper';
import { AppModalFormActions } from '../../modal';
import {reSetUploadedImage} from "../../../redux/actions/fileUpload";
import FileUploadInput from '../../FileUploadInput/FileUploadInput';

const CreateLoveStoriesForm = ({
  handleClose,
  editStory,
  setEditStory,
  storyDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    title: editStory ? storyDetails.title : '',
    description1: editStory ? storyDetails.description1 : '',
    description2: editStory ? storyDetails.description2 : '',
    description3: editStory ? storyDetails.description3 : '',
    description4: editStory ? storyDetails.description4 : '',
    description5: editStory ? storyDetails.description5 : '',
    user: editStory ? storyDetails.user : null,
    coverImage: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [invalidImage, setInvalidImage] = useState(true);
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [createCoverImage, setCreateCoverImage] = useState(false);
  const uploadImageState = useSelector(state => state.FileUploadReducer);
  const { image, imageUploaded } = uploadImageState;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);

  const { user } = authState;
  const handleModalClose = () => {
    if (editStory) {
      setEditStory(false);
    }
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    reset({
      title: '',
      file: [],
      description: '',
      user: null
    });
  };

  useEffect(() => {
    if (open) {
      fetchAllUsers();
    }
  }, [open]);

  const fetchAllUsers = async () => {
    const data = {
      authToken: user.authToken
    };
    const res = await fetchAllUsersApi(data);
    setUsers(res.data);
    setLoadingUsers(false);
  };
  const updateLoveStory = async () => {
    setProcessingRequest(true);
    const {
      description1,
      description2,
      description3,
      description4,
      description5
    } = values;
    const data = {
      title: values.title,
      description: {
        description1,
        description2,
        description3,
        description4,
        description5
      },
      id: storyDetails.id,
      user: storyDetails.user,
      authToken: user.authToken,
      coverImage: values.coverImage
    };
    await updateLoveStoryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchLoveStoryRequest({
            authToken: user.authToken
          })
        );
        setCreateCoverImage(false);
        setCreateCoverImage(false);
        dispatch(reSetUploadedImage({}));
        dispatchNotifyActions(
          dispatch,
          'success',
          'Love story successfully updated'
        );
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        setCreateCoverImage(false);
        dispatch(reSetUploadedImage({}));
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            dispatch,
            ['title', 'description1', 'user', 'cover_image']
          );
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Love story update failed');
        }
      }
    });
  };

  const createLoveStory = async () => {
    setProcessingRequest(true);
    const {
      description1,
      description2,
      description3,
      description4,
      description5
    } = values;
    const data = {
      title: values.title,
      description: {
        description1,
        description2,
        description3,
        description4,
        description5
      },
      user: values.user,
      authToken: user.authToken,
      coverImage: values.coverImage
    };

    await createLoveStoryApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Love story successfully created'
        );
        setProcessingRequest(false);
        dispatch(
          fetchLoveStoryRequest({
            authToken: user.authToken
          })
        );
        setCreateCoverImage(false);
        dispatch(reSetUploadedImage({}));
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        setCreateCoverImage(false);
        dispatch(reSetUploadedImage({}));
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'title',
            'description1',
            'user',
            'cover_image'
          ]);
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Failed to process request');
        }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('name')) {
      dispatchNotifyActions(dispatch, 'error', data.name[0]);
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('image')) {
      dispatchNotifyActions(dispatch, 'error', data.image[0]);
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('description')) {
      dispatchNotifyActions(dispatch, 'error', data.description[0]);
    } else {
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request. Try again'
      );
    }
  };

  const handleCreateCoverImage = () => {
    setCreateCoverImage(true);
  };

  const handleOnSubmit = async () => {
    if (!editStory) {
      await createLoveStory();
    } else {
      await updateLoveStory();
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };
  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      coverImage: image
    });
  };
  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editStory ? 'Edit Love Story' : 'Add new love story'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            {!createCoverImage && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        {...register('title', {
                          required: {
                            value: true,
                            message: 'Title is required'
                          }
                        })}
                        type="text"
                        name="title"
                        label="Title"
                        placeholder="Enter  title"
                        error={errors.title && true}
                        inputProps={{
                          defaultValue: editStory && storyDetails.title
                        }}
                        onChange={e => handleChange(e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.title && errors.title.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth error={Boolean(errors.user)}>
                      <InputLabel id="demo-simple-select-label">
                        Select a user
                      </InputLabel>

                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: 'User is required'
                          }
                        }}
                        control={control}
                        defaultValue={editStory ? () => 'value' : ''}
                        name="user"
                        render={({ field: { value, name, onChange } }) => (
                          <Select
                            // disabled={loadingUsers}
                            name={name}
                            value={value}
                            displayEmpty
                            renderValue={
                              editStory ? () => storyDetails.user_text : ''
                            }
                            onChange={e => {
                              handleChange(e);
                              onChange(e.target.value);
                            }}>
                            {users.map((user, index) => (
                              <MenuItem value={user.id} key={index}>
                                {user.username}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <div style={{ color: 'red' }}>
                        <FormHelperText>
                          {errors.user && errors.user.message}
                        </FormHelperText>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <DescriptionList
                  values={values}
                  errors={errors}
                  editDetails={editStory}
                  editForm={editStory}
                  handleChange={handleChange}
                  register={register}
                  setStateValues={setStateValues}
                  defaultData={storyDetails}
                />
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                    <FormControl>
                      <FormLabel component="legend">
                        Upload cover image
                      </FormLabel>
                      <FileUploadInput
                        canPerformActions
                        setStateValues={setStateValues}
                        values={values}
                        resetFileUpload={resetForm}
                        handleFileChange={e => {
                          handleFileUpload(e);
                        }}
                        accept=".jpg, .png, .jpeg"
                        message={'Love story cover image is required'}
                        errors={errors}
                        register={register}
                        validate={!editStory}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editStory ? 'Edit Love Story' : 'Create Love Story'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateLoveStoriesForm;
