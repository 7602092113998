import React, { Fragment } from 'react';

import { ExampleWrapperSeamless } from '../../../layout-components';
import ManageClientGalleryTable from '../../Tables/client-gallery/manageClientGalleryTable';

export default function ClientsGallery({ storyId, fetchGallery }) {
  return (
    <Fragment>
      <ExampleWrapperSeamless sectionHeading="">
        <ManageClientGalleryTable storyId={storyId} fetchGallery={fetchGallery} />
      </ExampleWrapperSeamless>
    </Fragment>
  );
}
