import React, {Fragment, useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import {
  createGalleryApi,
  fetchClientGalleryImageNamesApi,
  fetchGalleryImageNamesApi, fetchLoveStoryGalleryImageNamesApi, fetchServiceGalleryImageNamesApi
} from '../../../api/authServices/manageGalleries/galleries';
import { dispatchNotifyActions } from '../../../utils/helper';
import Typography from "@mui/material/Typography";

const UploadCategoryGalleryForm = ({
  handleClose,
  open,
  categoryId,
  type,
  galleryType,
  fetchGallery
}) => {
  const [values, setStateValues] = useState({
    image: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [progress, setProgress] = useState();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [existingImages, setExistingImage] = useState([]);
  const handleModalClose = () => {
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      image: null
    });
  };
  useEffect(() => {
    handleImageNamesRequest();
  }, []);

  const fetchGalleryImageNames = async func => {
    const data = { authToken: user.authToken, id: categoryId };
    await func({
      data,
      onSuccess: res => {
        for (let i = 0; i < res.data?.length; i++) {
          existingImages.push(res.data[i].name);
        }
      },
      onError: err => {
        console.log(err);
      }
    });
  };

  const handleImageNamesRequest = () => {
    if (type === 'clientgallery') {
      fetchGalleryImageNames(fetchClientGalleryImageNamesApi);
    } else if (type === 'lovestory') {
      fetchGalleryImageNames(fetchLoveStoryGalleryImageNamesApi);
    } else if (type === 'service') {
      fetchGalleryImageNames(fetchServiceGalleryImageNamesApi);
    }
  };

  const handleDuplicateMessage = result => {
    if (result.length === 0) {
      return false;
    }
    return true;
  };

  const handleDuplicateBeforePost = uploadedImages => {
    console.log(existingImages);
    if (existingImages.length > 0) {
      const test = uploadedImages.filter(val => {
        return !existingImages.includes(val.name);
      });

      return test;
    }
    return uploadedImages;
  };

  const createGallery = async postData => {
    setProcessingRequest(true);
    // const removeDuplicates = [...new Set(values.image)];
    // const postData = handleDuplicateBeforePost(removeDuplicates);
    // handleDuplicateMessage(postData);
    const data = {
      id: categoryId,
      image: postData,
      authToken: user.authToken,
      type
    };
    await createGalleryApi(setProgress, {
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatchNotifyActions(
          dispatch,
          'success',
          'Gallery successfully uploaded'
        );
        dispatch(
          // fetchCategoryGalleryApi
          fetchGallery({
            id: categoryId,
            authToken: user.authToken,
            galleryType: galleryType
          })
        );
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          switch (err.response.data.status) {
            case 400:
              handleResponseValidationErrors(data);
              break;
            case 500:
              dispatchNotifyActions(
                dispatch,
                'error',
                'Gallery upload failed.'
              );
              break;
            default:
              dispatchNotifyActions(
                dispatch,
                'error',
                'Gallery upload failed.'
              );
          }
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Gallery upload failed.');
        }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('image')) {
      dispatchNotifyActions(dispatch, 'error', data.name[0]);
    } else {
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request. Try again'
      );
    }
  };

  const handleOnSubmit = async () => {
    const removeDuplicates = [...new Set(values.image)];
    const postData = handleDuplicateBeforePost(removeDuplicates);

    if (handleDuplicateMessage(postData)) {
      await createGallery(postData);
    } else {
      dispatchNotifyActions(
        dispatch,
        'error',
        'Duplicate image(s) found. Kindly upload new images'
      );
    }
  };
  // handle file upload
  const handleFileUpload = e => {
    setStateValues({
      ...values,
      image: e.target.files
    });
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={'Upload Gallery'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />

        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Typography variant={'caption'} color={'primary'}>
              Duplicates in bulk upload will automatically be removed
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">Upload Gallery</FormLabel>
                  {!progress && (
                    <FileUploadInput
                      canPerformActions
                      setStateValues={setStateValues}
                      values={values}
                      resetFileUpload={resetForm}
                      handleFileChange={e => {
                        handleFileUpload(e);
                      }}
                      accept=".jpg, .png, .jpeg"
                      errors={errors}
                      message={'Image is required'}
                      validate={true}
                      register={register}
                    />
                  )}
                </FormControl>
                {progress && (
                  <div
                    className={
                      'progress-bar progress-bar-info progress-bar-striped'
                    }
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + '%' }}>
                    {`${progress}%`}
                  </div>
                )}
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={'Upload'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default UploadCategoryGalleryForm;
