import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';

import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import { fetchAllUsersApi } from '../../../api/authServices/users/users';

import { fetchClientGalleryRequest } from '../../../redux/actions/clientGalleryActions';
import {
  createClientGalleryApi,
  updateClientGalleryApi
} from '../../../api/authServices/clientGallery/clientGallery';
import {
  checkImageDetails,
  dispatchNotifyActions,
  handleResponseError
} from '../../../utils/helper';

import { reSetUploadedImage } from '../../../redux/actions/fileUpload';

const CreateClientGalleryForm = ({
  handleClose,
  editClientGallery,
  setEditClientGallery,
  clientGalleryDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    title: editClientGallery ? clientGalleryDetails.title : '',
    link: editClientGallery ? clientGalleryDetails.link : '',
    user: editClientGallery ? clientGalleryDetails.user : null,
    coverImage: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [invalidImage, setInvalidImage] = useState(true);
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [imageList, setImageList] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleModalClose = () => {
    if (editClientGallery) {
      setEditClientGallery(false);
    }
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    reset({
      title: '',
      link: '',
      file: [],
      description: '',
      user: null
    });
  };

  useEffect(() => {
    if (open) {
      fetchAllUsers();
    }
  }, [open]);

  const fetchAllUsers = async () => {
    const data = {
      authToken: user.authToken
    };
    const res = await fetchAllUsersApi(data);
    setUsers(res.data);
    setLoadingUsers(false);
  };
  const updateClientGallery = async () => {
    setProcessingRequest(true);
    const data = {
      title: values.title,
      coverImage: values.coverImage,
      link: values.link,
      id: clientGalleryDetails.id,
      user: values.user,
      authToken: user.authToken
    };
    await updateClientGalleryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchClientGalleryRequest({
            authToken: user.authToken
          })
        );
        dispatchNotifyActions(
          dispatch,
          'success',
          'Client gallery successfully updated'
        );
        handleModalClose();
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            if (err.response) {
              handleResponseError(
                err.response,
                setProcessingRequest,
                dispatch,
                ['title', 'cover_image', 'user']
              );
            } else {
              dispatchNotifyActions(
                dispatch,
                'error',
                'Client gallery creation failed'
              );
            }
            break;
          case 500:
            setProcessingRequest(false);
            dispatchNotifyActions(
              dispatch,
              'error',
              'Client gallery update failed'
            );
            break;
          default:
            setProcessingRequest(false);
            dispatchNotifyActions(
              dispatch,
              'error',
              'Client gallery update failed'
            );
        }
      }
    });
  };

  const createClientGallery = async () => {
    setProcessingRequest(true);
    const data = {
      title: values.title,
      coverImage: values.coverImage,
      link: values.link,
      user: values.user,
      authToken: user.authToken
    };

    await createClientGalleryApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Client gallery successfully created'
        );
        setProcessingRequest(false);
        dispatch(
          fetchClientGalleryRequest({
            authToken: user.authToken
          })
        );
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        dispatch(reSetUploadedImage({}));
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'title',
            'cover_image',
            'user'
          ]);
        } else {
          dispatchNotifyActions(
            dispatch,
            'error',
            'Client gallery creation failed'
          );
        }
      }
    });
  };
  const handleOnSubmit = async () => {
    if (!editClientGallery) {
      await createClientGallery();
    } else {
      await updateClientGallery();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };
  const handleMultipleFile = files => {
    const imagesList = [];
    for (let i = 0; i < files.length; i++) {
      imagesList.push(files[i]);
    }
    return imagesList;
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      coverImage: image
    });
    // checkImageDetails(image, 1365, 2048, setInvalidImage, setNotifyPopUp);
  };
  // handle file upload
  const handleStoryImagesUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      storyImages: image
    });
    checkImageDetails(image, 1365, 2048, invalidImage, setInvalidImage);
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={
            editClientGallery ? 'Edit Client Gallery' : 'Add new client gallery'
          }
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('title', {
                      required: {
                        value: true,
                        message: 'Title is required'
                      }
                    })}
                    type="text"
                    name="title"
                    label="Title"
                    placeholder="Enter  title"
                    error={errors.title && true}
                    inputProps={{
                      defaultValue:
                        editClientGallery && clientGalleryDetails.title
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.title && errors.title.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth error={Boolean(errors.user)}>
                  <InputLabel id="demo-simple-select-label">
                    Select a user
                  </InputLabel>

                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: 'User is required'
                      }
                    }}
                    control={control}
                    defaultValue={editClientGallery ? () => 'value' : ''}
                    name="user"
                    render={({ field: { value, name, onChange } }) => (
                      <Select
                        // disabled={loadingUsers}
                        name={name}
                        value={value}
                        displayEmpty
                        renderValue={
                          editClientGallery
                            ? () => clientGalleryDetails.user_text
                            : ''
                        }
                        onChange={e => {
                          handleChange(setStateValues, values, e);
                          onChange(e.target.value);
                        }}>
                        {users.map((user, index) => (
                          <MenuItem value={user.id} key={index}>
                            {user.username}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <div style={{ color: 'red' }}>
                    <FormHelperText>
                      {errors.user && errors.user.message}
                    </FormHelperText>
                  </div>
                </FormControl>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                  <FormControl>
                    <FormLabel component="legend">Upload cover image</FormLabel>
                    <FileUploadInput
                      register={register}
                      errors={errors}
                      message={'Cover image is required'}
                      canPerformActions
                      setStateValues={setStateValues}
                      values={values}
                      resetFileUpload={resetForm}
                      handleFileChange={e => {
                        handleFileUpload(e);
                      }}
                      accept=".jpg, .png, .jpeg"
                      validate={!editClientGallery}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <p id="fileInfo"></p>
              {/*<Grid container>*/}
              {/*  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>*/}
              {/*    <FormControl>*/}
              {/*      <FormLabel component="legend">*/}
              {/*        Upload story images*/}
              {/*      </FormLabel>*/}
              {/*      <FileUploadInput*/}
              {/*        errors={errors}*/}
              {/*        register={register}*/}
              {/*        message={'Story image is required'}*/}
              {/*        canPerformActions*/}
              {/*        setStateValues={setStateValues}*/}
              {/*        values={values}*/}
              {/*        resetFileUpload={resetForm}*/}
              {/*        handleFileChange={e => {*/}
              {/*          handleStoryImagesUpload(e);*/}
              {/*        }}*/}
              {/*        accept=".jpg, .png, .jpeg"*/}
              {/*        validate={!editStory}*/}
              {/*      />*/}
              {/*    </FormControl>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </Grid>
          </DialogContent>
          <DialogActions>
            {/*{!invalidImage && (*/}
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={
                editClientGallery
                  ? 'Edit Client Gallery'
                  : 'Create Client Gallery'
              }
            />
            {/*)}*/}
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateClientGalleryForm;
