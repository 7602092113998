import React from 'react';
// import { capitalizeFirstLetter } from '../../../../utils/helper';

export const getPricingTypeKey = (pricingTypeEnum, value) =>
  Object.keys(pricingTypeEnum).find(key => pricingTypeEnum[key] === value);

export const dataFormatter = (data, setTableData) => {
  let counter = 1;
  const galleryLinks = data.map(row => ({
    id: row.id,
    user_text: row.user_text,
    user: row.user,
    gallery_link: row.gallery_link,
    title: row.title,
    time_created: row.time_created,
    counter: counter++
  }));
  setTableData(galleryLinks);
};

// toggles delete modal
export const toggleDeleteCodeModal = (setDeleteCodeModal, deleteCodeModal) => {
  setDeleteCodeModal(!deleteCodeModal);
};

// toggles edit gallery link modal
export const editModal = (setEditLinkModal, editLinkModal) => {
  setEditLinkModal(!editLinkModal);
};

// executes  delete modal
export const deleteModal = (
  setGalleryLinkDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryLinkDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

// executes edit modal and set code data for editing
export const editLink = (
  setGalleryLinkDetails,
  setEditLinkModal,
  editLinkModal,
  setEditLink,
  data
) => {
  setGalleryLinkDetails(data);
  setEditLink(true);
  editModal(setEditLinkModal, editLinkModal);
};
