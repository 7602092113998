import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import AppButton from './AppButton/AppButton';
import CustomDialogTitle from './CustomDialogTitle/CustomDialogTitle';
import { Warning } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

const styles = makeStyles(theme => ({
  modalPaper: {
    padding: '30px'
  },

  outlinedPrimary: {
    color: theme.palette.error['dark'],
    border: `1px solid ${theme.palette.error['dark']}`,

    '&:hover': {
      // color: theme.palette.error["dark"],
      border: `1px solid ${theme.palette.error['dark']}`
    }
  },

  actionsRoot: {
    justifyContent: 'center'
  }
}));

const AppConfirmModal = ({
  open,
  title,
  toggleModal,
  cancelText,
  confirmText,
  content,
  confirmAction,
  heading,
  loading
}) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      classes={{ paper: classes.modalPaper }}>
      <CustomDialogTitle titleText={title} toggleModal={toggleModal} />
      <DialogContent style={{ display: 'inline-flex', textAlign: 'center' }}>
        <Grid container>
          <Grid xs={2} item>
            <Warning color="error" style={{ margin: '0 4px' }} />
          </Grid>
          <Grid xs={10} item>
            <span style={{ fontWeight: 'bold' }}>{heading}</span>
          </Grid>
          <Grid xs={12} item>
            <div style={{ marginTop: '10px' }}>{content}</div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot }}>
        <AppButton
          variant="contained"
          color="default"
          onClick={toggleModal}
          disabled={loading}>
          {cancelText}
        </AppButton>
        <AppButton
          variant="contained"
          style={{ color: 'white' }}
          color={'primary'}
          // classes={{ outlinedPrimary: classes.outlinedPrimary }}
          onClick={confirmAction}
          loading={loading}
          disabled={loading}>
          {confirmText}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

AppConfirmModal.propTypes = {
  titleText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  toggleModal: PropTypes.func,
  confirmAction: PropTypes.func,
  content: PropTypes.node
};

export default AppConfirmModal;
