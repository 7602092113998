import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Card, Button, Tooltip } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { initRequest, publishGallery } from '../../utils/helper';
import AppLoader from '../../components/loader/loader';
import NoDataFound from '../../components/common/DataNotFound';

import { fetchPageImageRequest } from '../../redux/actions/pageImagesActions';
import AppButtonGroup from '../../components/AppButtonGroup/AppButtonGroup';
import PublishButton from '../../components/publish/publishButton';
import { PageTitle } from '../../layout-components';
import CreatePageImage from './pageImages';

import { deletePageImageApi } from '../../api/authServices/pageImages/pageImages';
import AppDeleteModal from '../../components/modal/AppDeleteModal/AppDeleteModal';
import { toggleDeleteCodeModal } from '../../components/Tables/gallery-links/helper';
import Typography from '@mui/material/Typography';
import PopupNotif2 from '../../components/notifications/popupnotif2';

import AppConfirmModal from '../../components/modal/confirmActionModal';
import { publishPageImagesApi } from '../../api/authServices/publish/publish';

import Img from 'react-cool-img';
import AppViewModal from '../../components/modal/appViewModal';
import SingleCategoryDetail from '../../components/photo-gallery/details/singleDetail';
import { toggleViewModal } from '../../components/Tables/love-story/helper';
import imgPlaceholder from '../../assets/images/aaa_small.png';

export default function PageImageList({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [pages, setPages] = useState([
    { id: 1, name: 'Contact us' },
    { id: 2, name: 'Home' },
    { id: 3, name: 'Client gallery' },
    { id: 4, name: 'Love story' },
    { id: 5, name: 'Portfolio' },
    { id: 6, name: 'Blog' },
    { id: 7, name: 'About us' }
  ]);

  const [pageDetails, setPageDetails] = useState({
    name: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const pageimageState = useSelector(state => state.PageImageReducer);
  const { loading, data } = pageimageState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchPageImageRequest);
  }, [dispatch]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenViewDetails = () => {
    setViewDetails(true);
  };

  const handleCloseViewDetails = () => {
    setViewDetails(false);
  };
  const handleEditModalClose = () => {
    setEditCategoryModal(false);
  };

  const handleOpenManage = () => {
    setOpenManage(true);
  };

  const handleCloseManage = () => {
    setOpenManage(false);
  };

  const getPageName = page => {
    if (data && data.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].id === page) {
          return pages[i].name;
        }
      }
    }
  };

  const routeChange = (categoryId, categoryName, description) => {
    history.push({
      pathname: 'ManageCategory',
      state: {
        data: {
          id: categoryId,
          name: categoryName,
          description: description
        }
      }
    });
  };
  // const toggleEditModal = () => {
  //   setEditCategory(true);
  //   editModal(setEditCategoryModal, editCategoryModal);
  // };
  const handleNoGalleryForSelectedCategory = data => {
    if (data.length === 0) {
      setErrorPopUp({
        status: true,
        popupType: 'info',
        message: `No gallery found for ${pageDetails.name}`
      });
    } else {
      alert(JSON.stringify(data));
    }
    handleCloseViewDetails();
  };

  const toggleDeleteModal = (id, page_text) => {
    setPageDetails({
      ...pageDetails,
      page_text,
      id
    });
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };

  const toggleEditModal = (page_text, page, id) => {
    setPageDetails({
      ...pageDetails,
      page_text,
      page,
      id
    });
    setEditPage(true);
    handleOpen();
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const deletePageImage = async id => {
    setProcessingRequest(true);
    const data = {
      id,
      authToken: user.authToken
    };
    await deletePageImageApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Page image successfully deleted'
        });
        dispatch(
          fetchPageImageRequest({
            authToken: user.authToken
          })
        );
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(false);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Page image deletion failed'
        });
      }
    });
  };

  const togglePublishProcess = page => {
    setPageDetails({
      ...pageDetails,
      id: page.id,
      name: page.name,
      status: page.status
    });
    toggleConfirmModal();
  };

  const publishPageImage = () => {
    publishGallery(
      setProcessingRequest,
      pageDetails,
      user.authToken,
      setErrorPopUp,
      publishPageImagesApi,
      toggleConfirmModal,
      fetchPageImageRequest,
      dispatch,
      `Page image successfully ${
        pageDetails.status ? 'unpublished' : 'publish'
      }`,
      `Page image ${pageDetails.status ? 'unpublished' : 'publish'} failed`
    );
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage page images"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      {!loading ? (
        <Grid container spacing={1}>
          {data &&
            data?.length > 0 &&
            data?.map((page, index) => (
              <Grid item xs={12} sm={6} md={4}>
                <Card className="card-transparent mb-4">
                  <div className="card-img-wrapper">
                    <Tooltip
                      title={''}
                      onClick={() => {
                        setPageDetails({
                          ...pageDetails,
                          name: getPageName(page.page).toUpperCase(),
                          image: page.image,
                          id: page.id
                        });
                        setViewImageModal(true);
                      }}>
                      <Img
                        style={{ cursor: 'grab' }}
                        src={page.image}
                        className="card-img-top rounded"
                        alt="..."
                        cache
                        placeholder={imgPlaceholder}
                      />
                    </Tooltip>
                  </div>
                  {/*<div className="card-body text-left">*/}
                  {/*  <h5 className="card-title font-weight-bold font-size-md">*/}
                  {/*    {getPageName(page.page).toUpperCase()}*/}
                  {/*  </h5>*/}
                  {/*  /!*<p className="card-text">{category.description}</p>*!/*/}
                  {/*</div>*/}

                  <AppButtonGroup name={getPageName(page.page).toUpperCase()}>
                    <Button
                      onClick={() => {
                        toggleEditModal(
                          getPageName(page.page),
                          page.page,
                          page.id
                        );
                      }}>
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        toggleDeleteModal(page.id, getPageName(page.page));
                      }}>
                      Delete
                    </Button>
                    <PublishButton
                      status={page.status}
                      toggleModal={() => togglePublishProcess(page)}
                    />
                  </AppButtonGroup>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}

      {!loading && <NoDataFound data={data} />}

      {open && (
        <CreatePageImage
          open={open}
          editPage={editPage}
          pageList={data}
          pageDetails={pageDetails}
          handleClose={handleClose}
        />
      )}

      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete Page Image"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={
          <Typography
            variant={
              'caption'
            }>{`You are about to delete ${pageDetails?.page_text} page image`}</Typography>
        }
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deletePageImage(pageDetails?.id);
        }} // action to delete item
      />
      <AppConfirmModal
        open={confirmModal}
        title={`Confirm ${pageDetails?.status ? 'unpublished' : 'publish'}`}
        cancelText="No, Cancel"
        confirmText="Yes, Confirm"
        toggleModal={() => {
          toggleConfirmModal();
        }}
        heading={`You are about to publish this image`}
        content={
          <p>
            {' '}
            Are you sure you want to{' '}
            {`${pageDetails?.status ? 'unpublished' : 'publish'}`}?
          </p>
        }
        loading={processingRequest}
        confirmAction={() => {
          publishPageImage();
        }} // action to delete item
      />
      {viewImageModal && (
        <AppViewModal
          modalSize={'md'}
          title={pageDetails.name + ' - Page Image'}
          children={<SingleCategoryDetail image={pageDetails.image} />}
          open={viewImageModal}
          handleClose={() => {
            toggleViewModal(setViewImageModal, viewImageModal);
          }}
        />
      )}
      <PopupNotif2
        activate={errorPopUp?.status}
        messageContent={errorPopUp?.message}
        popupType={errorPopUp?.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
}
