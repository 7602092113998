import React from 'react';
import { ButtonGroup } from '@material-ui/core';

const AppButtonGroup = props => {
  return (
    <div className="card-body text-left">
      <h6 className="card-title font-weight-bold font-size-md">
        {props.name}
      </h6>
      {/*<p className="card-text">{category.description}</p>*/}
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group">
        {props.children}
      </ButtonGroup>
    </div>
  );
};

export default AppButtonGroup;
