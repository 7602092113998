import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  createUserApi,
  updateUserApi
} from '../../../api/authServices/users/users';

import { fetchAllUsersRequest } from '../../../redux/actions/usersActions';
import {dispatchNotifyActions, handleResponseError} from "../../../utils/helper";
import {createAdminApi, updateAdminApi} from "../../../api/admins/admins";
import {fetchAllAdminRequest} from "../../../redux/actions/adminActions";

const CreatAdminForm = ({
  handleClose,
  editUser,
  open,
  userDetails,
  setEditUser
}) => {
  const [values, setStateValues] = useState({
    firstName: editUser ? userDetails.fist_name : '',
    lastName: editUser ? userDetails.last_name : '',
    username: editUser ? userDetails.username : '',
    email: editUser ? userDetails.email : '',
    phone: editUser ? userDetails.phone : ''
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const userState = useSelector(state => state.UserReducer);
  const { loading, data } = userState;
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const handleModalClose = () => {
    if (editUser) {
      setEditUser(false);
    }
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phone: ''
    });
  };

  const updateUser = async () => {
    setProcessingRequest(true);
    const data = {
      prevData: userDetails,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      id: userDetails.id,
      authToken: user.authToken
    };
    await updateAdminApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchAllUsersRequest({
            authToken: user.authToken
          })
        );
        dispatchNotifyActions(
          dispatch,
          'success',
          'Admin successfully updated'
        );
        handleModalClose();
      },
      onError: err => {
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            dispatch,
            ['first_name', 'last_name', 'username', 'email', 'phone']
          );
        } else{
          dispatchNotifyActions(dispatch, 'error', 'Admin update failed');
        }
      }
    });
  };

  const createUser = async () => {
    setProcessingRequest(true);
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      username: values.username,
      phone: values.phone,
      authToken: user.authToken
    };

    await createAdminApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Admin successfully created'
        );
        setProcessingRequest(false);
        dispatch(fetchAllAdminRequest({ authToken: user.authToken }));
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            dispatch,
            ['first_name', 'last_name', 'username', 'email', 'phone']
          );
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Admin creation failed');
        }
      }
    });
  };
  const handleOnSubmit = async () => {
    if (!editUser) {
      await createUser();
    } else {
      await updateUser();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editUser ? 'Edit Admin' : 'Add new Admin'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="create-user">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('firstName', {
                      required: {
                        value: true,
                        message: 'First name is required'
                      }
                    })}
                    type="text"
                    name="firstName"
                    label="First name"
                    placeholder="Enter first name"
                    error={errors.galleryLink && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.first_name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.firstName && errors.firstName.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('lastName', {
                      required: {
                        value: true,
                        message: 'Last name is required'
                      }
                    })}
                    type="text"
                    name="lastName"
                    label="Last name"
                    placeholder="Enter last name"
                    error={errors.lastName && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.last_name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.lastName && errors.lastName.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Email is required'
                      }
                    })}
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="Enter email address"
                    error={errors.email && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.email
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.email && errors.email.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            {!editUser && (
              <Grid container>
                <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                  <FormControl fullWidth>
                    <TextField
                      {...register('username', {
                        required: {
                          value: true,
                          message: 'Username is required'
                        }
                      })}
                      type="text"
                      name="username"
                      label="Username"
                      placeholder="Enter email address"
                      error={errors.username && true}
                      inputProps={{
                        defaultValue: editUser && userDetails.username
                      }}
                      onChange={e => handleChange(setStateValues, values, e)}
                    />
                    <div style={{ color: 'red' }}>
                      <Typography variant="caption" gutterBottom align="center">
                        {errors.username && errors.username.message}
                      </Typography>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone number is required'
                      }
                    })}
                    type="text"
                    name="phone"
                    label="Phone"
                    placeholder="Enter phone number"
                    error={errors.phone && true}
                    inputProps={{
                      defaultValue: editUser && userDetails.phone
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.phone && errors.phone.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editUser ? 'Update admin' : 'Create admin'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreatAdminForm;
