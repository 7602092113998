import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid
} from '@material-ui/core';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import AppButton from '../../modal/AppButton/AppButton';
import { useDispatch, useSelector } from 'react-redux';

import AppLoader from '../../loader/loader';
import { fetchLoveStoryGalleryRequest } from '../../../redux/actions/loveStoryGalleryActions';
import Slideshow from "../../../assets/components/slideShow/slideImage";

export default function SingleLoveStoryDetail({
  image,
  open,
  handleClose,
  storyDetail
}) {
  const loveStoryGalleryState = useSelector(
    state => state.LoveStoryGalleryReducer
  );
  const authState = useSelector(state => state.AuthReducer);
  const { loading, data } = loveStoryGalleryState;
  const { user } = authState;
  const dispatch = useDispatch();
  const [test, setTest] = useState(true);

  useEffect(() => {
    // alert('running gallery details component');
    // const user = JSON.parse(localStorage.getItem('user'));
    // dispatch(recoverAuth(user));
    // dispatch(
    //   fetchGalleryRequest({
    //     id: categoryDetails.id,
    //     authToken: user.authToken
    //   })
    // );
    dispatch(
      fetchLoveStoryGalleryRequest({
        id: storyDetail.id,
        authToken: user.authToken,
        galleryType: 'love-stories-galleries'
      })
    );
  }, [dispatch, storyDetail.id, user.authToken]);
  return (
    <Fragment>
      <Dialog
        open={!loading && open}
        onClose={handleClose}
        maxWidth={loading ? 'xs' : 'md'}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        scroll="body">
        {!loading && (
          <CustomDialogTitle toggleModal={handleClose}>
            {storyDetail.title}
          </CustomDialogTitle>
        )}
        <DialogContent>
          {loading ? (
            <AppLoader />
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={12}>
                <Card className="card-transparent mb-4">
                  <div className="card-img-wrapper">
                    <img
                      src={`${storyDetail.coverImage}`}
                      className="card-img-top rounded"
                      alt="..."
                    />
                  </div>
                </Card>
                <h3>{storyDetail.title}</h3>
                <Divider />
                <br />
                <p>{storyDetail.description1}</p>
                <p>{storyDetail.description2}</p>
                <p>{storyDetail.description3}</p>
                <p>{storyDetail.description4}</p>
                <p>{storyDetail.description5}</p>
                <div className="divider border-2 border-dark my-5 border-dark opacity-2 mx-auto rounded-circle w-50" />
                {data && data.length > 0 && <Slideshow images={data} />}

                {/*<Grid container spacing={4}>*/}
                {/*  {data &&*/}
                {/*    data.map(gallery => (*/}
                {/*      <Grid item xs={12} sm={6} md={4}>*/}
                {/*        <Card className="card-transparent mb-4">*/}
                {/*          <div className="card-img-wrapper">*/}
                {/*            <img*/}
                {/*              src={*/}
                {/*                'http://localhost:8001/media/' +*/}
                {/*                gallery.tall_img*/}
                {/*              }*/}
                {/*              className="card-img-top rounded"*/}
                {/*              alt="..."*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*        </Card>*/}
                {/*      </Grid>*/}
                {/*    ))}*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <AppButton onClick={handleClose}>Close</AppButton>
          </DialogActions>
        )}
      </Dialog>
      <AppLoader loading={loading} />
    </Fragment>
  );
}
