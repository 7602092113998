import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import { checkImageDetails, initRequest, resetForm } from '../../../utils/helper';
import { submitForm } from './helper';
import { fetchAboutPageContentRequest } from '../../../redux/actions/aboutPageActions';

const CreateTheTeamForm = ({
  handleClose,
  editTeam,
  setEditTeam,
  teamDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    name: editTeam ? teamDetails.name : '',
    email: editTeam ? teamDetails.email : '',
    phone: editTeam ? teamDetails.phone : '',
    title: editTeam ? teamDetails.title : '',
    profileImage: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [imageDetails, setImageDetials] = useState({});
  const [invalidImage, setInvalidImage] = useState(true);

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    initRequest(dispatch, fetchAboutPageContentRequest);
  }, []);

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleModalClose = () => {
    if (editTeam) {
      setEditTeam(false);
    }
    resetForm(
      reset,
      setStateValues({
        profileImage: []
      })
    );
    handleClose();
  };

  const handleOnSubmit = async () => {
    submitForm(
      editTeam,
      setProcessingRequest,
      values,
      teamDetails,
      user,
      dispatch,
      handleModalClose,
      reset,
      {
        name: '',
        email: '',
        phone: '',
        title: '',
        profileImage: null,
        file: null
      }
    );
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      profileImage: image
    });
    // checkImageDetails(image, imageDetails, setImageDetials);
  };
  const handleResetFileField = () => {
    // resetForm(reset, {
    //   profileImage: null,
    //   file: null
    // });
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editTeam ? 'Edit Team member' : 'Add new team member'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Name is required'
                      }
                    })}
                    type="text"
                    name="name"
                    label="Full name"
                    placeholder="Enter full name"
                    error={errors.name && true}
                    inputProps={{
                      defaultValue: editTeam && teamDetails.name
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.name && errors.name.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Email is required'
                      }
                    })}
                    type="email"
                    name="email"
                    label="Email address"
                    placeholder="Enter email address"
                    error={errors.email && true}
                    inputProps={{
                      defaultValue: editTeam && teamDetails.email
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.email && errors.email.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone is required'
                      }
                    })}
                    type="text"
                    name="phone"
                    label="Phone"
                    placeholder="Enter phone number"
                    error={errors.phone && true}
                    inputProps={{
                      defaultValue: editTeam && teamDetails.phone
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.phone && errors.phone.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    {...register('title', {
                      required: {
                        value: true,
                        message: 'Title is required'
                      }
                    })}
                    type="text"
                    name="title"
                    label="Title"
                    placeholder="Enter title"
                    error={errors.title && true}
                    inputProps={{
                      defaultValue: editTeam && teamDetails.title
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.title && errors.title.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">Upload profile image</FormLabel>
                  <FileUploadInput
                    canPerformActions
                    setStateValues={setStateValues}
                    values={values}
                    resetFileUpload={handleResetFileField}
                    handleFileChange={e => {
                      handleFileUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    message={'Profile image is required'}
                    errors={errors}
                    register={register}
                    validate={!editTeam}
                  />
                  <p id={'fileInfo'}></p>

                  {/*<div style={{ color: 'red' }}>*/}
                  {/*  <Typography variant="caption" gutterBottom align="center">*/}
                  {/*    {errors.image && errors.image.message}*/}
                  {/*  </Typography>*/}
                  {/*</div>*/}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editTeam ? 'Edit member' : 'Create member'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateTheTeamForm;
