import React, {useState} from 'react';
import {Avatar, Tooltip} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/Delete';
import DataTableLoader from '../DataTableLoader';
import { deleteModal, editLink } from './helper';

export const setTableColumn = (
  setColumns,
  galleryLinkDetails,
  setEditLinkModal,
  editLinkModal,
  setDeleteCodeModal,
  deleteCodeModal,
  setGalleryLinkDetails,
  setEditLink
) =>
  setColumns([
    {
      name: 'counter',
      label: '#',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'user_text',
      label: 'User',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'gallery_link',
      label: 'Gallery Link',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'title',
      label: 'Gallery Title',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'time_created',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true
      }
    },
    // {
    //   name: 'Action',
    //   label: <span style={{ marginLeft: 20 }}>Action</span>,
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (e, d) => {
    //       const rData = {
    //         user_text: d.rowData[1],
    //         link: d.rowData[2],
    //         description: d.rowData[3],
    //         id: d.rowData[6],
    //         user: d.rowData[7]
    //       };
    //       return (
    //         <div style={{ display: 'flex', flexDirection: 'row' }}>
    //           {/*<Tooltip*/}
    //           {/*  title="Edit"*/}
    //           {/*  onClick={() => {*/}
    //           {/*    editLink(*/}
    //           {/*      setGalleryLinkDetails,*/}
    //           {/*      setEditLinkModal,*/}
    //           {/*      editLinkModal,*/}
    //           {/*      setEditLink,*/}
    //           {/*      rData*/}
    //           {/*    );*/}
    //           {/*    console.log(rData);*/}
    //           {/*  }}*/}
    //           {/*  style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10 }}>*/}
    //           {/*  <Edit color="primary" />*/}
    //           {/*</Tooltip>*/}
    //           <Tooltip
    //             title="Delete"
    //             style={{ cursor: 'pointer', marginLeft: 20 }}
    //             onClick={() => {
    //               deleteModal(
    //                 setGalleryLinkDetails,
    //                 setDeleteCodeModal,
    //                 deleteCodeModal,
    //                 rData
    //               );
    //             }}>
    //             <DeleteForever style={{ color: 'red', marginLeft: 10 }} />
    //           </Tooltip>
    //         </div>
    //       );
    //     }
    //   }
    // },
    {
      name: 'id',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'user',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    }
  ]);

export const setTableOptions = setOptions =>
  setOptions({
    filterType: 'textField',
    // selectableRowsHideCheckboxes: 'hideCheckBox',
    // selectableRowsOnClick: true,
    // selectableRows: 'multi',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'No data found'
      }
    }
  });

export const setTableOptions2 = (setOptions2) =>
  setOptions2({
    filterType: 'textField',
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: <DataTableLoader message="Fetching data..." />
      }
    }
  });
