import React, { Fragment } from 'react';

import {IconButton, Paper, Tooltip} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';

import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
  { icon: <FavoriteIcon />, name: 'Like' }
];

function PageTitle(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Paper square elevation={2} className="app-page-title">
        <div>
          <div className="app-page-title--first">
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              <div className="app-page-title--description">
                {props.titleDescription}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="speedial-wrapper">
            {props.createObj && (
              <IconButton onClick={props.handleOpen}>
                <SpeedDial
                  ariaLabel="SpeedDial menu"
                  icon={
                    props.data?.length > 0 ? (
                      <Tooltip title={'Edit'}>
                        <EditIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Add'}>
                        <SpeedDialIcon />
                      </Tooltip>
                    )
                  }
                  direction="left"
                  open={open}>
                  {/*{actions.map(action => (*/}
                  {/*  <SpeedDialAction*/}
                  {/*    key={action.name}*/}
                  {/*    icon={action.icon}*/}
                  {/*    tooltipTitle={action.name}*/}
                  {/*    onClick={handleClose}*/}
                  {/*  />*/}
                  {/*))}*/}
                </SpeedDial>
              </IconButton>
            )}
          </div>
        </div>
      </Paper>
    </Fragment>
  );
}

export default PageTitle;
