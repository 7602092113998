import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import PopupNotif2 from '../../notifications/popupnotif2';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import { fetchContactInfoRequest } from '../../../redux/actions/contactUsInfoActions';
import {
  createContactInfoApi,
  updateContactInfoApi
} from '../../../api/authServices/contactUs/contactInfo';
import { handleResponseError, initRequest } from '../../../utils/helper';

const ManageContactUsInfoForm = ({ handleClose, open }) => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const infoState = useSelector(state => state.ContactUsInfoReducer);
  const { loading, data } = infoState;
  const [values, setStateValues] = useState({
    email: data && data.length > 0 ? data[0].email : '',
    phone: data && data.length > 0 ? data[0].phone : '',
    address: data && data.length > 0 ? data[0].address : '',
    description: data && data.length > 0 ? data[0].description : '',
    navImage: [],
    id: data && data.length > 0 ? data[0].id : null
  });

  useEffect(() => {
    initRequest(dispatch, fetchContactInfoRequest);
  }, [dispatch]);

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const handleModalClose = () => {
    if (data && data.length > 0) {
      setEditContact(false);
    }
    resetForm();
    handleClose();
  };
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const resetForm = () => {
    reset({
      email: '',
      phone: '',
      address: '',
      description: ''
    });
  };

  const updateContactPageContent = async () => {
    setProcessingRequest(true);
    const { email, address, phone, description } = values;
    const data = {
      email,
      phone,
      address,
      description,
      authToken: user.authToken,
      id: infoState.data && infoState.data[0].id
    };
    await updateContactInfoApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchContactInfoRequest({
            authToken: user.authToken
          })
        );
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Contact information successfully updated'
        });
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            setNotifyPopUp,
            ['email', 'address', 'description', 'phone']
          );
        } else {
          setNotifyPopUp({
            status: true,
            popupType: 'error',
            message: 'Failed to process request. Try again'
          });
        }
      }
    });
  };

  const createContactPageContent = async () => {
    setProcessingRequest(true);
    const { email, address, phone, description } = values;
    const data = {
      email,
      phone,
      address,
      description,
      authToken: user.authToken
    };

    await createContactInfoApi({
      data,
      onSuccess: res => {
        setNotifyPopUp({
          status: true,
          popupType: 'success',
          message: 'Contact information successfully created'
        });
        setProcessingRequest(false);
        dispatch(
          fetchContactInfoRequest({
            authToken: user.authToken
          })
        );
        resetForm();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            setNotifyPopUp,
            ['email', 'address', 'description', 'phone']
          );
        } else {
          setNotifyPopUp({
            status: true,
            popupType: 'error',
            message: 'Failed to process request. Try again'
          });
        }
      }
    });
  };
  const handleOnSubmit = async () => {
    if (data && !data.length > 0) {
      await createContactPageContent();
    } else {
      await updateContactPageContent();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      navImage: image
    });
  };

  const handleInfoImagesUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      infoImage: image
    });
  };
  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={'Manage Contact Informations'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register(`email`, {
                      required: {
                        value: true,
                        message: `Email is required`
                      }
                    })}
                    type="email"
                    name="email"
                    label={'Contact email'}
                    placeholder="Enter contact email"
                    error={errors.name && true}
                    inputProps={{
                      defaultValue: values.email
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.email && errors.email.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register(`phone`, {
                      required: {
                        value: true,
                        message: `Phone number is required`
                      }
                    })}
                    type="text"
                    name="phone"
                    label={'Contact phone'}
                    placeholder="Enter contact phone"
                    error={errors.phone && true}
                    inputProps={{
                      defaultValue: values.phone
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.phone && errors.phone.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`address`, {
                      required: {
                        value: false,
                        message: `Address is required`
                      }
                    })}
                    type="text"
                    name="address"
                    label={'Contact address (Optional)'}
                    placeholder="Enter contact address"
                    error={errors.address && true}
                    inputProps={{
                      defaultValue: values.address
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.address && errors.address.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`description`, {
                      required: {
                        value: false,
                        message: `Description is required`
                      }
                    })}
                    type="text"
                    name="description"
                    label={'Contact description (Optional)'}
                    placeholder="Enter contact description"
                    error={errors.description && true}
                    inputProps={{
                      defaultValue: values.aboutInfoText2
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.description && errors.description.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={
                data && data.length > 0 ? 'Update Contact' : 'Create Contact'
              }
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default ManageContactUsInfoForm;
