import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchLoveStoryApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/manage-love-stories/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const fetchLoveStoryGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/galleries/love-stories/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createLoveStoryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.coverImage);
  formData.append('square_image', data.coverImage);
  formData.append('land_scape_image', data.coverImage);
  formData.append('description', JSON.stringify(data.description));
  formData.append('title', data.title);
  formData.append('user', data.user);
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-love-stories/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateLoveStoryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('cover_image', data.coverImage);
  formData.append('square_image', data.coverImage);
  formData.append('land_scape_image', data.coverImage);
  formData.append('description', JSON.stringify(data.description));
  formData.append('title', data.title);
  formData.append('user', data.user);
  if (data.coverImage && data.coverImage.length === 0) {
    formData.delete('cover_image');
    formData.delete('square_image');
    formData.delete('land_scape_image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-love-stories/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteLoveStoryApi = async ({
  data,
  onSuccess,
  onError
}) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-love-stories/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
