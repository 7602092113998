import { CLIENT_GALLERY } from '../actionTypes';
import { fetchLoveStoryApi } from '../../api/authServices/loveStories/loveStories';
import {fetchAllClientGalleryApi, fetchClientGalleryApi} from "../../api/authServices/clientGallery/clientGallery";
import {fetchClientGalleryGalleriesApi} from "../../api/authServices/manageGalleries/galleries";

//Get all gallery links
export const fetchClientGallery = () => ({
  type: CLIENT_GALLERY.FETCH_CLIENT_GALLERY_DATA
});

export const fetchClientGallerySuccess = data => ({
  type: CLIENT_GALLERY.FETCH_CLIENT_GALLERY_SUCCESS,
  payload: {
    data
  }
});

export const fetchClientGalleryFail = err => ({
  type: CLIENT_GALLERY.FETCH_CLIENT_GALLERY_FAIL,
  payload: {
    err
  }
});

export const fetchClientGalleryRequest = data => {
  return dispatch => {
    dispatch(fetchClientGallery());
    fetchClientGalleryApi(data)
      .then(res => {
        dispatch(fetchClientGallerySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchClientGalleryFail(err.message));
      });
  };
};
