import { ABOUT_PAGE } from '../actionTypes';
import { fetchAboutPageContentApi } from '../../api/authServices/aboutPage/aboutPage';

export const fetchAboutPageContent = () => ({
  type: ABOUT_PAGE.FETCH_ABOUT_DATA
});

export const fetchAboutPageContentSuccess = data => ({
  type: ABOUT_PAGE.FETCH_ABOUT_SUCCESS,
  payload: {
    data
  }
});

export const fetchAboutPageContentFail = err => ({
  type: ABOUT_PAGE.FETCH_ABOUT_FAIL,
  payload: {
    err
  }
});

export const fetchAboutPageContentRequest = data => {
  return dispatch => {
    dispatch(fetchAboutPageContent());
    fetchAboutPageContentApi(data)
      .then(res => {
        dispatch(fetchAboutPageContentSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAboutPageContentFail(err.message));
      });
  };
};
