import { HOME_GALLERY } from '../actionTypes';
import { fetchGalleryImageApi } from '../../api/authServices/galleryImages/galleryImage';

//Get all gallery links
export const fetchHomeGalleryImage = () => ({
  type: HOME_GALLERY.FETCH_HOME_GALLERY_DATA
});

export const fetchHomeGalleryImageSuccess = data => ({
  type: HOME_GALLERY.FETCH_HOME_GALLERY_SUCCESS,
  payload: {
    data
  }
});

export const fetchHomeGalleryImageFail = err => ({
  type: HOME_GALLERY.FETCH_HOME_GALLERY_FAIL,
  payload: {
    err
  }
});

export const fetchHomeImageGalleryRequest = data => {
  return dispatch => {
    dispatch(fetchHomeGalleryImage());
    fetchGalleryImageApi(data)
      .then(res => {
        dispatch(fetchHomeGalleryImageSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchHomeGalleryImageFail(err.message));
      });
  };
};
