import { recoverAuth } from '../redux/actions/authActions';
import {toggleDeleteCodeModal} from "../components/Tables/theTeam/helper";
import {updateGalleryStatusApi} from "../api/authServices/publish/publish";
import {setNotifyParams} from "../redux/actions/notifications";

export const initRequest = (dispatch, request) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user !== null) {
    dispatch(recoverAuth(user));
    dispatch(
      request({
        authToken: user.authToken
      })
    );
  }
};

export const recoverUserAuth = dispatch => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    dispatch(recoverAuth(user));
  }
};

export const handleResponseValidationErrors = (
  dispatch,
  err,
  payloadFields
) => {
  for (let i = 0; i < payloadFields.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (err.data.hasOwnProperty(payloadFields[i])) {
      dispatchNotifyActions(dispatch, 'error', err.data[payloadFields[i]]);
    }
  }
};

export const handleResponseError = (
  errData,
  setProcessingRequest,
  dispatch,
  payloadFields
) => {
  switch (errData.status) {
    case 400:
      setProcessingRequest(false);
      handleResponseValidationErrors(dispatch, errData, payloadFields);
      break;
    case 500:
      setProcessingRequest(false);
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request.Try again'
      );
      break;
    default:
      setProcessingRequest(false);
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request.Try again'
      );
  }
};

export const handleNoResponseError = (msg, setProcessingRequest, dispatch) => {
  setProcessingRequest(false);
  dispatchNotifyActions(dispatch, 'error', msg);
};

export const resetForm = (reset, resetData) => {
  reset(resetData);
};

// handle file upload
// const handleFileUpload = e => {
//   const image = e.target.files[0];
//   setStateValues({
//     ...values,
//     coverImage: image
//   });
//   checkImageSize(image);
// };

export const checkImageDetails = (
  image,
  width,
  height,
  setInvalidImage,
  setNotifyPopUp
) => {
  let reader = new FileReader(); // CREATE AN NEW INSTANCE.
  reader.onload = e => {
    let img = new Image();
    img.src = e.target.result;

    img.onload = () => {
      let w = img.width;
      let h = img.height;

      document.getElementById('fileInfo').innerHTML =
        document.getElementById('fileInfo').innerHTML +
        '<br /> ' +
        'Size: <b>' +
        Math.round(image.size / 1024) +
        '</b> KB <br />' +
        'Width: <b>' +
        w +
        '</b> <br />' +
        'Height: <b>' +
        h +
        '</b> <br />' +
        '</b> <br />';

      if (w >= width && h >= height) {
        setInvalidImage(false);
      } else {
        setInvalidImage(true);
        setNotifyPopUp({
          status: true,
          popupType: 'error',
          message: 'Please upload recommended image dimension'
        });
      }
    };
  };
  reader.readAsDataURL(image);
};

// export const checkImageSize = (file, imageDetails, setImageDetails) => {
//   let reader = new FileReader(); // CREATE AN NEW INSTANCE.
//   setImageDetails({
//     ...imageDetails,
//     height: 0,
//     width: 0
//   });
//
//   reader.onload = e => {
//     let img = new Image();
//     img.src = e.target.result;
//
//     setImageDetails({
//       ...imageDetails,
//       height: img.height,
//       width: img.width
//     });
//
//     img.onload = () => {
//       let w = img.width;
//       let h = img.height;
//
//       document.getElementById('fileInfo').innerHTML =
//         document.getElementById('fileInfo').innerHTML +
//         '<br /> ' +
//         // 'Name: <b>' +
//         // file.name +
//         // '</b> <br />' +
//         // 'Size: <b>' +
//         // Math.round(file.size / 1024) +
//         // '</b> KB <br />' +
//         'Width: <b>' +
//         w +
//         '</b> <br />' +
//         'Height: <b>' +
//         h +
//         '</b> <br />' +
//         // 'Type: <b>' +
//         // file.type +
//         '</b> <br />';
//     };
//   };
//   reader.readAsDataURL(file);
// };

export const deleteModalFromTable = (
  setGalleryDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

export const publishGallery = async (
  setProcessingRequest,
  typeDetails,
  authToken,
  setErrorPopUp,
  requestAPi,
  toggleConfirmModal,
  fetchData,
  dispatch,
  successMessage,
  failedMessage
) => {
  setProcessingRequest(true);
  const data = {
    id: typeDetails.id,
    authToken: authToken,
    status: !typeDetails.status
  };
  await requestAPi({
    data,
    onSuccess: res => {
      setProcessingRequest(false);
      setErrorPopUp({
        status: true,
        popupType: 'success',
        message: successMessage
      });
      dispatch(fetchData({ authToken: authToken }));
      toggleConfirmModal();
    },
    onError: err => {
      setProcessingRequest(false);
      setErrorPopUp({
        status: true,
        popupType: 'error',
        message: failedMessage
      });
    }
  });
};

export const dispatchNotifyActions = (dispatch, type, message) => {
  dispatch(
    setNotifyParams({
      status: true,
      type: type,
      message: message
    })
  );
};

