import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import Delete from '@material-ui/icons/Delete';
import {
  makeStyles,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Typography, TextField, Input
} from '@material-ui/core';

const FileUploadInput = ({
  accept,
  defaultValue,
  handleFileChange,
  caption = '',
  canPerformActions = false,
  resetFileUpload,
  register,
  message,
  errors,
  validate
}) => {
  const useStyles = makeStyles((theme) => ({
    fileInput: {
      display: 'none',
    },

    smallCaption: {
      position: 'relative',
      marginTop: '10px',

      '& .MuiFormLabel-root': {
        fontSize: '0.7rem',
      }
    },

    smallCaptionLabel: {
      position: 'relative',
      marginTop: '-40px',
      marginLeft: '-8px',
      top: 0,
    },

    btnAction: {
      textTransform: 'Capitalize',
      fontSize: '0.8rem',
      marginTop: '10px',
    },

    wrapper: {
      '& $btnAction': {
        color: `${theme.palette.primary.main} !important`,

        '&.danger': {
          color: `${theme.palette.error.main} !important`,
        }
      }
    },
    btn: {
      borderRadius: '4px'
    }
  }));

  const [files, setFiles] = useState(defaultValue);
  const classes = useStyles();

  const handleFileChangeAction = e => {
    // eslint-disable-next-line no-shadow
    const files = e.target.files[0];
    setFiles(files);
    handleFileChange(e);
  };

  const handleDiscardAction = () => {
    setFiles(null);
    // resetFileUpload(setFiles);
  };

  return (
    <>
      <Typography variant="div">{caption}</Typography>
      {files && <Typography variant="div">{files.name}</Typography>}
      {!files && (
        <FormControl>
          <Input
            inputProps={{ multiple: true }}
            error={errors.file && true}
            className={`${classes.fileInput} formItemPad`}
            id="contained-button-file"
            type="file"
            accept={accept}
            {...register('file', {
              required: {
                value: validate,
                message: message
              }
            })}
            onChange={(e) => {
              handleFileChangeAction(e);
            }}
            name="file"
          />
          <div style={{ margin: '10px 0' }}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<UploadFileIcon />}
                className={`${classes.formItemPad}`}
                classes={{ root: classes.btn }}>
                Upload
              </Button>
            </label>
          </div>
          <Grid classes={{ root: classes.smallCaption }}>
            <InputLabel
              htmlFor="ussd-user-id"
              classes={{ root: classes.smallCaptionLabel }}>
              <b>*Accepted formats:</b> {accept}
            </InputLabel>
          </Grid>
          <div style={{ color: 'red' }}>
            <Typography variant="caption" gutterBottom align="center">
              {errors.file && errors.file.message}
            </Typography>
          </div>
        </FormControl>
      )}
      {canPerformActions && files && (
        <Grid classes={{ root: classes.wrapper }}>
          <Button
            startIcon={<Delete />}
            variant="text"
            color="primary"
            classes={{ root: classes.btnAction }}
            className="danger"
            onClick={handleDiscardAction}>
            Discard and Upload new
          </Button>
        </Grid>
      )}
    </>
  );
};

FileUploadInput.propTypes = {
  accept: PropTypes.string.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  canPerformActions: PropTypes.bool.isRequired,
  resetFileUpload: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  defaultValue: PropTypes.any
};

export default FileUploadInput;
