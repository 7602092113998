import React, { Fragment } from 'react';
import { PageTitle } from '../../layout-components';
import CreateServicePageForm from '../../components/services/form/createServicePageForm';
export default function ServicePage() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Service Page"
        titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      {open && (
        <CreateServicePageForm open={open} handleClose={handleClose} />
      )}
    </Fragment>
  );
}
