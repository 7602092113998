import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const loginUserApi = async data => {
  return await AxiosService.post(`${baseUrl}/accounts/auth-admin/login/`, data)
    .then(res => res)
    .catch(err => err);
};

export const changePasswordApi = async ({ data, onSuccess, onError }) => {
  console.log(data.id);
  const formData = new FormData();
  formData.append('old_password', data.oldPassword);
  formData.append('password', data.newPassword);
  formData.append('password2', data.confirmPassword);
  return await AxiosService.put(
    `${baseUrl}/accounts/auth-admin/change-password/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};

export const loginOutApi = async data => {
  return await AxiosService.post(
    `${baseUrl}/accounts/logout/`,
    {},
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const updateAccountProfileApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('first_name', data.first_name);
  formData.append('last_name', data.last_name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);

  if (data.email === data.prevData.email) {
    formData.delete('email');
  }
  if (data.phone === data.prevData.phone) {
    formData.delete('phone');
  }

  return await AxiosService.patch(
    `${baseUrl}/accounts/update-profile/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
