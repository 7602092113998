import React, { Fragment } from 'react';
import { FormControl, TextField, Typography } from '@material-ui/core';

const DescriptionField = ({
  register,
  errors,
  name,
  editForm,
  editDetails,
  handleChange,
  setStateValues,
  values,
  required,
  title,
  label,
  defaultData,
  error,
  errorMessage
}) => {
  return (
    <Fragment>
      <FormControl fullWidth>
        <TextField
          rows={3}
          multiline
          {...register(`${name}`, {
            required: {
              value: required,
              message: `${title} is required`
            }
          })}
          type="text"
          name={name}
          label={label}
          placeholder="Enter category description"
          error={error && true}
          inputProps={{
            defaultValue: editForm && defaultData
          }}
          onChange={e => handleChange(e)}
        />
        <div style={{ color: 'red' }}>
          <Typography variant="caption" gutterBottom align="center">
            {error && errorMessage}
          </Typography>
        </div>
      </FormControl>
    </Fragment>
  );
};
export default DescriptionField;
