import { CONTACT_US } from '../actionTypes';
import { fetchAboutPageContentApi } from '../../api/authServices/aboutPage/aboutPage';
import {fetchContactPageContentApi} from "../../api/authServices/contactUs/contactPage";

export const fetchContactPageContent = () => ({
  type: CONTACT_US.FETCH_CONTACT_DATA
});

export const fetchContactPageContentSuccess = data => ({
  type: CONTACT_US.FETCH_CONTACT_SUCCESS,
  payload: {
    data
  }
});

export const fetchContactPageContentFail = err => ({
  type: CONTACT_US.FETCH_CONTACT_FAIL,
  payload: {
    err
  }
});

export const fetchContactPageContentRequest = data => {
  return dispatch => {
    dispatch(fetchContactPageContent());
    fetchContactPageContentApi(data)
      .then(res => {
        dispatch(fetchContactPageContentSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchContactPageContentFail(err.message));
      });
  };
};
