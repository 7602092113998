import React, { Fragment, useEffect } from 'react';
import { PageTitle } from '../../../layout-components';
import UploadCategoryGalleryForm from '../../../components/photo-gallery/forms/uploadGalleryForm';
import { fetchLoveStoryGalleryRequest } from '../../../redux/actions/loveStoryGalleryActions';
import ClientsGallery from '../../../components/client-gallery/manage';
import { fetchClientPhotosRequest } from '../../../redux/actions/clientPhotosActions';

export default function ManageClientGallery(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading={`${props.history.location.state.data.title}`}
        // titleDescription={`${props.history.location.state.data.description}`}
        createObj={true}
        handleOpen={handleOpen}
      />
      <ClientsGallery
        storyId={props.history.location.state.data.id}
        fetchGallery={fetchClientPhotosRequest}
      />
      {open && (
        <UploadCategoryGalleryForm
          categoryId={props.history.location.state.data.id}
          open={open}
          handleClose={handleClose}
          type={'clientgallery'}
          fetchGallery={fetchClientPhotosRequest}
        />
      )}
    </Fragment>
  );
}

