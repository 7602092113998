import { SERVICE_PAGE } from '../actionTypes';
import { fetchServicePageContentApi } from '../../api/authServices/service/services';

export const fetchServicePageContent = () => ({
  type: SERVICE_PAGE.FETCH_SERVICE_PAGE_DATA
});

export const fetchServicePageContentSuccess = data => ({
  type: SERVICE_PAGE.FETCH_SERVICE_PAGE_SUCCESS,
  payload: {
    data
  }
});

export const fetchServicePageContentFail = err => ({
  type: SERVICE_PAGE.FETCH_SERVICE_PAGE_FAIL,
  payload: {
    err
  }
});

export const fetchServicePageContentRequest = data => {
  return dispatch => {
    dispatch(fetchServicePageContent());
    fetchServicePageContentApi(data)
      .then(res => {
        dispatch(fetchServicePageContentSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchServicePageContentFail(err.message));
      });
  };
};
