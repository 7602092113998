import React from 'react';
// import { capitalizeFirstLetter } from '../../../../utils/helper';

export const getPricingTypeKey = (pricingTypeEnum, value) =>
  Object.keys(pricingTypeEnum).find(key => pricingTypeEnum[key] === value);

export const dataFormatter = (data, setTableData) => {
  let counter = 1;
  const users = data.map(row => ({
    id: row.id,
    first_name: row.first_name,
    last_name: row.last_name,
    email: row.email,
    username: row.username,
    phone: row.phone,
    date_joined: row.date_joined,
    is_active: row.is_active === true ? 'Active' : 'inactive',
    last_login: row.last_login,
    password_text: row.password_text,
    counter: counter++
  }));
  setTableData(users);
};

// toggles delete modal
export const toggleDeleteCodeModal = (setDeleteCodeModal, deleteCodeModal) => {
  setDeleteCodeModal(!deleteCodeModal);
};

export const editModal = (setEditLinkModal, editLinkModal) => {
  setEditLinkModal(!editLinkModal);
};

// executes  delete modal
export const deleteModal = (
  setGalleryLinkDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryLinkDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

// executes edit modal and set code data for editing
export const editUser = (
  setUserDetail,
  setEditUserModal,
  editUserModal,
  setEditUser,
  data
) => {
  setUserDetail(data);
  setEditUser(true);
  editModal(setEditUserModal, editUserModal);
};
