import { LOVE_STORY_GALLERY } from '../actionTypes';
import { fetchLoveStoryGalleryApi} from '../../api/authServices/loveStories/loveStories';

//Get all gallery links
export const fetchLoveStoryGallery = () => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_DATA
});

export const fetchLoveStoryGallerySuccess = data => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_SUCCESS,
  payload: {
    data
  }
});

export const fetchLoveStoryGalleryFail = err => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_FAIL,
  payload: {
    err
  }
});

export const fetchLoveStoryGalleryRequest = data => {
  return dispatch => {
    dispatch(fetchLoveStoryGallery());
    fetchLoveStoryGalleryApi(data)
      .then(res => {
        dispatch(fetchLoveStoryGallerySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchLoveStoryGalleryFail(err.message));
      });
  };
};
