import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesRequest } from '../../../redux/actions/categoriesActions';
import {
  createCategoryApi,
  updateCategoryApi
} from '../../../api/authServices/galleryCategory/category';
import FileUploadInput from '../../FileUploadInput/FileUploadInput';
import {dispatchNotifyActions, handleResponseError} from '../../../utils/helper';
import {createCarouselApi, updateCarouselApi} from "../../../api/authServices/carousel/carousel";
import {fetchCarouselRequest} from "../../../redux/actions/carouselActions";

const CreateCarouselImageForm = ({
  handleClose,
  editCarousel,
  setEditCarousel,
  carouselDetails,
  open
}) => {
  const [values, setStateValues] = useState({
    header: editCarousel ? carouselDetails.header : '',
    title: editCarousel ? carouselDetails.title : '',
    message: editCarousel ? carouselDetails.message : '',
    image: []
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleModalClose = () => {
    if (editCarousel) {
      setEditCarousel(false);
    }
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      image: []
    });
  };

  const updateCarouselImage = async () => {
    setProcessingRequest(true);
    const data = {
      header: values.header,
      title: values.title,
      message: values.message,
      image: values.image,
      id: carouselDetails.id,
      authToken: user.authToken
    };
    await updateCarouselApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatch(
          fetchCarouselRequest({
            authToken: user.authToken
          })
        );
        dispatchNotifyActions(
          dispatch,
          'success',
          'Carousel image successfully updated'
        );
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      },
      onError: err => {
        if (err.response) {
          handleResponseError(err.response, setProcessingRequest, dispatch, [
            'title',
            'message',
            'image'
          ]);
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Carousel update failed');
        }
      }
    });
  };

  const createCarouselImage = async () => {
    setProcessingRequest(true);
    const data = {
      header: values.header,
      title: values.title,
      message: values.message,
      image: values.image,
      authToken: user.authToken
    };

    await createCarouselApi({
      data,
      onSuccess: res => {
        dispatchNotifyActions(
          dispatch,
          'success',
          'Carousel image successfully uploaded'
        );
        setProcessingRequest(false);
        dispatch(
          fetchCarouselRequest({
            authToken: user.authToken
          })
        );
        resetForm();
        handleModalClose();
      },
      onError: err => {
        setProcessingRequest(false);
        if (err.response) {
          handleResponseError(
            err.response,
            setProcessingRequest,
            dispatch,
            ['title', 'message', 'image', 'header']
          );
        } else {
          dispatchNotifyActions(dispatch, 'error', 'Carousel creation failed');
        }
      }
    });
  };

  const handleOnSubmit = async () => {
    if (!editCarousel) {
      await createCarouselImage();
    } else {
      await updateCarouselImage();
    }
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      image: image
    });
    // checkImageDetails(image, 1365, 2048, setInvalidImage, setNotifyPopUp);
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={editCarousel ? 'Edit carousel' : 'Add new carousel'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        {...register('header', {
                          required: {
                            value: true,
                            message: 'Carousel header is required',
                          },
                          maxLength: {
                            value: 15,
                            message: 'Header can not be more than 15 characters'
                          }
                        })}
                        type="text"
                        name="header"
                        label="Header"
                        placeholder="Enter carousel header"
                        error={errors.header && true}
                        inputProps={{
                          defaultValue: editCarousel && carouselDetails.header
                        }}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.header && errors.header.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{marginTop: 20}}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        {...register('title', {
                          required: {
                            value: true,
                            message: 'Carousel title is required',
                          },
                          maxLength: {
                            value: 15,
                            message: 'Title can not be more than 15 characters'
                          }
                        })}
                        type="text"
                        name="title"
                        label="Title"
                        placeholder="Enter carousel title"
                        error={errors.title && true}
                        inputProps={{
                          defaultValue: editCarousel && carouselDetails.title
                        }}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.title && errors.title.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                    <FormControl fullWidth>
                      <TextField
                        {...register('message', {
                          required: {
                            value: true,
                            message: 'Carousel message is required',
                          },
                          maxLength: {
                            value: 40,
                            message:
                              'Favourite name can not be more than 40 characters'
                          }
                        })}
                        type="text"
                        name="message"
                        label="Message"
                        placeholder="Enter carousel message"
                        error={errors.message && true}
                        inputProps={{
                          defaultValue:
                            editCarousel && carouselDetails.message
                        }}
                        onChange={e => handleChange(setStateValues, values, e)}
                      />
                      <div style={{ color: 'red' }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          align="center">
                          {errors.message && errors.message.message}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl style={{ marginTop: 20 }}>
                  <FormLabel component="legend">
                    Upload carousel image
                  </FormLabel>
                  <FileUploadInput
                    canPerformActions
                    setStateValues={setStateValues}
                    values={values}
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleFileUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    message={'Carousel image is required'}
                    errors={errors}
                    register={register}
                    validate={!editCarousel}
                  />
                </FormControl>
              </Grid>
              <p id={'fileInfo'}></p>
            </Grid>
            {/*<p id="fileInfo"></p>*/}
          </DialogContent>
          <DialogActions>
            {/*{!invalidImage && (*/}
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={editCarousel ? 'Update carousel' : 'Create carousel'}
            />
            {/*)}*/}
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreateCarouselImageForm;
