import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import PopupNotif2 from '../../notifications/popupnotif2';
import CustomDialogTitle from '../../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../../modal/style';
import { AppModalFormActions } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadInput from '../../FileUploadInput/FileUploadInput';

import { submitForm } from './helper';
import {resetForm} from "../../../utils/helper";

const ManageAboutPageForm = ({
  handleClose,
  editStory,
  setEditStory,
  open
}) => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const aboutPageState = useSelector(state => state.AboutPageReducer);
  const { loading, data } = aboutPageState;
  const [values, setStateValues] = useState({
    aboutInfoText1: data && data.length > 0 ? data[0].info_text1 : '',
    aboutInfoText2: data && data.length > 0 ? data[0].info_text2 : '',
    aboutHeaderText: data && data.length > 0 ? data[0].header_text : '',
    infoImage: [],
    pageImage: [],
    id: data && data.length > 0 ? data[0].id : null
  });

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const handleModalClose = () => {
    if (editStory) {
      setEditStory(false);
    }
    resetForm(reset, {
      aboutInfoText1: '',
      aboutInfoText2: '',
      aboutHeaderText: '',
      infoImage: [],
      pageImage: []
    });
    handleClose();
  };
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const handleOnSubmit = () => {
    submitForm(
      data,
      setProcessingRequest,
      values,
      user,
      dispatch,
      reset,
      {
        aboutInfoText1: '',
        aboutInfoText2: '',
        aboutHeaderText: '',
        infoImage: [],
        pageImage: []
      },
      handleModalClose,
      loading
    );
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      pageImage: image
    });
  };

  const handleInfoImagesUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      infoImage: image
    });
  };
  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={'Manage About Page'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`aboutHeaderText`, {
                      required: {
                        value: true,
                        message: `About page header is required`
                      }
                    })}
                    type="text"
                    name="aboutHeaderText"
                    label={'About header text'}
                    placeholder="Enter About header text"
                    error={errors.aboutHeaderText && true}
                    inputProps={{
                      defaultValue: values.aboutHeaderText
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.aboutHeaderText && errors.aboutHeaderText.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`aboutInfoText1`, {
                      required: {
                        value: true,
                        message: `About page info 1 is required`
                      }
                    })}
                    type="text"
                    name="aboutInfoText1"
                    label={'About info text'}
                    placeholder="Enter About info text 1"
                    error={errors.aboutInfoText1 && true}
                    inputProps={{
                      defaultValue: values.aboutInfoText1
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.aboutInfoText1 && errors.aboutInfoText1.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    rows={3}
                    multiline
                    {...register(`aboutInfoText2`, {
                      required: {
                        value: false,
                        message: `About page info 2 is required`
                      }
                    })}
                    type="text"
                    name="aboutInfoText2"
                    label={'About info text 2 (Optional)'}
                    placeholder="Enter About info text 2"
                    error={errors.aboutInfoText2 && true}
                    inputProps={{
                      defaultValue: values.aboutInfoText2
                    }}
                    onChange={e => handleChange(setStateValues, values, e)}
                  />
                  <div style={{ color: 'red' }}>
                    <Typography variant="caption" gutterBottom align="center">
                      {errors.aboutInfoText2 && errors.aboutInfoText2.message}
                    </Typography>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            {/*<Grid container>*/}
            {/*  <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>*/}
            {/*    <FormControl>*/}
            {/*      <FormLabel component="legend">Upload page image</FormLabel>*/}
            {/*      <FileUploadInput*/}
            {/*        register={register}*/}
            {/*        errors={errors}*/}
            {/*        message={'About page image is required'}*/}
            {/*        canPerformActions*/}
            {/*        resetFileUpload={resetForm}*/}
            {/*        handleFileChange={e => {*/}
            {/*          handleFileUpload(e);*/}
            {/*        }}*/}
            {/*        accept=".jpg, .png, .jpeg"*/}
            {/*        validate={!values.aboutHeaderText}*/}
            {/*      />*/}
            {/*    </FormControl>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <p id="fileInfo"></p>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">
                    Upload about info image
                  </FormLabel>
                  <FileUploadInput
                    errors={errors}
                    register={register}
                    message={'About info image is required'}
                    canPerformActions
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={!values.aboutHeaderText}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={data && data.length > 0 ? 'Update' : 'Submit'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default ManageAboutPageForm;
