import React from 'react';
import {Avatar, Tooltip} from '@material-ui/core';
import DeleteForever from '@material-ui/icons/Delete';
import DataTableLoader from '../DataTableLoader';
import {deleteModal, editCode, editTeam, viewGallery, viewTeamMember} from './helper';
import {Edit, Visibility} from "@material-ui/icons";

export const setTableColumn = (
  setColumns,
  setTeamDetails,
  setEditTeamModal,
  editTeamModal,
  setEditTeam,
  setCodeModal,
  codeDeleteModal,
  setViewTeamModal,
  viewTeamModal
) =>
  setColumns([
    {
      name: 'counter',
      label: '#',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Full name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'time_created',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Action',
      label: <span style={{ marginLeft: 20 }}>Action</span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (e, d) => {
          const rData = {
            name: d.rowData[1],
            email: d.rowData[2],
            phone: d.rowData[3],
            title: d.rowData[4],
            time_created: d.rowData[5],
            id: d.rowData[7],
            image: d.rowData[8]
          };
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip
                title="View"
                onClick={() => {
                  viewTeamMember(
                    setTeamDetails,
                    setViewTeamModal,
                    viewTeamModal,
                    rData
                  );
                }}
                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10 }}
              >
                <Visibility color="primary" />
              </Tooltip>
              <Tooltip
                title="Edit"
                onClick={() => {
                  editTeam(
                    setTeamDetails,
                    setEditTeamModal,
                    editTeamModal,
                    setEditTeam,
                    rData
                  );
                }}
                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10 }}
              >
                <Edit color="primary" />
              </Tooltip>
              <Tooltip
                title="Delete"
                style={{ cursor: 'pointer', marginLeft: 20 }}
                onClick={() => { deleteModal(setTeamDetails, setCodeModal, codeDeleteModal, rData); }}
              >
                <DeleteForever style={{ color: 'red', marginLeft: 10 }} />
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'cover_image',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    }
  ]);

export const setTableOptions = (
  setOptions
) =>
  setOptions({
    filterType: 'textField',
    // selectableRowsHideCheckboxes: 'hideCheckBox',
    // selectableRowsOnClick: true,
    // selectableRows: 'multi',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'No data found'
      }
    }
  });

export const setTableOptions2 = setOptions2 =>
  setOptions2({
    filterType: 'textField',
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: <DataTableLoader message="Fetching data..." />
      }
    }
  });
