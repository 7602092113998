import { GALLERY_LINKS } from '../actionTypes';
import { fetchGalleryLinksApi } from '../../api/authServices/galleryLinks/galleryLinks';

//Get all gallery links
export const fetchGalleryLinks = () => ({
  type: GALLERY_LINKS.FETCH_DATA
});

export const fetchGalleryLinkSuccess = data => ({
  type: GALLERY_LINKS.FETCH_DATA_SUCCESS,
  payload: {
    data
  }
});

export const fetchGalleryLinkFail = err => ({
  type: GALLERY_LINKS.FETCH_DATA_FAIL,
  payload: {
    err
  }
});

export const fetchGalleryLinksRequest = (data) => {
  return dispatch => {
    dispatch(fetchGalleryLinks());
    fetchGalleryLinksApi(data)
      .then(res => {
        dispatch(fetchGalleryLinkSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchGalleryLinkFail(err.message));
      });
  };
};
