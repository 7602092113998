import AxiosService from '../authServices/axiosService';
import baseUrl from '../authServices/BaseUrl';

export const fetchAllAdminsApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/manage-admins/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createAdminApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-admins/`,
    data,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
export const updateAdminApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('first_name', data.first_name);
  formData.append('last_name', data.last_name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);

  if (data.email === data.prevData.email) {
    formData.delete('email');
  }
  if (data.phone === data.prevData.phone) {
    formData.delete('phone');
  }

  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-admins/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteAdminApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-admins/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
