import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchAboutPageContentApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/pages/about-content/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createAboutPageContentApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('info_text1', data.aboutInfoText1);
  formData.append('info_text2', data.aboutInfoText2);
  formData.append('header_text', data.aboutHeaderText);
  formData.append('page_image', data.pageImage);
  formData.append('info_image', data.infoImage);
  return await AxiosService.post(
    `${baseUrl}/adminportal/pages/about-content/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateAboutPageContentApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('info_text1', data.aboutInfoText1);
  formData.append('info_text2', data.aboutInfoText2);
  formData.append('header_text', data.aboutHeaderText);
  formData.append('info_image', data.infoImage);
  formData.append('page_image', data.pageImage);
  if (data.infoImage.length === 0) {
    formData.delete('info_image');
  }
  if (data.pageImage.length === 0) {
    formData.delete('page_image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages/about-content/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
