import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchGalleryImageApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/home-gallery-image/`, {
      headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createGalleryImageApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('cover_image', data.image);
  return await AxiosService.post(
    `${baseUrl}/adminportal/home-gallery-image/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateGalleryImageApi = async ({ data, onSuccess, onError }) => {
  console.log(data.image);

  const formData = new FormData();
    formData.append('cover_image', data.image);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/home-gallery-image/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteGalleryImageApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/home-gallery-image/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const publishGalleryImageApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/home-gallery-image/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
