import React, { Fragment } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';

import avatar5 from '../../assets/images/avatars/avatar5.jpg';
import { logoutUser} from "../../redux/actions/authActions";
import { loginOutApi } from '../../api/authServices/authUser/loginUser';
export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const destroyToken = async () => {
    await loginOutApi({ authToken: user.authToken });
  };

  const userProfile = () => {
    history.push('/UserProfile');
  }

  const changePassword = () => {
    history.push('/Change-password')
  }

  const logOut = () => {
    localStorage.clear();
    dispatch(logoutUser());
    destroyToken();
    history.replace('/login');
  };
  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <Avatar sizes="44" alt="Emma Taylor" />
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{user.userDetail.username}</div>
          {/*<span className="text-white-50">Senior React Developer</span>*/}
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <Avatar sizes="44" alt="Emma Taylor" />
            </Box>
            <div className="pl-3  pr-3">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {user.userDetail.username}
              </div>
              {/*<span className="text-black-50 text-center">*/}
              {/*  Senior React Developer*/}
              {/*</span>*/}
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={userProfile}>Profile settings</ListItem>
            <ListItem button onClick={changePassword}>Change password</ListItem>
            <ListItem button onClick={logOut}>
              Logout
            </ListItem>
            {/*<Divider className="w-100" />*/}
            {/*<ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">*/}
            {/*  <Tooltip arrow title="Twitter">*/}
            {/*    <Button color="default" className="text-twitter">*/}
            {/*      <span className="btn-wrapper--icon">*/}
            {/*        <FontAwesomeIcon icon={['fab', 'twitter']} />*/}
            {/*      </span>*/}
            {/*    </Button>*/}
            {/*  </Tooltip>*/}
            {/*</ListItem>*/}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
