import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchCarouselApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/manage-carousel/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createCarouselApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('header', data.header);
  formData.append('title', data.title);
  formData.append('message', data.message);
  formData.append('image', data.image);
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-carousel/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateCarouselApi = async ({ data, onSuccess, onError }) => {
  console.log(data.image.name);
  const formData = new FormData();
  formData.append('header', data.header);
  formData.append('title', data.title);
  formData.append('message', data.message);
  if (data?.image?.name) {
    formData.append('image', data.image);
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-carousel/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteCarouselApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/manage-carousel/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const publishCarouselApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('status', data.status);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-carousel/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess(res))
    .catch(err => onError(err));
};
