import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Card,
  DialogActions
} from '@material-ui/core';

import black_logo from '../../assets/images/app_logo.png';
import login_bg from '../../assets/images/bg-cover.jpg';
import { useForm } from 'react-hook-form';

import {
  authUserRequest,
  authUserSuccess,
  setUserState,
  setUserToken
} from '../../redux/actions/authActions';
import PopupNotif2 from '../../components/notifications/popupnotif2';
import AppButton from '../../components/modal/AppButton/AppButton';

const LoginPage = () => {
  const [values, setStateValues] = useState({
    username: '',
    password: ''
  });
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [authenticatingUser, setAuthenticatingUser] = useState(false);
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const history = useHistory();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('user'));
    if (authUser) {
      history.push('/dashboard');
    }
  }, []);

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };


  const handleAuthSuccess = res => {
    localStorage.setItem(
      'user',
      JSON.stringify({
        isLoggedIn: res.data.status,
        userDetail: res.data.userDetails,
        authToken: `Token ${res.data.authToken}`
      })
    );
    history.replace('/dashboard');
  };

  const handleOnSubmit = values => {
    setAuthenticatingUser(true);
    const { username, password } = values;
    dispatch(
      authUserRequest({
        data: {
          username: username,
          password: password
        },
        onSuccess: res => {
          setAuthenticatingUser(false);
          handleAuthSuccess(res);
        },
        onError: err => {
          setAuthenticatingUser(false);
          setNotifyPopUp({
            status: true,
            popupType: 'error',
            message: 'Username and password does not match'
          });
          // const { status } = err.response;
          // switch (status) {
          //   case 400:
          //     setNotifyPopUp({
          //       status: true,
          //       popupType: 'error',
          //       message: 'Username and password does not match'
          //     });
          //     break;
          //   case 500:
          //     setNotifyPopUp({
          //       status: true,
          //       popupType: 'error',
          //       message: 'Login failed. please try again'
          //     });
          //     break;
          //   default:
          //     setNotifyPopUp({
          //       status: true,
          //       popupType: 'error',
          //       message: 'Login failed. please try again'
          //     });
          // }
        }
      })
    );
  };
  return (
    <Fragment>
      <div className="hero-wrapper bg-composed-wrapper bg-premium-dark min-vh-100">
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div
            className="bg-composed-wrapper--image opacity-5"
            style={{ backgroundImage: 'url(' + login_bg + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-3" />
          <div className="bg-composed-wrapper--bg bg-red-lights opacity-1" />
          <div className="bg-composed-wrapper--content pt-0 pb-1 py-lg-5">
            <Grid container style={{ textAlign: 'center' }}>
              <Grid item xs={12} sm={4} md={3}></Grid>
              <Grid item xs={12} sm={4} md={6} className={'text-center'}>
                <img src={black_logo} width={300} height={240} />
                <h6 className="display-2 mb-4 font-weight-normal text-white">
                  <Typography variant={'h2'}>Admin Login</Typography>
                </h6>
              </Grid>
              <Grid item xs={12} sm={4} md={3}></Grid>
            </Grid>

            <Grid container style={{ textAlign: 'center' }}>
              <Grid item xs={2} sm={4} md={5}></Grid>
              <Grid item xs={8} sm={4} md={2} className={'text-center'}>
                <Card className="p-4 mb-0">
                  <form
                    onSubmit={handleSubmit(handleOnSubmit)}
                    id="assign-gallery-link">
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            {...register('username', {
                              required: {
                                value: true,
                                message: 'Username is required'
                              }
                            })}
                            type="text"
                            name="username"
                            label="Username"
                            placeholder="Enter username"
                            error={errors.username && true}
                            onChange={e =>
                              handleChange(setStateValues, values, e)
                            }
                          />
                          <div style={{ color: 'red' }}>
                            <Typography
                              variant="caption"
                              gutterBottom
                              align="center">
                              {errors.username && errors.username.message}
                            </Typography>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                        <FormControl fullWidth>
                          <TextField
                            {...register('password', {
                              required: {
                                value: true,
                                message: 'Password is required'
                              }
                            })}
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Enter password"
                            error={errors.password && true}
                            onChange={e =>
                              handleChange(setStateValues, values, e)
                            }
                          />
                          <div style={{ color: 'red' }}>
                            <Typography
                              variant="caption"
                              gutterBottom
                              align="center">
                              {errors.password && errors.password.message}
                            </Typography>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <DialogActions style={{marginTop: 30}}>
                      <AppButton
                        fullWidth
                        type={'submit'}
                        title={'Login'}
                        loading={authenticatingUser}
                        variant={'contained'}
                        color={'secondary'}>
                        Login
                      </AppButton>
                    </DialogActions>
                  </form>
                </Card>
              </Grid>
              <Grid item xs={2} sm={4} md={5}></Grid>
            </Grid>
          </div>
        </div>
      </div>
      <PopupNotif2
        activate={notifyPopUp.status}
        messageContent={notifyPopUp.message}
        popupType={notifyPopUp.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
};

export default LoginPage;
