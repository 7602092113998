import React, { Fragment, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import PopupNotif2 from '../notifications/popupnotif2';
import CustomDialogTitle from '../modal/CustomDialogTitle/CustomDialogTitle';
import useStyles from '../modal/style';
import { AppModalFormActions } from '../modal';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadInput from '../FileUploadInput/FileUploadInput';

import { submitForm } from './helper';
import {resetForm} from "../../utils/helper";


const CreatePageImagesForm = ({
  handleClose,
  editStory,
  setEditStory,
  open
}) => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const pagesState = useSelector(state => state.PageImageReducer);
  const { loading, data } = pagesState;
  const [values, setStateValues] = useState({
    contactImage: [],
    homeImage: [],
    ServicesImage: [],
    loveStoryImage: [],
    clientGalleryImage: [],
    id: data && data.length > 0 ? data[0].id : null
  });

  const resetError = () =>
    setNotifyPopUp({
      status: false,
      message: '',
      popupType: notifyPopUp.popupType
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const handleModalClose = () => {
    if (editStory) {
      setEditStory(false);
    }
    // resetForm(reset, {
    //   contactImage: '',
    //   homeImage: '',
    //   ServicesImage: '',
    //   loveStoryImage: [],
    //   clientGalleryImage: []
    // });
    handleClose();
  };
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });

  const handleOnSubmit = () => {
    submitForm(
      data,
      setProcessingRequest,
      values,
      user,
      setNotifyPopUp,
      dispatch,
      reset,
      {
        contactImage: [],
        homeImage: [],
        ServicesImage: [],
        loveStoryImage: [],
        clientGalleryImage: []
      },
      handleModalClose,
      loading
    );
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  // handle file upload
  const handleFileUpload = e => {
    const image = e.target.files[0];
    setStateValues({
      ...values,
      pageImage: image
    });
  };

  const handleInfoImagesUpload = e => {
    // const image = e.target.files[0];
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: values.fiiles[0]
    });
  };
  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseModalAction(setStateValues, values, handleClose);
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <PopupNotif2
          activate={notifyPopUp.status}
          messageContent={notifyPopUp.message}
          popupType={notifyPopUp.popupType}
          resetError={() => resetError()}
        />

        <CustomDialogTitle
          titleText={'Manage page Images'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">
                    Upload contact us image
                  </FormLabel>
                  <FileUploadInput
                    errors={errors}
                    register={register}
                    message={'Home page image is required'}
                    canPerformActions
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={!values.homeImage.length > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FileUploadInput
                    errors={errors}
                    register={register}
                    message={'Contact info image is required'}
                    canPerformActions
                    resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={!values.contactImage.length > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">
                    Upload love story image
                  </FormLabel>
                  <FileUploadInput
                    errors={errors}
                    register={register}
                    message={'Service image page is required'}
                    canPerformActions
                    // resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={data && data.length > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">Upload client gallery image</FormLabel>
                  <FileUploadInput
                    register={register}
                    errors={errors}
                    message={'Love story page image is required'}
                    canPerformActions
                    // resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={data && data.length > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <p id="fileInfo"></p>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <FormControl>
                  <FormLabel component="legend">
                    Upload home page image
                  </FormLabel>
                  <FileUploadInput
                    errors={errors}
                    register={register}
                    message={'Home image is required'}
                    canPerformActions
                    // resetFileUpload={resetForm}
                    handleFileChange={e => {
                      handleInfoImagesUpload(e);
                    }}
                    accept=".jpg, .png, .jpeg"
                    validate={data && data.length > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={data && data.length > 0 ? 'Update' : 'Submit'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CreatePageImagesForm;
