import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchContactInfoApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/manage-contact-us-info/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const createContactInfoApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('address', data.address);
  formData.append('description', data.description);
  return await AxiosService.post(
    `${baseUrl}/adminportal/manage-contact-us-info/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};


export const updateContactInfoApi = async ({
  data,
  onSuccess,
  onError
}) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('address', data.address);
  formData.append('description', data.description);
  return await AxiosService.patch(
    `${baseUrl}/adminportal/manage-contact-us-info/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
