import {
  createTheTeamApi,
  updateTheTeamApi
} from '../../../api/authServices/theTeam/theTeam';
import { fetchTheTeamRequest } from '../../../redux/actions/theTeamActions';
import {dispatchNotifyActions, handleResponseError, resetForm} from '../../../utils/helper';

const createTeamMember = async (
  setProcessingRequest,
  values,
  teamDetails,
  user,
  dispatch,
  reset,
  formFields,
  handleModalClose
) => {
  setProcessingRequest(true);
  const data = {
    name: values.name,
    title: values.title,
    email: values.email,
    phone: values.phone,
    profileImage: values.profileImage,
    authToken: user.authToken
  };

  await createTheTeamApi({
    data,
    onSuccess: res => {
      dispatchNotifyActions(
        dispatch,
        'success',
        'Team member successfully created'
      );
      setProcessingRequest(false);
      dispatch(
        fetchTheTeamRequest({
          authToken: user.authToken
        })
      );
      resetForm(reset, formFields);
      handleModalClose();
    },
    onError: err => {
      if (err.response) {
        handleResponseError(err.response, setProcessingRequest, dispatch, [
          'image',
          'name',
          'email',
          'phone'
        ]);
      } else {
        handleTurnOffLoader(setProcessingRequest);
        dispatchNotifyActions(
          dispatch,
          'error',
          'Team member creation failed. Try again'
        );
      }
    }
  });
};

const updateTeamMember = async (
  setProcessingRequest,
  values,
  teamDetails,
  user,
  dispatch,
  setNotifyPopUp,
  handleModalClose
) => {
  setProcessingRequest(true);
  const data = {
    name: values.name,
    title: values.title,
    email: values.email,
    phone: values.phone,
    profileImage: values.profileImage,
    authToken: user.authToken,
    id: teamDetails.id
  };
  await updateTheTeamApi({
    data,
    onSuccess: res => {
      setProcessingRequest(false);
      dispatch(
        fetchTheTeamRequest({
          authToken: user.authToken
        })
      );
      dispatchNotifyActions(
        dispatch,
        'success',
        'Team member successfully updated'
      );
      handleModalClose();
    },
    onError: err => {
      if (err.response) {
        handleResponseError(
          err.response,
          setProcessingRequest,
          setNotifyPopUp,
          ['image', 'name', 'email', 'phone']
        );
      } else {
        handleTurnOffLoader(setProcessingRequest);
        setNotifyPopUp({
          status: true,
          popupType: 'error',
          message: 'Team member update failed. Try again'
        });
      }
    }
  });
};

const handleTurnOffLoader = setProcessingRequest => {
  setProcessingRequest(false);
};

export const submitForm = async (
  editTeam,
  setProcessingRequest,
  values,
  teamDetails,
  user,
  dispatch,
  handleModalClose,
  reset,
  formFields
) => {
  if (!editTeam) {
    await createTeamMember(
      setProcessingRequest,
      values,
      teamDetails,
      user,
      dispatch,
      reset,
      formFields,
      handleModalClose
    );
  } else {
    await updateTeamMember(
      setProcessingRequest,
      values,
      teamDetails,
      user,
      dispatch,
      handleModalClose
    );
  }
};
