import { FILE_UPLOAD } from '../redux/actionTypes';

const initialState = {
  image: {},
  imageUploaded: false,
  cropImageData: {}
};

export default function fileUploadedReducer(state = initialState, action) {
  switch (action.type) {
    case FILE_UPLOAD.SET_UPLOADED_IMAGE:
      return {
        ...state,
        image: action.payload.data,
        imageUploaded: true
      };
    case FILE_UPLOAD.RESET_UPLOADED_IMAGE:
      return {
        ...state,
        image: action.payload.data,
        imageUploaded: false
      };
    case FILE_UPLOAD.SET_CROP_IMAGE_DATA:
      return {
        ...state,
        cropImageData: action.payload.data
      };
    default:
      return state;
  }
}
