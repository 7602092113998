import { CAROUSEL } from '../actionTypes';
import { fetchCategoryApi } from '../../api/authServices/galleryCategory/category';
import {fetchCarouselApi} from "../../api/authServices/carousel/carousel";

export const fetchCarousel = () => ({
  type: CAROUSEL.FETCH_CAROUSEL_DATA
});

export const fetchCarouselSuccess = data => ({
  type: CAROUSEL.FETCH_CAROUSEL_SUCCESS,
  payload: {
    data
  }
});

export const fetchCarouselFail = err => ({
  type: CAROUSEL.FETCH_CAROUSEL_FAIL,
  payload: {
    err
  }
});

export const fetchCarouselRequest = data => {
  return dispatch => {
    dispatch(fetchCarousel());
    fetchCarouselApi(data)
      .then(res => {
        dispatch(fetchCarouselSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchCarouselFail(err));
      });
  };
};
