import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AppDeleteModal from '../../modal/AppDeleteModal/AppDeleteModal';
import PopupNotif2 from '../../notifications/popupnotif2';
import {dataFormatter, editMemberModal, toggleDeleteCodeModal, toggleEditModal, toggleViewModal} from './helper';
import {
  setTableColumn,
  setTableOptions,
  setTableOptions2
} from './tableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTheTeamApi } from '../../../api/authServices/theTeam/theTeam';
import { initRequest } from '../../../utils/helper';
import { fetchTheTeamRequest } from '../../../redux/actions/theTeamActions';
import CreateTheTeamForm from "../../theTeam/form/createTheTeamForm";
import AppViewModal from "../../modal/appViewModal";
import {Card, CardContent, Grid} from "@material-ui/core";
import {fetchContactInfoRequest} from "../../../redux/actions/contactUsInfoActions";

const ManageContactUsInfoTable = () => {
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editTeamModal, setEditTeamModal] = useState(false);
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [viewTeamModal, setViewTeamModal] = useState(false);
  const [teamDetails, setTeamDetails] = useState([]);
  const [options, setOptions] = useState({});
  const [options2, setOptions2] = useState({});
  const [editTeam, setEditTeam] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [refreshTable, setRefreshTable] = useState({
    extensionCode: null,
    pricingType: null
  });
  const authState = useSelector(state => state.AuthReducer);
  const infoState = useSelector(state => state.ContactUsInfoReducer);
  const { loading, data } = infoState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchContactInfoRequest);
  }, []);

  useEffect(() => {
    dataFormatter(data, setTableData);
  }, [data]);

  useEffect(() => {
    setTableColumn(
      setColumns,
      setTeamDetails,
      setEditTeamModal,
      editTeamModal,
      setEditTeam,
      setDeleteCodeModal,
      deleteCodeModal,
      setViewTeamModal,
      viewTeamModal
    );
  }, []);

  useEffect(() => {
    setTableOptions(setOptions, loading, editTeam, setRefreshTable);

    setTableOptions2(setOptions2, editTeam, setRefreshTable);
  }, []);

  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };

  // Delete gallery link
  const deleteTheTeam = async () => {
    setProcessingRequest(true);
    const data = {
      id: teamDetails.id,
      authToken: user.authToken
    };
    const res = await deleteTheTeamApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        dispatch(
          fetchTheTeamRequest({
            authToken: user.authToken
          })
        );
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Team member successfully deleted'
        });

        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Team member deletion failed'
        });
      }
    });
  };

  const toggleEditModal = () => {
    editMemberModal(setEditTeamModal, editTeamModal);
  };

  return (
    <div>
      {loading ? (
        <MUIDataTable data={[]} columns={columns} options={options2} />
      ) : (
        <MUIDataTable data={tableData} columns={columns} options={options} />
      )}
      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete team member"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete team member ${teamDetails.name}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteTheTeam();
        }} // action to delete item
      />

      {viewTeamModal && (
        <AppViewModal
          modalSize={'xs'}
          title={`${teamDetails.name} details`}
          children={
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={12}>
                <Card className="mb-4">
                  <img
                    alt={'image'}
                    className="card-img-top"
                    src={teamDetails.image}
                  />
                </Card>
              </Grid>
            </Grid>
          }
          open={viewTeamModal}
          handleClose={() => {
            toggleViewModal(setViewTeamModal, viewTeamModal);
          }}
        />
      )}

      {editTeam && (
        <CreateTheTeamForm
          handleClose={() => toggleEditModal()}
          editTeam={editTeam}
          open={editTeamModal}
          teamDetails={teamDetails}
          setEditTeam={setEditTeam}
        />
      )}
      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </div>
  );
};

export default ManageContactUsInfoTable;
