import React, {Fragment, useCallback, useEffect, useState} from 'react';
import 'react-slideshow-image/dist/styles.css';
import Gallery from 'react-photo-gallery';
import { mediaBaseUrl } from '../../../api/media_baseurl';
import Carousel, { Modal, ModalGateway } from 'react-images';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Photo from './photos';
import {ButtonGroup, Button, Tooltip, Checkbox} from '@material-ui/core';
import { Sort, RemoveRedEye, Delete } from '@material-ui/icons';
import SelectedImage from './selectedImage';
import {deleteModalFromTable, toggleDeleteCodeModal} from "../../../components/Tables/photo-gallery/helper";
import AppDeleteModal from "../../../components/modal/AppDeleteModal/AppDeleteModal";
import {deleteMultipleCategoryGalleryApi} from "../../../api/authServices/manageGalleries/galleries";
import { dispatchNotifyActions } from '../../../utils/helper';
import {fetchClientPhotosRequest} from "../../../redux/actions/clientPhotosActions";
import {fetchLoveStoryGalleryRequest} from "../../../redux/actions/loveStoryGalleryActions";
import {fetchCategoryGalleryRequest} from "../../../redux/actions/galleryActions";

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement(item => (
  <Photo {...item} />
));
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery photos={items} onClick={() => {'hi'}} renderImage={props => <SortablePhoto {...props} />} />
));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Slideshow = ({
  images,
  switchAction,
  setSwitchAction,
  type,
  fetchGallery,
  id,
  authToken,
  dispatch
}) => {
  const [imageGallery, setImageGallery] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  // image selection code
  const [selectAll, setSelectAll] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [photos, setPhotos] = useState([]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => {
      return (
        <SelectedImage
          selected={selectAll}
          key={key}
          margin={'2px'}
          index={index}
          photo={photo}
          left={left}
          top={top}
          confirmDelete={confirmDelete}
        />
      );
    },
    [selectAll]
  );
  // image selection code ends here

  useEffect(() => {
    gallery();
  }, []);
  const myGallery = [];
  console.log(images)
  const gallery = () => {
    if (images && images.length > 0) {
      console.log(imageGallery)
      images.map(image => {
        myGallery.push({
          id: image.id,
          src: `${mediaBaseUrl}${image.land_scape_img}`,
          width: image.img_width,
          height: image.img_height,
          time_updated: image.time_updated
        });
      });
    }
    setItems(myGallery);
    setImageGallery(myGallery);
    // return myGallery;
  };
  const toggleDeleteModal = () => {
    setDeletePhoto(!deletePhoto);
  };
  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  };
  const getSelectedPhotoIds = () => {
    const selectedItems = [];
    // eslint-disable-next-line no-unused-vars
    if (selectAll) {
      for (let i = 0; i < imageGallery.length; i++) {
        selectedItems.push(imageGallery[i].id);
      }
      return selectedItems;
    }
  };
  const checkModelType = () => {
    switch (type) {
      case 'client-gallery':
        dispatch(
          fetchClientPhotosRequest({
            id,
            authToken: authToken
          })
        );
        break;
      case 'love-story':
        dispatch(
          fetchLoveStoryGalleryRequest({
            id,
            authToken: authToken
          })
        );
        break;
      case 'service':
        dispatch(
          fetchCategoryGalleryRequest({
            id,
            authToken: authToken
          })
        );
        break;
    }
  };
  const handleDeletePhoto = async () => {
    setProcessingRequest(true);
    const selectedItems = getSelectedPhotoIds();
    const data = {
      id: selectedItems,
      type: type,
      authToken: authToken
    };
    const res = await deleteMultipleCategoryGalleryApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        checkModelType();
        dispatchNotifyActions(
          dispatch,
          'success',
          'Gallery successfully deleted'
        );
        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        console.log(err);
        setProcessingRequest(false);
        dispatchNotifyActions(dispatch, 'error', 'Gallery deletion failed');
      }
    });
    console.log(selectedItems);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const sortImageAndSave = (sort) => {
    const oldDataIndex = {
      id: imageGallery[sort.oldIndex].id,
      time_updated: imageGallery[sort.oldIndex].time_updated
    }
    const newDataIndex = {

    }
  }
  return (
    <Fragment>
      <div>
        <ButtonGroup className={'text-right'}>
          <Button
            onClick={() => {
              setSwitchAction('view');
            }}
            color={switchAction === 'view' ? 'primary' : ''}>
            <Tooltip title={'View'}>
              <RemoveRedEye />
            </Tooltip>
          </Button>
          <Button
            onClick={() => {
              setSwitchAction('sort');
            }}
            color={switchAction === 'sort' ? 'primary' : ''}>
            <Tooltip title={'Sort'}>
              <Sort />
            </Tooltip>
          </Button>
          <Button
            onClick={() => {
              setSwitchAction('delete');
            }}
            color={switchAction === 'delete' ? 'primary' : ''}>
            <Tooltip title={'Delete'}>
              <Delete />
            </Tooltip>
          </Button>
        </ButtonGroup>
        <br />
        {/*<Divider />*/}
        <br />
        {switchAction === 'sort' && (
          <>
            {/*<h2>Sortable Gallery</h2>*/}
            <h5>Drag photo to rearrange</h5>
            <SortableGallery
              items={items}
              onSortEnd={onSortEnd}
              onSortOver={(sort, event) => {
                console.log(imageGallery[sort.newIndex]);
                console.log(imageGallery[sort.oldIndex]);

              }}
              axis={'xy'}
            />
          </>
        )}
        {switchAction === 'view' && (
          <>
            <h5>View photos</h5>
            <Gallery photos={imageGallery} onClick={openLightbox} />
          </>
        )}
        {switchAction === 'delete' && (
          <>
            <h5>
              Select photos to delete{' '}
              <Tooltip title={'Select All'}>
                <Checkbox onClick={toggleSelectAll} {...label} />
              </Tooltip>
            </h5>
            {selectAll && (
              <Tooltip
                title={'Confirm delete'}
                onClick={() => {
                  setDeletePhoto(true);
                }}>
                <Delete style={{ color: 'red', cursor: 'grab'}} />
              </Tooltip>
            )}
            <Gallery
              photos={imageGallery}
              onClick={openLightbox}
              renderImage={imageRenderer}
            />
          </>
        )}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={imageGallery.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <AppDeleteModal
        open={deletePhoto}
        title="Delete Photo"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete photo(s)`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={handleDeletePhoto} // action to delete item
      />
    </Fragment>
  );
};

export default Slideshow;
