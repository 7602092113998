import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon
};

export default [
  {
    label: 'Navigation menu',
    content: JSON.parse(
      `[
  {
    "label": "Dashboards",
    "icon": "DashboardTwoToneIcon",
    "content": [
      {
        "label": "Dashboard",
        "description": "This is a dashboard page example built using this template.",
        "to": "/dashboard"
      }
    ]
  },
  {
    "label": "Page images & contents",
    "icon": "SettingsIcon",
    "content": [
        {
        "label": "Page images ",
        "description": "Manage home page",
        "to": "/manage-page-images"
      },
      {
        "label": "About page contents",
        "description": "Manage about page",
        "to": "/About"
      },
       {
        "label": "Home page gallery",
        "description": "Manage about page",
        "to": "/manage-home-gallery"
      }
    ]
  },
     {
    "label": "Carousel",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Images",
        "description": "Manage about page",
        "to": "/manage-carousel-images"
      }
    ]
  },
    {
    "label": "Portfolio",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Manage portfolio",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/portfolio"
      }
    ]
  },
      {
    "label": "Love story",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Love story",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/Love-stories"
      }
    ]
  },
      {
    "label": "Gallery",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Client",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/Client-gallery"
      }
    ]
  },
  {
    "label": "The team",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Manage team",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/the-team"
      }
    ]
  },
    {
    "label": "User Enquiries",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Enquiries",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/User-enquiries"
      }
    ]
  },
    {
    "label": "Contact-us Info",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Manage Contact Info",
        "description": "Wide selection of cards with multiple styles, borders, actions and hover effects.",
        "to": "/Contact-us-info"
      }
    ]
  },
   {
    "label": "Users",
    "icon": "ErrorIcon",
    "content": [
      {
        "label": "Manage users",
        "description": "",
        "to": "/Manage-users"
      }
    ]
  },
     {
    "label": "Admin",
    "icon": "ErrorIcon",
    "content": [
      {
        "label": "Manage admins",
        "description": "",
        "to": "/Manage-admins"
      }
    ]
  },
  {
    "label": "Messages",
    "icon": "ErrorIcon",
    "content": [
      {
        "label": "Emails",
        "description": "",
        "to": "/Manage-emails"
      }
    ]
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
