import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSeamless } from '../../layout-components';
import ManageEmailTable from '../../components/Tables/messages/emails/manageEmailTable';
import SendEmailForm from '../../components/Tables/messages/emails/forms/sendEmails';

export default function ManageEmails() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage Emails"
        // titleDescription="Tables are the backbone of almost all web applications."
        createObj={true}
        handleOpen={handleOpen}
        toolTipTitle={'Send Emails'}
      />

      <ExampleWrapperSeamless sectionHeading="">
        <ManageEmailTable />
      </ExampleWrapperSeamless>
      {open && <SendEmailForm open={open} handleClose={handleClose} />}
    </Fragment>
  );
}
