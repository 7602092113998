import { LOVE_STORY } from '../actionTypes';
import { fetchLoveStoryApi } from '../../api/authServices/loveStories/loveStories';

//Get all gallery links
export const fetchLoveStory = () => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_DATA
});

export const fetchLoveStorySuccess = data => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_SUCCESS,
  payload: {
    data
  }
});

export const fetchLoveStoryFail = err => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_FAIL,
  payload: {
    err
  }
});

export const fetchLoveStoryRequest = data => {
  return dispatch => {
    dispatch(fetchLoveStory());
    fetchLoveStoryApi(data)
      .then(res => {
        dispatch(fetchLoveStorySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchLoveStoryFail(err.message));
      });
  };
};
