import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import ManageTheTeamTable from "../../components/Tables/theTeam/manageTheTeamTable";
import CreateTheTeamForm from "../../components/theTeam/form/createTheTeamForm";
import ManageContactUsPageForm from "../../components/contactUs/forms/manageContactUsForm";
import ManageContactUsInfoTable from "../../components/Tables/contact-us-info/manageContactUsInfoTable";
import ManageContactUsInfoForm from "../../components/contactUs/forms/manageContactInfoForm";
import {useSelector} from "react-redux";
export default function ContactUsInfo() {
  const [open, setOpen] = React.useState(false);
  const infoState = useSelector(state => state.ContactUsInfoReducer);
  const { loading, data } = infoState;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Contact Info"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        data={data}
        handleOpen={handleOpen}
      />
      <ManageContactUsInfoTable />

      {open && <ManageContactUsInfoForm open={open} handleClose={handleClose} />}
    </Fragment>
  );
}
