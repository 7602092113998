import React from 'react';

export const dataFormatter = (data, setTableData) => {
  if (data && data.length > 0) {
    let counter = 1;
    const team = data.map(row => ({
      id: row.id,
      name: row.name,
      time_created: row.time_created,
      counter: counter++,
      phone: row.phone,
      email: row.email,
      title: row.title,
      cover_image: row.cover_image
    }));
    setTableData(team);
  } else {
    setTableData([]);
  }
};
// toggles delete modal
export const toggleDeleteCodeModal = (setDeleteCodeModal, deleteCodeModal) => {
  setDeleteCodeModal(!deleteCodeModal);
};

// toggles edit code modal
export const toggleViewModal = (setViewTeamModal, viewTeamModal) => {
  setViewTeamModal(!viewTeamModal);
};

// executes  delete modal
export const deleteModal = (
  setGalleryDetails,
  setDeleteCodeModal,
  deleteCodeModal,
  data
) => {
  setGalleryDetails(data);
  toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
};

// executes edit modal and set code data for editing
export const viewTeamMember = (
  setTeamDetails,
  setTeamModal,
  viewTeamModal,
  data
) => {
  setTeamDetails(data);
  toggleViewModal(setTeamModal, viewTeamModal);
};

export const editMemberModal = (setEditTeamModal, editTeamModal) => {
  setEditTeamModal(!editTeamModal);
};

export const editTeam = (
  setTeamDetails,
  setEditTeamModal,
  editTeamModal,
  setEditTeam,
  data
) => {
  setTeamDetails(data);
  setEditTeam(true);
  editMemberModal(setEditTeamModal, editTeamModal);
};
