import ThemeOptions from './ThemeOptions';
import GalleryLinks from './GalleryLinks';
import UserReducer from './UserReducer';
import AuthReducer from './AuthReducer';
import CategoryReducer from './CategoryReducer';
import GalleryReducer from './GalleryReducer';
import LoveStoryReducer from './LoveStoryReducer';
import ClientGalleryReducer from './ClientGalleryReducer';
import LoveStoryGalleryReducer from './LoveStoryGalleryReducer';
import TheTeamReducer from './TheTeamReducer';
import AboutPageReducer from './AboutPageReducer';
import ContactPageReducer from './ContactUsReducer';
import ServicePageReducer from './ServicePageReducer';
import ContactUsInfoReducer from './ContactUsInfoReducer';
import UserEnquiriesReducer from './UserEnquiriesReducer';
import PageImageReducer from './PageImageReducer';
import AdminReducer from './AdminReducer';
import DashboardReducer from './DashboardReducer';
import ClientPhotosReducer from './ClientPhotosReducer';
import FileUploadReducer from './FileUploadReducer';
import NotificationsReducer from './NotificationsReducer';
import CarouselReducer from './CarouselReducer';
import HomePageGalleryReducer from './HomeGalleryReducer';

export default {
  ThemeOptions,
  GalleryLinks,
  AuthReducer,
  UserReducer,
  CategoryReducer,
  GalleryReducer,
  LoveStoryReducer,
  ClientGalleryReducer,
  LoveStoryGalleryReducer,
  TheTeamReducer,
  AboutPageReducer,
  ContactPageReducer,
  ServicePageReducer,
  ContactUsInfoReducer,
  UserEnquiriesReducer,
  PageImageReducer,
  AdminReducer,
  DashboardReducer,
  ClientPhotosReducer,
  FileUploadReducer,
  NotificationsReducer,
  CarouselReducer,
  HomePageGalleryReducer
};
