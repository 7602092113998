import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Card, Button, Tooltip, ButtonGroup } from '@material-ui/core';
import CategoryDetails from '../../components/photo-gallery/details';
import AppViewModal from '../../components/modal/appViewModal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDeleteCodeModal } from '../Tables/photo-gallery/helper';

import AppDeleteModal from '../../components/modal/AppDeleteModal/AppDeleteModal';
import PopupNotif2 from '../../components/notifications/popupnotif2';
import { initRequest, publishGallery } from '../../utils/helper';
import AppLoader from '../../components/loader/loader';
import NoDataFound from '../../components/common/DataNotFound';
import Img from 'react-cool-img';
import imgPlaceholder from '../../assets/images/aaa.png';

import PublishButton from '../../components/publish/publishButton';
import AppConfirmModal from '../../components/modal/confirmActionModal';
import { editModal } from '../Tables/admin/helper';
import CreateCarouselImageForm from '../../components/carousel/forms/createCarouselImagesForms';
import { fetchCarouselRequest } from '../../redux/actions/carouselActions';
import {
  deleteCarouselApi,
  publishCarouselApi
} from '../../api/authServices/carousel/carousel';
import SingleCategoryDetail from '../photo-gallery/details/singleDetail';
import {fetchHomeImageGalleryRequest} from "../../redux/actions/homeGalleryActions";
import {deleteGalleryImageApi, publishGalleryImageApi} from "../../api/authServices/galleryImages/galleryImage";
import CreateGalleryImageForm from "./galleryImagesForm";

export default function HomePageGalleryImageList({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editCarousel, setEdeitCarousel] = useState(false);
  const [carouselDetails, setCarouselDetails] = useState({
    title: '',
    message: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const galleryState = useSelector(state => state.HomePageGalleryReducer);
  const { loading, data } = galleryState;
  const [confirmModal, setConfirmModal] = useState(false);
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchHomeImageGalleryRequest);
  }, [dispatch]);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenViewDetails = () => {
    setViewDetails(true);
  };

  const handleCloseViewDetails = () => {
    setViewDetails(false);
  };
  const handleEditModalClose = () => {
    setEditCategoryModal(false);
  };

  const handleOpenManage = () => {
    setOpenManage(true);
  };

  const handleCloseManage = () => {
    setOpenManage(false);
  };

  const routeChange = (categoryId, categoryName, description) => {
    history.push({
      pathname: 'ManageCategory',
      state: {
        data: {
          id: categoryId,
          name: categoryName,
          description: description
        }
      }
    });
  };

  const viewGallery = (id, title) => {
    history.push({
      pathname: '/view-gallery',
      search: `?service=${title}`,
      state: {
        data: {
          id,
          title,
          type: 'service'
        }
      }
    });
  };
  const toggleDeleteModal = () => {
    toggleDeleteCodeModal(setDeleteCodeModal, deleteCodeModal);
  };
  const toggleEditModal = () => {
    setEdeitCarousel(true);
    editModal(setEditCategoryModal, editCategoryModal);
  };

  // Delete gallery link
  const deleteCategory = async id => {
    setProcessingRequest(true);
    const data = {
      id: id,
      authToken: user.authToken
    };
    const res = await deleteGalleryImageApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        // const newData = data.filter(row => row.id !== galleryLinkDetails.id);
        setErrorPopUp({
          status: true,
          popupType: 'success',
          message: 'Gallery image successfully deleted'
        });
        dispatch(
          fetchHomeImageGalleryRequest({
            authToken: user.authToken
          })
        );
        // setTableData(newData);
        toggleDeleteModal();
      },
      onError: err => {
        setProcessingRequest(true);
        setErrorPopUp({
          status: true,
          popupType: 'error',
          message: 'Gallery image deletion failed'
        });
      }
    });
  };
  const handleNoGalleryForSelectedCategory = data => {
    if (data.length === 0) {
      alert('data length is zero');
      setErrorPopUp({
        status: true,
        popupType: 'info',
        message: `No gallery found for ${carouselDetails.title}`
      });
    } else {
    }
    handleCloseViewDetails();
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const togglePublishProcess = carousel => {
    setCarouselDetails({
      ...carouselDetails,
      id: carousel.id,
      title: carousel.title,
      status: carousel.status
    });
    toggleConfirmModal();
  };
  const publishCarousel = () => {
    publishGallery(
      setProcessingRequest,
      carouselDetails,
      user.authToken,
      setErrorPopUp,
      publishGalleryImageApi,
      toggleConfirmModal,
      fetchHomeImageGalleryRequest,
      dispatch,
      `Gallery image successfully ${
        carouselDetails.status ? 'unpublished' : 'publish'
      }`,
      `Gallery image ${
        carouselDetails.status ? 'unpublished' : 'publish'
      } failed`
    );
  };
  return (
    <Fragment>
      {!loading ? (
        <Grid container spacing={1}>
          {data &&
            data.length > 0 &&
            data.map((carousel, index) => {
              return (
                <Grid item xs={12} sm={4} md={4} key={index}>
                  <Card className="card-transparent mb-2">
                    <div className="card-img-wrapper">
                      <Tooltip
                        title={''}
                        onClick={() => {
                          // viewGallery(carousel.id, carousel.title);
                          setCarouselDetails({
                            ...carouselDetails,
                            image: carousel.cover_image,
                            message: carousel.name,
                            id: carousel.id
                          });
                          handleOpenManage();
                        }}>
                        {/*<ResizeImage*/}
                        {/*  src={category.cover_image}*/}
                        {/*  alt="Tsunami bt hokusai"*/}
                        {/*  options={{ width: 200 }}*/}
                        {/*/>*/}
                        <Img
                          style={{ cursor: 'grab' }}
                          src={carousel.cover_image}
                          className="card-img-top rounded"
                          alt="..."
                          placeholder={imgPlaceholder}
                          cache // Default is true, just for demo
                        />
                      </Tooltip>
                    </div>
                    <div className="card-body text-left">
                      {/*<p className="card-text">{carousel.title}</p>*/}
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group">
                        <Button
                          onClick={() => {
                            setCarouselDetails({
                              ...carouselDetails,
                              title: carousel.title,
                              message: carousel.message,
                              id: carousel.id
                            });
                            toggleEditModal(
                              carousel.id
                            );
                          }}>
                          {/*<Tooltip title={'Edit'}>*/}
                          {/*  <Edit color={'secondary'} />*/}
                          {/*</Tooltip>*/}
                          Edit
                        </Button>
                        <Button
                          // className={'text-danger'}
                          onClick={() => {
                            setCarouselDetails({
                              ...carouselDetails,
                              title: carousel.name,
                              id: carousel.id
                            });
                            toggleDeleteModal();
                          }}>
                          {/*<Tooltip title={'Delete'}>*/}
                          {/*  <Delete style={{ color: 'red' }} />*/}
                          {/*</Tooltip>*/}
                          Delete
                        </Button>
                        <PublishButton
                          status={carousel.status}
                          toggleModal={() => togglePublishProcess(carousel)}
                        />
                      </ButtonGroup>
                    </div>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}

      {!loading && <NoDataFound data={data} />}

      {/*VIEW CATEGORY GALLERY*/}
      {open && (
        <CategoryDetails
          categoryDetails={carouselDetails}
          handleNoGalleryForSelectedCategory={
            handleNoGalleryForSelectedCategory
          }
          open={open}
          handleClose={handleClose}
        />
      )}

      {openManage && (
        <AppViewModal
          modalSize={'lg'}
          title={carouselDetails.message}
          children={
            <SingleCategoryDetail
              carousel={true}
              image={carouselDetails.image}
            />
          }
          open={openManage}
          handleClose={handleCloseManage}
        />
      )}
      {/*<AppViewModal*/}
      {/*  modalSize={'md'}*/}
      {/*  title={'Manage Category'}*/}
      {/*  children={<ManageCategories />}*/}
      {/*  open={openManage}*/}
      {/*  handleClose={handleCloseManage}*/}
      {/*/>*/}
      {editCategoryModal && (
        <CreateGalleryImageForm
          open={editCategoryModal}
          handleClose={handleEditModalClose}
          carouselDetails={carouselDetails}
          editCarousel={editCarousel}
          setEditCarousel={setEdeitCarousel}
        />
      )}

      <AppDeleteModal
        open={deleteCodeModal}
        title="Delete gallery image"
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
        toggleModal={() => {
          toggleDeleteModal();
        }}
        heading={`You are about to delete gallery image ${carouselDetails.name}`}
        content={<p> Are you sure you want to delete?</p>}
        loading={processingRequest}
        confirmAction={() => {
          deleteCategory(carouselDetails.id);
        }} // action to delete item
      />
      <AppConfirmModal
        open={confirmModal}
        title={`Confirm ${carouselDetails.status ? 'unpublished' : 'publish'}`}
        cancelText="No, Cancel"
        confirmText="Yes, Confirm"
        toggleModal={() => {
          toggleConfirmModal();
        }}
        heading={`You are about to ${
          carouselDetails.status ? 'unpublished' : 'publish'
        } gallery image ${carouselDetails.name}`}
        content={
          <p>
            {' '}
            Are you sure you want to{' '}
            {`${carouselDetails.status ? 'unpublished' : 'publish'}`}?
          </p>
        }
        loading={processingRequest}
        confirmAction={() => {
          publishCarousel();
        }} // action to delete item
      />
      <PopupNotif2
        activate={errorPopUp.status}
        messageContent={errorPopUp.message}
        popupType={errorPopUp.popupType}
        resetError={() => resetError()}
      />
    </Fragment>
  );
}
