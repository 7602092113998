import {
  createCategoryApi,
  updateCategoryApi
} from '../../../api/authServices/galleryCategory/category';
import { fetchCategoriesRequest } from '../../../redux/actions/categoriesActions';
import { handleResponseError } from '../../../utils/helper';

const updateServicePage = async (
  setProcessingRequest,
  values,
  dispatch,
  setNotifyPopUp,
  handleModalClose,
  user
) => {
  setProcessingRequest(true);
  const data = {
    name: values.name,
    description: values.description,
    image: values.image,
    id: values.id,
    authToken: user.authToken
  };
  await updateCategoryApi({
    data,
    onSuccess: res => {
      setProcessingRequest(false);
      dispatch(
        fetchCategoriesRequest({
          authToken: user.authToken
        })
      );
      setNotifyPopUp({
        status: true,
        popupType: 'success',
        message: 'Gallery category successfully updated'
      });
      setTimeout(() => {
        handleModalClose();
      }, 3000);
    },
    onError: err => {
      if (err.response) {
        handleResponseError(
          err.response,
          setProcessingRequest,
          setNotifyPopUp,
          ['image']
        );
      } else {
        setProcessingRequest(false);
        setNotifyPopUp({
          status: true,
          popupType: 'error',
          message: 'Service page update failed. Try again'
        });
      }
    }
  });
};

const createServicePage = async (
  setProcessingRequest,
  values,
  dispatch,
  setNotifyPopUp,
  handleModalClose,
  user
) => {
  setProcessingRequest(true);
  const data = {
    name: values.name,
    description: values.description,
    image: values.image,
    authToken: user.authToken
  };

  await createCategoryApi({
    data,
    onSuccess: res => {
      setNotifyPopUp({
        status: true,
        popupType: 'success',
        message: 'Service page successfully created'
      });
      setProcessingRequest(false);
      dispatch(
        fetchCategoriesRequest({
          authToken: user.authToken
        })
      );
      // resetForm();
    },
    onError: err => {
      if (err.response) {
        handleResponseError(
          err.response,
          setProcessingRequest,
          setNotifyPopUp,
          ['image']
        );
      } else {
        setProcessingRequest(false);
        setNotifyPopUp({
          status: true,
          popupType: 'error',
          message: 'Service page creation failed. Try again'
        });
      }
    }
  });
};

export const submitServiceForm = async (
  setProcessingRequest,
  values,
  serviceDetail,
  user,
  dispatch,
  setNotifyPopUp,
  handleModalClose
) => {
  if (!serviceDetail.name) {
    await createServicePage(
      setProcessingRequest,
      values,
      dispatch,
      setNotifyPopUp,
      handleModalClose,
      user
    );
  } else {
    await updateServicePage(
      setProcessingRequest,
      values,
      dispatch,
      setNotifyPopUp,
      handleModalClose,
      user,
      handleModalClose
    );
  }
};
