import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Card, Button, Tooltip } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { initRequest } from '../../utils/helper';
import AppLoader from '../../components/loader/loader';
import NoDataFound from '../../components/common/DataNotFound';

import { fetchPageImageRequest } from '../../redux/actions/pageImagesActions';
import AppButtonGroup from '../AppButtonGroup/AppButtonGroup';
import PublishButton from '../publish/publishButton';
import {PageTitle} from "../../layout-components";
import Img from 'react-cool-img';

export default function PageImageList({ handleOpenModal }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [errorPopUp, setErrorPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
    setErrorPopUp({ status: false, message: '', popupType: 'success' });
  const [deleteCodeModal, setDeleteCodeModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [pages, setPages] = useState([
    { id: 1, name: 'Contact us' },
    { id: 2, name: 'Home' },
    { id: 3, name: 'Client gallery' },
    { id: 4, name: 'Love story' },
    { id: 5, name: 'Services' },
    { id: 6, name: 'Blog' },
    { id: 7, name: 'About us' }
  ]);
  const [pageDetails, setPageDetails] = useState({
    name: '',
    id: null
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const authState = useSelector(state => state.AuthReducer);
  const pageimageState = useSelector(state => state.PageImageReducer);
  const { loading, data } = pageimageState;
  const { user } = authState;
  const dispatch = useDispatch();

  useEffect(() => {
    initRequest(dispatch, fetchPageImageRequest);
  }, [dispatch]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenViewDetails = () => {
    setViewDetails(true);
  };

  const handleCloseViewDetails = () => {
    setViewDetails(false);
  };
  const handleEditModalClose = () => {
    setEditCategoryModal(false);
  };

  const handleOpenManage = () => {
    setOpenManage(true);
  };

  const handleCloseManage = () => {
    setOpenManage(false);
  };

  const getPageName = page => {
    if (data && data.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].id === page) {
          return pages[i].name;
        }
      }
    }
  };

  const routeChange = (categoryId, categoryName, description) => {
    history.push({
      pathname: 'ManageCategory',
      state: {
        data: {
          id: categoryId,
          name: categoryName,
          description: description
        }
      }
    });
  };
  // const toggleEditModal = () => {
  //   setEditCategory(true);
  //   editModal(setEditCategoryModal, editCategoryModal);
  // };
  const handleNoGalleryForSelectedCategory = data => {
    if (data.length === 0) {
      setErrorPopUp({
        status: true,
        popupType: 'info',
        message: `No gallery found for ${pageDetails.name}`
      });
    } else {
      alert(JSON.stringify(data));
    }
    handleCloseViewDetails();
  };

  const toggleEditModal = (name, id) => {
    setPageDetails({
      ...pageDetails,
      name,
      id
    });
    setEditPage(true);
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage page images"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      {!loading ? (
        <Grid container spacing={1}>
          {data &&
            data.length > 0 &&
            data.map((page, index) => (
              <Grid item xs={12} sm={6} md={4}>
                <Card className="card-transparent mb-4">
                  <div className="card-img-wrapper">
                    <Tooltip
                      title={''}
                      onClick={() => {
                        alert('hi');
                        // setCategoryDetails({
                        //   ...categoryDetails,
                        //   name: category.name,
                        //   description: category.description,
                        //   id: category.id
                        // });
                        // handleOpen();
                      }}>
                      <Img
                        style={{ cursor: 'grab' }}
                        src={page.image}
                        className="card-img-top rounded"
                        alt="..."
                      />
                    </Tooltip>
                  </div>
                  {/*<div className="card-body text-left">*/}
                  {/*  <h5 className="card-title font-weight-bold font-size-md">*/}
                  {/*    {getPageName(page.page).toUpperCase()}*/}
                  {/*  </h5>*/}
                  {/*  /!*<p className="card-text">{category.description}</p>*!/*/}
                  {/*</div>*/}

                  <AppButtonGroup name={getPageName(page.page).toUpperCase()}>
                    <Button onClick={() => {}}>Edit</Button>
                    <Button onClick={() => {}}>Delete</Button>
                    <PublishButton
                    // status={category.status}
                    // toggleModal={() => togglePublishProcess(category)}
                    />
                  </AppButtonGroup>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <AppLoader loading={loading} />
      )}

      {!loading && <NoDataFound data={data} />}
      {/*VIEW CATEGORY GALLERY*/}
      {/*{open && (*/}
      {/*  <CategoryDetails*/}
      {/*    categoryDetails={categoryDetails}*/}
      {/*    handleNoGalleryForSelectedCategory={*/}
      {/*      handleNoGalleryForSelectedCategory*/}
      {/*    }*/}
      {/*    open={open}*/}
      {/*    handleClose={handleClose}*/}
      {/*  />*/}
      {/*)}*/}
    </Fragment>
  );
}
