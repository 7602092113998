import React, { Fragment } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';

const AppLoader = ({ loading }) => {
  return (
    <Fragment>
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress thickness={5} size={30} style={{ margin: 15 }} />
          <p>Loading...</p>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default AppLoader;
