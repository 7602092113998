export const AUTH = {
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_MAX_TIME_OUT: 'SET_MAX_TIME_OUT',
  IS_USER_LOGGED_IN: 'IS_USER_LOGGED_IN',
  AUTH_USER: 'AUTH_USER',
  AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS',
  AUTH_USER_FAIL: 'AUTH_USER_FAIL',
  LOGOUT_USER: 'LOGOUT_USER',
  RECOVER_AUTH: 'RECOVER_AUTH',
  FETCH_UPDATED_USER: 'FETCH_UPDATED_USER',
  STORE_LOGIN_USER: 'STORE_LOGIN_USER'
};
// export const NOTIFICATIONS = {
//   FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
//   FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
//   FETCH_NOTIFICATIONS_FAIL: 'FETCH_NOTIFICATIONS_FAIL'
// };
export const TRANSACTION = {
  FETCH_TRANSACTION: 'FETCH_TRANSACTION',
  FETCH_TRANSACTION_SUCCESS: 'FETCH_TRANSACTION_SUCCESS',
  FETCH_TRANSACTION_FAIL: 'FETCH_TRANSACTION_FAIL'
};
export const PENDING_APPROVALS = {
  FETCH_APPROVAL: 'FETCH_APPROVAL',
  FETCH_APPROVALS_SUCCESS: 'FETCH_APPROVALS_SUCCESS',
  FETCH_APPROVALS_FAIL: 'FETCH_APPROVALS_FAIL',
  CANCEL_APPROVAL: 'CANCEL_APPROVAL',
  APPROVE: 'APPROVE',
  SET_VOUCHER_HISTORY_PARAMETERS: 'SET_VOUCHER_HISTORY_PARAMETERS'
};
export const VOUCHER_HISTORY = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_APPROVALS_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_APPROVALS_FAIL'
};
export const SUCCESS = {
  SET_MESSAGE: 'SET_MESSAGE'
};
export const FUND_WALLET = {
  FUND: 'FUND',
  FUND_SUCCESS: 'FUND_SUCCESS',
  FUND_FAIL: 'FUND_FAIL',
  FETCH_DATA: 'FETCH_DATA',
  FETCH_WALLET_NETWORK: 'FUND_WALLET_NETWORK',
  SET_WALLET_NETWORK_NAME: 'SET_WALLET_NETWORK_NAME',
  SET_WALLET_TYPE_NAME: 'SET_WALLET_TYPE_NAME',
  SET_FUND_WALLET_FORM_DATA: 'SET_FUND_WALLET_FORM_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_NETWORK: 'SET_NETWORK',
  SET_PHONE: 'SET_PHONE',
  SET_AMOUNT: 'SET_AMOUNT',
  RECOVER_FUND_WALLET_FORM_DATA: 'RECOVER_FUND_WALLET_FORM_DATA'
};
export const SEARCH = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};
export const PROVIDER = {
  FETCH_PROVIDER_DATA: 'FETCH_PROVIDER_DATA',
  FETCH_PROVIDER_DATA_SUCCESS: 'FETCH_PROVIDER_DATA_SUCCESS',
  FETCH_PROVIDER_DATA_FAIL: 'FETCH_PROVIDER_DATA_FAIL'
};
export const OFFER = {
  FETCH_OFFERS: 'FETCH_OFFERS',
  FETCH_OFFERS_SUCCESS: 'FETCH_OFFERS_SUCCESS',
  FETCH_OFFERS_FAIL: 'FETCH_OFFERS_FAIL'
};

export const PACKAGE = {
  FETCH_VOUCHER_DATA: 'FETCH_VOUCHER_DATA',
  FETCH_VOUCHER_DATA_SUCCESS: 'FETCH_VOUCHER_DATA_SUCCESS',
  FETCH_VOUCHER_DATA_FAIL: 'FETCH_VOUCHER_DATA_FAIL',
  FETCH_CATEGORY_SEARCH_DATA_SUCCESS: 'FETCH_CATEGORY_SEARCH_DATA_SUCCESS',
  FETCH_CATEGORY_SEARCH_DATA_FAIL: 'FETCH_CATEGORY_SEARCH_DATA_FAIL',
  FETCH_CATEGORY_SEARCH_DATA: 'FETCH_CATEGORY_SEARCH_DATA',
  FETCH_SEARCHED_PRODUCTS: 'FETCH_SEARCHED_PRODUCTS',
  SET_PACKAGE_DETAILS_FOR_SEARCH_PACKAGE:
    'SET_PACKAGE_DETAILS_FOR_SEARCH_PACKAGE',
  SEARCH_FOR_PACKAGE: 'SEARCH_FOR_PACKAGE',
  SEARCH_FOR_PACKAGE_SUCCESS: 'SEARCH_FOR_PACKAGE_SUCCESS',
  SEARCH_FOR_PACKAGE_FAIL: 'SEARCH_FOR_PACKAGE_FAIL',
  FETCH_CLIENT_DETAILS: 'FETCH_CLIENT_DETAILS',
  FETCH_CLIENT_DETAILS_SUCCESS: 'FETCH_CLIENT_DETAILS_SUCCESS',
  FETCH_CLIENT_DETAILS_FAIL: 'FETCH_CLIENT_DETAILS_FAIL',
  SET_PACKAGE_DETAILS: 'SET_PACKAGE_DETAILS',
  SET_IS_PURCHASED: 'SET_IS_PURCHASED',
  GET_PACKAGES_PURCHASE_STATUS: 'GET_PACKAGES_PURCHASE_STATUS',
  FETCH_PACKAGES: 'FETCH_PACKAGES',
  FETCH_MY_PACKAGES: 'FETCH_MY_PACKAGES',
  FETCH_PACKAGES_SUCCESS: 'FETCH_PACKAGES_SUCCESS',
  FETCH_PACKAGES_FAIL: 'FETCH_PACKAGES_FAIL',
  FETCH_SALE_PACKAGES: 'FETCH_SALE_PACKAGES',
  FETCH_SALE_PACKAGES_SUCCESS: 'FETCH_SALE_PACKAGES_SUCCESS',
  FETCH_SALE_PACKAGES_FAIL: 'FETCH_SALE_PACKAGES_FAIL',
  FETCH_FEATURED_PACKAGES: 'FETCH_FEATURED_PACKAGES',
  FETCH_FEATURED_PACKAGES_SUCCESS: 'FETCH_FEATURED_PACKAGES_SUCCESS',
  FETCH_FEATURED_PACKAGES_FAIL: 'FETCH_FEATURED_PACKAGES_FAIL',
  FETCH_BEST_SELLER_PACKAGES: 'FETCH_BEST_SELLER_PACKAGES',
  FETCH_BEST_SELLER_PACKAGES_SUCCESS: 'FETCH_BEST_SELLER_PACKAGES_SUCCESS',
  FETCH_BEST_SELLER_PACKAGES_FAIL: 'FETCH_BEST_SELLER_PACKAGES_FAIL',
  FETCH_DALE_PACKAGES: 'FETCH_DALE_PACKAGES',
  FETCH_DALE_PACKAGES_SUCCESS: 'FETCH_DALE_PACKAGES_SUCCESS',
  FETCH_DALE_PACKAGES_FAIL: 'FETCH_DALE_PACKAGES_FAIL',
  FETCH_PACKAGES_DETAIL: 'FETCH_PACKAGES_DETAIL',
  FETCH_PACKAGES_DETAIL_SUCCESS: 'FETCH_PACKAGES_DETAIL_SUCCESS',
  FETCH_PACKAGES_DETAIL_FAIL: 'FETCH_PACKAGES_DETAIL_FAIL',
  FETCH_SEARCHED_PACKAGES: 'FETCH_SEARCHED_PACKAGES',
  FETCH_SEARCHED_PACKAGES_SUCCESS: 'FETCH_SEARCHED_PACKAGES_SUCCESS',
  FETCH_SEARCHED_PACKAGES_FAIL: 'FETCH_SEARCHED_PACKAGES_FAIL',
  FETCH_DATA: 'FETCH_DATA'
};

export const SHOP_FILTER = {
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SORT: 'SET_SORT',
  SET_SHOW: 'SET_SHOW',
  SET_VIEW: 'SET_VIEW',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_COLOR: 'SET_COLOR',
  SET_SIZE: 'SET_SIZE',
  SET_TAG: 'SET_TAG'
};

export const GALLERY_LINKS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};

export const USERS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};

export const ADMIN = {
  FETCH_ADMIN_DATA: 'FETCH_FETCH_ADMIN_DATADATA',
  FETCH_ADMIN_DATA_SUCCESS: 'FETCH_ADMIN_DATA_SUCCESS',
  FETCH_ADMIN_DATA_FAIL: 'FETCH_ADMIN_DATA_FAIL'
};

export const CATEGORY = {
  FETCH_CATEGORY_DATA: 'FETCH_CATEGORY_DATA',
  FETCH_CATEGORY_SUCCESS: 'FETCH__CATEGORY_DATA_SUCCESS',
  FETCH_CATEGORY_FAIL: 'FETCH_CATEGORY_DATA_FAIL'
};

export const GALLERY = {
  FETCH_GALLERY_DATA: 'FETCH_GALLERY_DATA',
  FETCH_GALLERY_SUCCESS: 'FETCH_GALLERY_DATA_SUCCESS',
  FETCH_GALLERY_FAIL: 'FETCH_GALLERY_DATA_FAIL',
};

export const LOVE_STORY = {
  FETCH_LOVE_STORY_DATA: 'FETCH_LOVE_STORY_DATA',
  FETCH_LOVE_STORY_SUCCESS: 'FETCH_LOVE_STORY_SUCCESS',
  FETCH_LOVE_STORY_FAIL: 'FETCH_LOVE_STORY_FAIL'
};

export const LOVE_STORY_GALLERY = {
  FETCH_LOVE_STORY_GALLERY_DATA: 'FETCH_LOVE_STORY_GALLERY_DATA',
  FETCH_LOVE_STORY_GALLERY_SUCCESS: 'FETCH_LOVE_STORY_GALLERY_SUCCESS',
  FETCH_LOVE_STORY_GALLERY_FAIL: 'FETCH_LOVE_STORY_GALLERY_FAIL'
};

export const CLIENT_GALLERY = {
  FETCH_CLIENT_GALLERY_DATA: 'FETCH_CLIENT_GALLERY_DATA',
  FETCH_CLIENT_GALLERY_SUCCESS: 'FETCH_CLIENT_GALLERY_SUCCESS',
  FETCH_CLIENT_GALLERY_FAIL: 'FETCH_CLIENT_GALLERY_FAIL'
};

export const CATEGORY_GALLERY = {
  FETCH_CATEGORY_GALLERY_DATA: 'FETCH_CATEGORY_GALLERY_DATA',
  FETCH_CATEGORY_GALLERY_SUCCESS: 'FETCH_CATEGORY_GALLERY_SUCCESS',
  FETCH_CATEGORY_GALLERY_FAIL: 'FETCH_CATEGORY_GALLERY_FAIL'
};

export const THE_TEAM = {
  FETCH_TEAM_DATA: 'FETCH_TEAM_DATA',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_FAIL: 'FETCH_TEAM_FAIL'
};

export const ABOUT_PAGE = {
  FETCH_ABOUT_DATA: 'FETCH_ABOUT_DATA',
  FETCH_ABOUT_SUCCESS: 'FETCH_ABOUT_SUCCESS',
  FETCH_ABOUT_FAIL: 'FETCH_ABOUT_FAIL'
};

export const CONTACT_US = {
  FETCH_CONTACT_DATA: 'FETCH_CONTACT_DATA',
  FETCH_CONTACT_SUCCESS: 'FETCH_CONTACT_SUCCESS',
  FETCH_CONTACT_FAIL: 'FETCH_CONTACT_FAIL'
};

export const CONTACT_US_INFO = {
  FETCH_CONTACT_INFO_DATA: 'FETCH_CONTACT_INFO_DATA',
  FETCH_CONTACT_INFO_SUCCESS: 'FETCH_CONTACT_INFO_SUCCESS',
  FETCH_CONTACT_INFO_FAIL: 'FETCH_CONTACT_INFO_FAIL'
};

export const SERVICE_PAGE = {
  FETCH_SERVICE_PAGE_DATA: 'FETCH_SERVICE_PAGE_DATA',
  FETCH_SERVICE_PAGE_SUCCESS: 'FETCH_SERVICE_SUCCESS',
  FETCH_SERVICE_PAGE_FAIL: 'FETCH_SERVICE_FAIL'
};

export const USER_ENQUERIES = {
  FETCH_ENQUERIES_DATA: 'FETCH_ENQUERIES_PAGE_DATA',
  FETCH_ENQUERIES_SUCCESS: 'FETCH_ENQUERIES_SUCCESS',
  FETCH_ENQUERIES_FAIL: 'FETCH_ENQUERIES_FAIL'
};

export const PAGE_IMAGES = {
  FETCH_PAGE_IMAGES_DATA: 'FETCH_PAGE_IMAGES_DATA',
  FETCH_PAGE_IMAGES_SUCCESS: 'FETCH_PAGE_IMAGES_SUCCESS',
  FETCH_PAGE_IMAGES_FAIL: 'FETCH_PAGE_IMAGES_FAIL'
};

export const DASHBOARD = {
  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  FETCH_DASHBOARD_FAIL: 'FETCH_DASHBOARD_FAIL'
};

export const CLIENT_PHOTOS = {
  FETCH_CLIENT_PHOTO_DATA: 'FETCH_CLIENT_PHOTO_DATA',
  FETCH_CLIENT_PHOTO_SUCCESS: 'FETCH_CLIENT_PHOTO_SUCCESS',
  FETCH_CLIENT_PHOTO_FAIL: 'FETCH_CLIENT_PHOTO_FAIL'
};

export const FILE_UPLOAD = {
  SET_UPLOADED_IMAGE: 'SET_UPLOADED_IMAGE',
  RESET_UPLOADED_IMAGE: 'RESET_UPLOADED_IMAGE',
  IMAGE_UPLOADED: 'IMAGE_UPLOADED',
  SET_CROP_IMAGE_DATA: 'SET_CROP_IMAGE_DATA'
};

export const NOTIFICATIONS = {
  SET_STATUS_AND_TYPE: 'SET_STATUS_AND_TYPE'
};
export const CAROUSEL = {
  FETCH_CAROUSEL_DATA: 'FETCH_CAROUSEL_DATA',
  FETCH_CAROUSEL_SUCCESS: 'FETCH_CAROUSEL_SUCCESS',
  FETCH_CAROUSEL_FAIL: 'FETCH_CAROUSEL_FAIL'
};
export const HOME_GALLERY = {
  FETCH_HOME_GALLERY_DATA: 'FETCH_HOME_GALLERY_DATA',
  FETCH_HOME_GALLERY_SUCCESS: 'FETCH_HOME_GALLERY_SUCCESS',
  FETCH_HOME_GALLERY_FAIL: 'FETCH_HOME_GALLERY_FAIL'
};
