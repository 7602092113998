import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchPageImagesApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/pages-images/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const fetchPageTypesApi = async data => {
  return await AxiosService.get(`${baseUrl}/adminportal/page-types/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

export const createPageImageApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('page', data.page);
  formData.append('image', data.image);
  return await AxiosService.post(
    `${baseUrl}/adminportal/pages-images/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updatePageImagesApi = async ({ data, onSuccess, onError }) => {
  alert(JSON.stringify(data));
  const formData = new FormData();
  formData.append('page', data.page);
  formData.append('image', data.image);

  if (data.image.length === 0) {
    formData.delete('image');
  }
  return await AxiosService.patch(
    `${baseUrl}/adminportal/pages-images/${data.id}/`,
    formData,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deletePageImageApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/pages-images/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
