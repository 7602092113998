import React from 'react';
import {Avatar, Tooltip} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/Delete';
import DataTableLoader from '../DataTableLoader';
import {deleteModal, editCode, editUser} from './helper';
import { Visibility} from "@material-ui/icons";

export const setTableColumn = (
  setColumns,
  userDetails,
  setEditUserModal,
  editUserModal,
  setDeleteCodeModal,
  deleteCodeModal,
  setUserDetails,
  setEditUser
) =>
  setColumns([
    {
      name: 'counter',
      label: '#',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'first_name',
      label: 'First name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'last_name',
      label: 'Last name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email Address',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'date_joined',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'is_active',
      label: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'last_login',
      label: 'Last Login',
      options: {
        filter: true,
        sort: true
      }
    },
    // {
    //   name: 'password_text',
    //   label: 'Password'
    // },
    {
      name: 'Action',
      label: <span style={{ marginLeft: 20 }}>Action</span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (e, d) => {
          const rData = {
            first_name: d.rowData[1],
            last_name: d.rowData[2],
            email: d.rowData[3],
            username: d.rowData[4],
            phone: d.rowData[5],
            id: d.rowData[10]
          };
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip
                title="View"
                onClick={() => {
                  editUser(
                    setUserDetails,
                    setEditUserModal,
                    editUserModal,
                    setEditUser,
                    rData
                  );
                  console.log(rData);
                }}
                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10 }}
              >
                <Edit color="primary" />
              </Tooltip>
              <Tooltip
                title="Delete"
                style={{ cursor: 'pointer', marginLeft: 20 }}
                onClick={() => {
                  deleteModal(
                    setUserDetails,
                    setDeleteCodeModal,
                    deleteCodeModal,
                    rData
                  );
                }}>
                <DeleteForever style={{ color: 'red', marginLeft: 10 }} />
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'period_id',
      options: {
        display: 'excluded'
      }
    }
  ]);

export const setTableOptions = (
  setOptions
) =>
  setOptions({
    filterType: 'textField',
    // selectableRowsHideCheckboxes: 'hideCheckBox',
    // selectableRowsOnClick: true,
    // selectableRows: 'multi',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'No data found'
      }
    }
  });

export const setTableOptions2 = (setOptions2) =>
  setOptions2({
    filterType: 'textField',
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    selectedColumns: false,
    viewColumns: true,
    searchPlaceholder: 'Enter item to search',
    customToolbar: () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      />
    ),
    textLabels: {
      body: {
        noMatch: <DataTableLoader message="Fetching data..." />
      }
    }
  });
