import React, { Fragment } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle/CustomDialogTitle';
import AppButton from './AppButton/AppButton';
import { AddCircle, Edit } from '@material-ui/icons';

const AppModal = ({
  open,
  handleClose,
  children,
  title,
  isEdit,
  editFormTitle,
  formTitle
}) => {
  return (
    <Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <CustomDialogTitle
          titleText={isEdit ? editFormTitle : formTitle}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />
        <DialogContent className="p-0">
          <div>{children}</div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AppModal;

export const AppModalFormActions = ({
  handleClose,
  handleSubmit,
  buttonTitle,
  processingRequest,
  editLink
}) => {
  return (
    <Fragment>
      <DialogActions>
        <AppButton
          color="default"
          variant="outlined"
          disabled={processingRequest}
          style={{ textTransform: 'capitalize' }}
          onClick={handleClose}>
          Close
        </AppButton>
        <AppButton
          color="primary"
          type="submit"
          variant="contained"
          disabled={processingRequest}
          style={{ textTransform: 'capitalize' }}
          fullWidth
          // startIcon={props.editExtension ? <Edit /> : <AddCircle style={{ paddingRight: 5 }} />}
        >
          {editLink ? <Edit /> : <AddCircle style={{ paddingRight: 5 }} />}
          {editLink ? 'Edit' : buttonTitle}
          {processingRequest && (
            <span style={{ marginLeft: 10 }}>
              <CircularProgress size={20} />{' '}
            </span>
          )}
        </AppButton>
      </DialogActions>
    </Fragment>
  );
};
