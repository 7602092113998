import React, { Fragment, useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import CustomDialogTitle from '../../components/modal/CustomDialogTitle/CustomDialogTitle';
import FileUploadInput from '../../components/FileUploadInput/FileUploadInput';
import { AppModalFormActions } from '../../components/modal';
import useStyles from '../../components/modal/style';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchNotifyActions, handleResponseError } from '../../utils/helper';
import {
  createPageImageApi,
  fetchPageTypesApi,
  updatePageImagesApi
} from '../../api/authServices/pageImages/pageImages';
import { fetchPageImageRequest } from '../../redux/actions/pageImagesActions';
import Demo from "../../components/cropper/react-cropper";

export default function CreatePageImage({
  pageDetails,
  editPage,
  open,
  handleClose,
  pageList
}) {
  // const [open, setOpen] = React.useState(false);
  const [values, setStateValues] = useState({
    page: editPage ? pageDetails.page : null,
    image: []
  });
  const [result, setResult] = useState({});

  const [processingRequest, setProcessingRequest] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [progress, setProgress] = useState();
  const [imageUploaded, setImageUploaded] = useState(false);
  const [pages, setPages] = useState([
    { id: 1, name: 'Contact us' },
    { id: 3, name: 'Client gallery' },
    { id: 4, name: 'Love story' },
    { id: 5, name: 'Portfolio' },
    { id: 6, name: 'Blog' },
    { id: 7, name: 'About us' }
  ]);

  useEffect(() => {
    fetchPageList();
  }, []);

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();
  const authState = useSelector(state => state.AuthReducer);
  const pageState = useSelector(state => state.PageImageReducer);
  const { loading, data } = pageState;

  const { user } = authState;
  const handleModalClose = () => {
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      image: null
    });
  };

  const fetchPageList = async () => {
    const res = await fetchPageTypesApi({ authToken: user.authToken });
    setPages(res.data);
    checkPageList();
  };

  const checkPageList = () => {
    for (let i = 0; i < pages.length; i++) {
      if (pages.includes(pageList.page)) {
        alert('hurray');
      }
    }
    // console.log(pageList);
    // const newList = pages.filter((p, index) => {
    //   return pageList[index].page !== p.id;
    // });
    // console.log(pages);
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchPageImageRequest({
  //       authToken: user.authToken
  //     })
  //   );
  // }, [dispatch, user.authToken]);

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     let j = 0;
  //     for (let i = 0; i < pages.length; i++) {
  //       j++;
  //       if (pages[i].id === data[i].page) {
  //         delete pages[i]['id'];
  //         delete pages[i]['name'];
  //       }
  //       if (j === data.length){
  //         break;
  //       }
  //     }
  //   }
  // }, [data]);

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  const createPageImage = async () => {
    setProcessingRequest(true);
    const data = {
      page: values.page,
      image: values.image,
      type: selectedType,
      authToken: user.authToken
    };
    await createPageImageApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatchNotifyActions(
          dispatch,
          'success',
          'Image successfully uploaded'
        );
        dispatch(
          // fetchCategoryGalleryApi
          fetchPageImageRequest({
            authToken: user.authToken
          })
        );
        handleModalClose();
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            if (err.response) {
              handleResponseError(
                err.response,
                setProcessingRequest,
                dispatch,
                ['page']
              );
            } else {
              dispatchNotifyActions(
                dispatch,
                'error',
                'Page image upload failed'
              );
            }
            break;
          case 500:
            setProcessingRequest(false);
            dispatchNotifyActions(
              dispatch,
              'error',
              'Page image upload failed'
            );
            break;
          default:
            setProcessingRequest(false);
            dispatchNotifyActions(dispatch, 'error', 'Page image failed');
        }
      }
    });
  };

  const updatePageImage = async () => {
    setProcessingRequest(true);
    const data = {
      page: values.page,
      image: values.image,
      type: selectedType,
      authToken: user.authToken,
      id: pageDetails.id
    };
    await updatePageImagesApi({
      data,
      onSuccess: res => {
        setProcessingRequest(false);
        dispatchNotifyActions(
          dispatch,
          'success',
          'page image successfully updated'
        );
        dispatch(
          fetchPageImageRequest({
            authToken: user.authToken
          })
        );
        handleModalClose();
      },
      onError: err => {
        const { data, status } = err.response;
        switch (status) {
          case 400:
            setProcessingRequest(false);
            if (err.response) {
              handleResponseError(
                err.response,
                setProcessingRequest,
                dispatch,
                ['page']
              );
            } else {
              dispatchNotifyActions(
                dispatch,
                'error',
                'Page image update failed'
              );
            }
            break;
          case 500:
            setProcessingRequest(false);
            dispatchNotifyActions(
              dispatch,
              'error',
              'Page image update failed'
            );
            break;
          default:
            setProcessingRequest(false);
            dispatchNotifyActions(
              dispatch,
              'error',
              'Page image update failed'
            );
        }
      }
    });
  };

  const handleResponseValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('page')) {
      dispatchNotifyActions(dispatch, 'error', data.page[0]);
    } else {
      dispatchNotifyActions(
        dispatch,
        'error',
        'Failed to process request. Try again'
      );
    }
  };

  const formatPageName = name => {
    let newName = '';
    const arr = name.split('_');
    if (arr.length === 2) {
      newName = arr[0] + ' ' + arr[1];
    } else {
      newName = `${arr[0]}`;
    }
    return newName.charAt(0).toUpperCase() + newName.slice(1);
  };

  const handleOnSubmit = async () => {
    if (!editPage) {
      await createPageImage();
    } else {
      await updatePageImage();
    }
  };
  // handle file upload
  // const handleFileUpload = e => {
  //   setStateValues({
  //     ...values,
  //     image: e.target.files[0]
  //   });
  // };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  const handleFileUpload = e => {
    // setStateValues({
    //   ...values,
    //   image: e.target.files[0]
    // });
    setImageUploaded(true);
    console.log(values.image);
    console.log(e.target.files[0]);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setResult(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = value => {
  //   setOpen(false);
  // };
  const getCroppedImage = data => {
    setStateValues({
      ...values,
      image: data
    });
  };
  return (
    <Fragment>
      {/*<PageList />*/}
      <Dialog
        open={open}
        onClose={handleClose}
        // onClose={() => {
        //   handleCloseModalAction(setStateValues, values, handleClose);
        // }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
        disableBackdropClick>
        <CustomDialogTitle
          titleText={'Create page image'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        />

        <form onSubmit={handleSubmit(handleOnSubmit)} id="assign-gallery-link">
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth error={Boolean(errors.page)}>
                      <InputLabel id="demo-simple-select-label">
                        Select a page
                      </InputLabel>

                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: 'Page name is required'
                          }
                        }}
                        control={control}
                        defaultValue={editPage ? 'value' : ''}
                        name="page"
                        render={({ field: { value, name, onChange } }) => (
                          <Select
                            // disabled={loadingUsers}
                            name={name}
                            value={value}
                            displayEmpty
                            renderValue={
                              editPage ? () => pageDetails.page_text : ''
                            }
                            onChange={e => {
                              handleChange(setStateValues, values, e);
                              onChange(e.target.value);
                            }}>
                            {pages.map((page, index) => (
                              <MenuItem value={page.id} key={index}>
                                {formatPageName(page.name.toLowerCase())}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <div style={{ color: 'red' }}>
                        <FormHelperText>
                          {errors.page && errors.page.message}
                        </FormHelperText>
                      </div>
                    </FormControl>
                    <br/>
                    <br />
                    <br/>
                  </Grid>
                </Grid>
                {/*<FormControl style={{ marginTop: '20px' }}>*/}
                {/*  <FormLabel component="legend">Upload page image</FormLabel>*/}
                {/*  <FileUploadInput*/}
                {/*    canPerformActions*/}
                {/*    setStateValues={setStateValues}*/}
                {/*    values={values}*/}
                {/*    resetFileUpload={resetForm}*/}
                {/*    handleFileChange={e => {*/}
                {/*      handleFileUpload(e);*/}
                {/*    }}*/}
                {/*    accept=".jpg, .png, .jpeg"*/}
                {/*    errors={errors}*/}
                {/*    message={'Image is required'}*/}
                {/*    validate={!editPage}*/}
                {/*    register={register}*/}
                {/*  />*/}
                {/*</FormControl>*/}
                {progress && (
                  <div
                    className={
                      'progress-bar progress-bar-info progress-bar-striped'
                    }
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + '%' }}>
                    {`${progress}%`}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Demo getCroppedImage={getCroppedImage} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <AppModalFormActions
              processingRequest={processingRequest}
              handleClose={() => {
                handleModalClose();
              }}
              buttonTitle={'Upload'}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
}
