import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import CategoriesList from '../../../components/photo-gallery';
import CreateGalleryCategoryForm from '../../../components/photo-gallery/gallery-category/forms';
export default function Categories() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Portfolio"
        titleDescription="Create portfolio and their galleries here."
        createObj={true}
        handleOpen={handleOpen}
      />
      <CategoriesList />

      {open && (
        <CreateGalleryCategoryForm open={open} handleClose={handleClose} />
      )}
    </Fragment>
  );
}
