import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import PageList from '../../components/home/pageList';
import CreateCarouselImageForm from '../../components/carousel/forms/createCarouselImagesForms';
import CategoriesList from '../../components/photo-gallery';
import CarouselImageList from "../../components/carousel/pageList/CarouselImageLists";
export default function HomeCarouselImages() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage carousel images"
        // titleDescription="Wide selection of cards with multiple styles, borders, actions and hover effects."
        createObj={true}
        handleOpen={handleOpen}
      />
      <CarouselImageList />

      {open && <CreateCarouselImageForm open={open} handleClose={handleClose} />}
    </Fragment>
  );
}
