import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchGalleryLinksApi = async (data) => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/photo-gallery-links/`, {
    headers: { Authorization: `${data.authToken}` }}
  )
    .then(res => res)
    .catch(err => err);
};

export const createGalleryLink = async ({ data, onSuccess, onError }) => {
  return await AxiosService.post(
    `${baseUrl}/adminportal/photo-gallery-links/`,
    data,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const updateGalleryLinks = async ({ data, onSuccess, onError }) => {
  return await AxiosService.put(
    `${baseUrl}/adminportal/photo-gallery-links/${data.id}/`,
    data,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteGalleryLinkApi = async ({ data, onSuccess, onError }) => {
  return await AxiosService.delete(
    `${baseUrl}/adminportal/photo-gallery-links/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }}
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};
